import { validateWebsiteUrl } from 'src/lib/utils';
import { uploadFile } from 'src/services/fileUpload';
import { z } from 'zod';

interface ILayerAction {
	id?: string;
	arguments?: any;
}

interface ILayerSpec {
	name?: string;
	actions?: ILayerAction[];
}

interface IVariation {
	id?: string;
	title?: string;
	isLoading?: boolean;
	layeredFile?: string;
	layerSpecs?: ILayerSpec[] | ILayerSpec;
}

interface IPalette {
	strategy: {
		id: string;
	};
	options: Array<{
		name: string;
		colors: string[];
	}>;
}

interface ITemplateAttribute {
	industries?: string[];
	thumbnail?: string;
	palettes?: IPalette;
	fonts?: string[];
}

interface ITemplate {
	name?: string;
	id: string;
	enabled?: boolean;
	attributes?: ITemplateAttribute;
	variations?: IVariation[];
}

export interface IFontFamily {
	name: string;
	styles: Array<{
		name: string;
		weight?: number;
		source: string;
	}>;
}

export interface IBrandIdentity {
	fontType?: string;
	templates?: ITemplate[];
	fontFamilies?: IFontFamily[];
}

export interface ICompanyAccountUpdate {
	account: string;
	name: string;
	websiteLink: string;
	industry?: {
		industryIds: string[];
		name: string;
		parent?: string | null;
	} | null;
	logo?: string;
	brandIdentity?: IBrandIdentity;
	keywords?: string[];
	description?: string;
}

export interface ICompanyChannel {
	id?: string;
	name: string;
	icon: string;
}

export interface ICompanyAccountSubscription {
	_id: string;
	stripeCustomerId: string;
	subscriptionId: string;
	status: string;
	planId: string;
	internalPlanId: string;
	createdAt: string;
	updatedAt: string;
}

export interface ICompanyAccountForm {
	id?: string;
	account: string;
	name: string;
	appStoreLink?: string;
	playStoreLink?: string;
	websiteLink: string;
	industry?: any;
	logo: string;
	timezone: string;
	language: string;
	channels?: ICompanyChannel;
	brandIdentity?: IBrandIdentity;
	description?: string;
	keywords?: string[];
	font?: string;
	subscription?: ICompanyAccountSubscription;
}

export interface ICompanyAccount extends ICompanyAccountForm {
	id: string;
}

export const CompanyAccountSchema: z.ZodType<ICompanyAccountForm> = z.object({
	id: z.string().optional(),
	account: z.string().nonempty('Account is required'),
	name: z.string().nonempty('Name is required'),
	appStoreLink: z
		.string()
		.url('App Store Link is not a valid URL')
		.optional()
		.or(z.literal('')),
	playStoreLink: z
		.string()
		.url('Play Store Link is not a valid URL')
		.optional()
		.or(z.literal('')),
	websiteLink: z
		.string()
		.nonempty('Website is required')
		.refine((url) => validateWebsiteUrl(url).isValid, {
			message: 'Website is not a valid URL',
		}),
	industry: z.object({}).optional().nullable(),
	logo: z.string().superRefine(async (url, ctx) => {
		try {
			await uploadFile(url, true, 100, 30);
		} catch (error: any) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: error.response?.data?.message || 'Logo URL is not valid',
			});
		}
	}),
	timezone: z.string(),
	language: z.string(),
	brandIdentity: z
		.object({
			fontType: z.string().optional(),
		})
		.optional(),
});
