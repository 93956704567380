import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import TemplatesContext, {
	IUpdatePayload,
	IVariation,
} from 'src/contexts/templates/TemplatesContext';
import {
	ILayer,
	ITemplateFormValues,
} from 'src/lib/schemas/account/template/TemplateSchema';
import http from 'src/services/http';
export const useImageGeneration = () => {
	const { setPayloads, handleSetPayloadsByCase, selectedTemplate } =
		useContext(TemplatesContext);

	const formMethods = useFormContext<ITemplateFormValues>();
	const regenerateImage = async (caseId: number, variationId: string) => {
		const caseItem = formMethods
			.getValues()
			.cases.find((caseItem) => caseItem.id === caseId);
		if (!caseItem) {
			console.error(`Case with id ${caseId} not found.`);
			return;
		}

		const variation = selectedTemplate!.variations!.find(
			(variation) => variation.id === variationId,
		);

		await makeApiCall(variation!, caseItem.layers, caseId, true);
	};

	const makeApiCall = async (
		variation: IVariation,
		layers: ILayer[],
		caseId: number,
		isRegenerate: boolean,
	) => {
		const templateWithSingleVariation = {
			...selectedTemplate,
			variations: [variation],
		};

		const inputs = {
			variantId: variation.id,
			template: templateWithSingleVariation,
			layers: layers,
		};

		try {
			const { data: responseData } = await http.post(
				'/v2/apps/fusion_ai.generate_image_psd/execute/process_psd',
				{ inputs },
			);

			await processCallback(
				responseData.callback,
				variation.id!,
				layers,
				caseId,
				isRegenerate,
			);
		} catch (error) {
			console.error('Error in makeApiCall:', error);
			throw error;
		}
	};

	const processCallback = async (
		callbackUrl: string,
		variationId: string,
		layers: ILayer[],
		caseId: number,
		isRegenerate: boolean,
	) => {
		const response = await http.get(callbackUrl);
		const { status, body } = response.data;

		if (status === 'processing' || status === 'pending') {
			setTimeout(
				// @ts-ignore:
				processCallback.bind(
					null,
					callbackUrl,
					variationId,
					layers,
					caseId,
					isRegenerate,
				),
				3500,
			);
		} else if (status === 'successful') {
			const updatePayload: IUpdatePayload = {
				variationId,
				attributes: {
					image: {
						dimensions: {
							width: body.dimensions.width,
							height: body.dimensions.height,
						},
						layeredFile: body.layeredFile,
						flatFile: body.flatFile,
						layers: body.layers,
					},
				},
			};

			handleSetPayloadsByCase(caseId, updatePayload, isRegenerate);
			setPayloads((prevPayloads) => [...prevPayloads, updatePayload]);
		}
	};

	return { makeApiCall, processCallback, regenerateImage };
};
