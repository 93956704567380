import { useContext } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	List,
	Skeleton,
	useDisclosure,
	Box,
} from '@chakra-ui/react';
import NavListItem from './NavListItem';
import NestedNavList from './NestedNavList';
import UserContext from 'src/contexts/user/UserContext';
import { IMenuItem } from 'src/services/menu';
import CurrentPlanCard from 'src/components/modals/trial-ending-modal/CurrentPlanCard';

const hasAccess = (item: IMenuItem, planId: string): boolean => {
	if (item.rules && Array.isArray(item.rules) && item.rules.length > 0) {
		return item.rules.some((rule) => rule.allowed_plans.includes(planId));
	}
	return true;
};

const filterNavItems = (items: IMenuItem[], planId: string): IMenuItem[] => {
	return items.reduce((acc: IMenuItem[], item: IMenuItem) => {
		if (hasAccess(item, planId)) {
			const filteredItem = { ...item };
			if (filteredItem.children && filteredItem.children.length > 0) {
				filteredItem.children = filterNavItems(filteredItem.children, planId);
			}
			acc.push(filteredItem);
		}
		return acc;
	}, []);
};

interface ISideBarProps {
	setDrawerOpen?: (open: boolean) => void;
}

function DrawerExample({ setDrawerOpen }: ISideBarProps) {
	const { onClose } = useDisclosure();
	const { navList, isLoading, currentPlan } = useContext(UserContext);
	const planId = currentPlan?._id ?? '';
	const filteredNavList = navList ? filterNavItems(navList, planId) : [];

	return (
		<Drawer
			isOpen={true}
			placement="left"
			onClose={onClose}
			variant="clickThrough"
			trapFocus={false}
			blockScrollOnMount={false}
		>
			<DrawerContent
				bg="white"
				w="250px"
				maxW="250px"
				marginTop="72px"
				pt="10px"
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
			>
				<DrawerBody p={1} w="100%" display="flex" flexDirection="column">
					<Box flex="1">
						<List>
							{isLoading ? (
								<>
									{[...Array(5)].map((_, index) => (
										<Skeleton key={index} height="20px" my="4" />
									))}
								</>
							) : filteredNavList.length > 0 ? (
								filteredNavList.map((item: IMenuItem) =>
									item.children && item.children.length > 0 ? (
										<NestedNavList
											key={item._id}
											{...item}
											setDrawerOpen={setDrawerOpen}
										/>
									) : (
										<NavListItem key={item._id} {...item} />
									),
								)
							) : (
								<div></div>
							)}
						</List>
					</Box>

					<CurrentPlanCard />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}

export default DrawerExample;
