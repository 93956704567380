import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Text,
} from '@chakra-ui/react';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, message }: any) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay zIndex={20000} />
			<ModalContent
				containerProps={{
					zIndex: 20000,
				}}
			>
				<ModalHeader>Confirm navigation</ModalHeader>
				<ModalBody>
					<Text>{message}</Text>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose}>Cancel</Button>
					<Button variant="orangeSolid" ml={3} onClick={onConfirm}>
						Continue
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmDialog;
