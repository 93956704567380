import { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import _ from 'lodash';

import { renderOutputWidget } from './utils/renderOutputWidget';
import { IAppActionOutput, IAppResponse } from 'src/lib/schemas';
import HTMLPreviewEditor from './HTMLPreviewEditor'; 

const SUPPORTED_OUTPUT_TYPES: Array<string> = [
  'String',
  'Image',
  'FlatImage',
  'Object[]',
  'Object',
  'Number',
  'String[]',
];

interface AppOutputsProps {
  outputSkeleton: IAppActionOutput[];
  result: IAppResponse;
  isProcessing: boolean;
  view?: string; // 'preview' o 'html'
}

const AppOutputs: FC<AppOutputsProps> = ({
  outputSkeleton,
  result,
  isProcessing,
  view,
}) => {
  const filteredOutputSkeleton = outputSkeleton.filter(
    (ops) =>
      SUPPORTED_OUTPUT_TYPES.includes(ops.type) && !ops.property.includes('.'),
  );

  const output = result ?? {};
  const [editedHtmlOutputs, setEditedHtmlOutputs] = useState<{ [key: string]: string }>({});

  const handleHtmlChange = (property: string, newHtml: string) => {
    setEditedHtmlOutputs((prev) => ({
      ...prev,
      [property]: newHtml,
    }));
  };

  return (
    <Flex direction="column" gap={4}>
      {filteredOutputSkeleton.map((op) => {
        const OutputWidget = renderOutputWidget(op.type);
        const value = _.get(output, op.property);

        if (OutputWidget) {
          if (op.type === 'String' && op.property === 'html') {
            const initialHtml = (value as string) || '';
            const editedHtml =
              editedHtmlOutputs[op.property] !== undefined
                ? editedHtmlOutputs[op.property]
                : initialHtml;

            return (
              <HTMLPreviewEditor
                key={op.property}
                htmlContent={editedHtml}
                onHtmlChange={(newVal) => handleHtmlChange(op.property, newVal)}
                view={view === 'preview' ? 'preview' : 'html'}
              />
            );
          } else {
            return (
              <OutputWidget
                key={op.property}
                isParent={true}
                output={op}
                outputSkeleton={outputSkeleton}
                rawOutput={output}
                baseProperty={op.property}
                value={value}
                level={1}
                isProcessing={isProcessing}
                view={view}
              />
            );
          }
        }
        return null;
      })}
    </Flex>
  );
};

export default AppOutputs;
