import React, { FC, ReactNode, useState, KeyboardEvent } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormLabel,
	FormLabelProps,
	FormErrorMessage,
	Input,
	Tag,
	TagCloseButton,
	TagLabel,
	Box,
	Textarea,
} from '@chakra-ui/react';

export interface TagInputProps {
	name: string;
	label?: string;
	requirementsLabel?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	value?: string[];
	onChange?: (val: string[]) => void;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	placeholderFontSize?: string;
}

const TagInput: FC<TagInputProps> = ({
	label,
	requirementsLabel,
	placeholder,
	value = [],
	onChange,
	required,
	error,
	formControlProps,
	formLabelProps,
	placeholderFontSize = '14px',
}) => {
	const [inputValue, setInputValue] = useState('');
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error)
			return (
				<Text color="#e53e3e" ml="3px">
					*
				</Text>
			);
		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInputValue(e.target.value);
	};

	const addTags = (text: string) => {
		const tags = text
			.split(/[\n,]/)
			.map((tag) => tag.trim())
			.filter((tag) => tag.length > 0);

		const uniqueTags = tags.filter((tag) => !value.includes(tag));
		onChange?.([...value, ...uniqueTags]);
	};

	const handleInputKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
		if (
			(e.key === 'Enter' || e.key === ',') &&
			!e.shiftKey &&
			inputValue.trim()
		) {
			e.preventDefault();
			addTags(inputValue);
			setInputValue('');
		}
	};

	const handleInputPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData('text');

		addTags(pastedText);

		setInputValue(pastedText);

		setTimeout(() => {
			setInputValue('');
		}, 350);
	};

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel mb="2" {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
					{requirementsLabel && (
						<Text fontSize="12px" color="#959595">
							{requirementsLabel}
						</Text>
					)}
				</FormLabel>
			)}

			<Textarea
				value={inputValue}
				onChange={handleInputChange}
				onKeyDown={handleInputKeyDown}
				onPaste={handleInputPaste}
				placeholder="Add tags"
				minHeight="40px"
				 overflow="hidden" 
			/>

			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};
export default TagInput;
