import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  Tooltip,
  Text,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { RxOpenInNewWindow } from 'react-icons/rx';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';
import CustomContainer from 'src/components/common/CustomContainer';
import { ImageListTemplates } from './ImageListTemplates';
import { ITemplateFormValues } from 'src/lib/schemas/account/template/TemplateSchema';
import { ImageSkeleton } from './commponents/ImageSkeleton';
import { useImageGeneration } from './utils/useImageGeneration';
import VariationSwitch from './commponents/VariationSwitch';
import { TableHook } from './hooks/TableHook';
import { customToast } from 'src/services/toast';

interface TestTemplatesProps {
  textButton: string;
}

const TestTemplates: React.FC<TestTemplatesProps> = ({ textButton }) => {
  const { selectedTemplate, payloads, setPayloads, setPayloadsByCaseState } =
    useContext(TemplatesContext);
  const { makeApiCall } = useImageGeneration();

  const endOfPageRef = useRef<HTMLDivElement | null>(null);
  const [activeVariations, setActiveVariations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [payloadLength, setPayloadLength] = useState<number>();

  const [allVariations, setAllVariations] = useState([
    { id: 'design', layeredFile: '' },
    { id: 'facebook_story', layeredFile: '' },
    { id: 'facebook_feed', layeredFile: '' },
    { id: 'instagram_feed', layeredFile: '' },
  ]);

  const formMethods = useForm<ITemplateFormValues>({
    defaultValues: {
      designDirection: true,
      fbStory: true,
      fbFeed: true,
      igFeed: true,
      cases: [{}],
    },
  });

  const { handleSubmit, setValue, watch } = formMethods;

  useEffect(() => {
    if (selectedTemplate) {
      const initialVariations =
        selectedTemplate.variations?.filter((variation) =>
          [
            'design',
            'facebook_story',
            'facebook_feed',
            'instagram_feed',
          ].includes(variation.id!),
        ) || [];

      setActiveVariations(initialVariations);

      setAllVariations((prevVariations) =>
        prevVariations.map((variation) => {
          const matchingVariation = initialVariations.find(
            (v) => v.id === variation.id,
          );

          if (matchingVariation) {
            return {
              ...variation,
              layeredFile: matchingVariation.layeredFile || '',
            };
          }
          return variation;
        }),
      );
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (payloads.length === payloadLength) {
      setLoading(false);
    }
    if (loading && endOfPageRef.current) {
      endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [payloads, payloadLength, loading]);

  const getVariationById = (id: string) => {
    return allVariations.find((v) => v.id === id);
  };

  const variationIdMap: Record<string, string> = {
    designDirection: 'design',
    fbStory: 'facebook_story',
    fbFeed: 'facebook_feed',
    igFeed: 'instagram_feed',
  };

  const handleSwitchChange = (name: string) => {
    const id = variationIdMap[name];

    const currentValue = watch(name as keyof ITemplateFormValues);
    setValue(name as keyof ITemplateFormValues, !currentValue);

    const updatedVariation = getVariationById(id);

    if (!updatedVariation) {
      return;
    }

    if (!currentValue) {
      const newActiveVariations = [...activeVariations, updatedVariation];
      setActiveVariations(newActiveVariations);
    } else {
      const newActiveVariations = activeVariations.filter(
        (variation) => variation.id !== updatedVariation.id,
      );
      setActiveVariations(newActiveVariations);
    }
  };

  const onSubmit = (data: ITemplateFormValues) => {
    handleGenerateManifestForVariations(data);
  };

  const handleGenerateManifestForVariations = async (
    data: ITemplateFormValues,
  ) => {
    if (
      !selectedTemplate?.variations ||
      selectedTemplate.variations.length === 0
    ) {
      return;
    }

    setLoading(true);
    setPayloadsByCaseState([]);
    setPayloads([]);
    setPayloadLength(activeVariations.length);

    const totalPayloads = activeVariations.length * data.cases.length;
    setPayloadLength(totalPayloads);

    try {
      for (const variation of activeVariations) {
        for (const caseItem of data.cases) {
          await makeApiCall(variation, caseItem.layers, caseItem.id, false);
        }
      }
    } catch (error) {
      setLoading(false);
      customToast('Error generating manifest for variations', 'error');
    }
  };

  const getLabel = (name: keyof ITemplateFormValues) => {
    switch (name) {
      case 'designDirection':
        return 'Design Direction';
      case 'fbStory':
        return 'FB/IG story';
      case 'fbFeed':
        return 'Facebook Feed';
      case 'igFeed':
        return 'Instagram Feed';
      default:
        return '';
    }
  };

  const isVariaitonsDisabled =
    !selectedTemplate?.variations || selectedTemplate.variations.length === 0;

  return (
    <CustomContainer mt="80px">
      <Box mt="10px">
        <Text fontWeight="bold" fontSize="24px">
          Test templates
        </Text>
        <Button
          textAlign="center"
          variant="link"
          colorScheme="blue"
          onClick={() =>
            window.open(
              '/tasks/fusion_ai.image_generator?category=65d5e6e691a129f951e102c8',
              '_blank',
            )
          }
        >
          Generate image with AI
          <RxOpenInNewWindow style={{ marginLeft: '4px' }} />
        </Button>

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              direction="column"
              gap={4}
              justifyContent="start"
              alignItems="start"
            >
              {(
                ['designDirection', 'fbStory', 'fbFeed', 'igFeed'] as Array<
                  'designDirection' | 'fbStory' | 'fbFeed' | 'igFeed'
                >
              ).map((name) => (
                <VariationSwitch
                  key={name}
                  name={name}
                  label={getLabel(name)}
                  isDisabled={isVariaitonsDisabled}
                  activeVariations={activeVariations}
                  onSwitchChange={handleSwitchChange}
                />
              ))}
              {!isVariaitonsDisabled && activeVariations.length <= 0 && (
                <Text color="red.500" fontSize="sm">
                  Should select at least one variation
                </Text>
              )}
            </Flex>

			{/* @ts-ignore: */}
            <TableHook />

            <Flex justifyContent="center">
              {loading && payloads.length < payloadLength! && (
                <ImageSkeleton payloadLength={payloadLength!} />
              )}
            </Flex>

            {payloads.length >= payloadLength! && <ImageListTemplates />}
            <Flex
              justifyContent="flex-end"
              mt={20}
              gap={10}
              ref={endOfPageRef}
            >
              <Tooltip
                label={
                  isVariaitonsDisabled ? 'Should generate variations' : ''
                }
                isDisabled={!isVariaitonsDisabled}
                placement="top"
                hasArrow
                bg="red.600"
                color="white"
              >
                <Button
                  disabled={isVariaitonsDisabled}
                  sx={{
                    backgroundColor: '#F7480B',
                    color: 'white',
                    opacity: isVariaitonsDisabled ? 0.6 : 1,
                  }}
                  _hover={{
                    bg: isVariaitonsDisabled ? '#F7480B' : '#e64109',
                  }}
                  type="submit"
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" mr={2} />
                      Loading...
                    </>
                  ) : (
                    'Test'
                  )}
                </Button>
              </Tooltip>
              <Button colorScheme="blue" type="submit" form="hook-form">
                {textButton}
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </Box>
    </CustomContainer>
  );
};

export default TestTemplates;
