export enum BillingStatus {
	Trial = 'trialing',
	TrialExpired = 'trialing_expired',
	Active = 'active',
	Inactive = 'inactive',
}

export interface IPlan {
	_id: string;
	planId: string;
	priceId: string;
	amount: number;
	name: string;
	description: string;
	subscriptionLink: string;
	level: number;
	features: string[];
}

export interface ISubscription {
	subscriptionId: string;
	isFreeTrial: boolean;
	isFreeTrialExpired: boolean;
	freeTrialEnd: string;
	plan: {
		id: string;
		amount: number;
		interval: string;
		currency: string;
		product: string;
		level: number;
	};
	nextBillingDate: string;
	paymentMethod: any | null;
	billingStatus: BillingStatus;
	checkoutUrl?: string;
	startDate: string;
}
