import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader } from 'src/components/auth';
import FusionLoading from 'src/components/common/FusionLoading';
import AlertMessage from 'src/components/common/AlertMessage';
import UserContext from 'src/contexts/user/UserContext';
import authService from 'src/services/auth';
import { toastSuccess } from 'src/services/toast';

const VerifyEmail = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [searchParams] = useSearchParams();
	const { user, setUser } = useContext(UserContext);

	useEffect(() => {
		const token = searchParams.get('token');
		if (!token) {
			setError('Token is invalid or expired');
			setIsLoading(false);
			return;
		}
		handleVerifyEmail(token);
	}, []);

	const handleVerifyEmail = async (token: string) => {
		try {
			const currentUser = authService.getCurrentUser();
			if (currentUser && currentUser.isEmailVerified) {
				navigate('/home');
				return;
			}
			const { user, redirectUrl } = await authService.verifyEmail(token);
			if (user) {
				setUser(user);
				toastSuccess('Email verified successfully. You are now logged in.');
				navigate(redirectUrl ?? '/home');
			}
		} catch (error: any) {
			if (!error.response) {
				console.error(error);
				return;
			}
			const status = error.response.status;
			const { message } = error.response.data;

			if (status === 409) {
				navigate('/login', {
					state: {
						error:
							'This email has already been verified. Please log in with your credentials.',
					},
				});
			} else if (status === 401 || status === 403) {
				navigate('/pending-verification');
			} else {
				setError(message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Container maxW="md">
			<Flex direction="column" gap={12}>
				<AuthHeader title="Verify Email" />
				{error && <AlertMessage status="error">{error}</AlertMessage>}
				<FusionLoading isLoading={isLoading} />
			</Flex>
		</Container>
	);
};

export default VerifyEmail;
