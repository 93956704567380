import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import _ from 'lodash';

import { renderOutputWidget } from '../../utils/renderOutputWidget';
import { IAppActionOutput } from 'src/lib/schemas';

interface ArrayOutputWidgetProps {
	output: IAppActionOutput;
	outputSkeleton: IAppActionOutput[];
	rawOutput: any;
	baseProperty: string;
	value: any;
	level: number;
	isProcessing: boolean;
	view?: string;
}
// @ts-ignore:
const ArrayOutputWidget: FC<ArrayOutputWidgetProps> = ({
	output,
	outputSkeleton,
	rawOutput,
	baseProperty,
	value,
	level,
	isProcessing,
	view,
}) => {
	const currentLevel = level + 1;
	const currentOutput = outputSkeleton.filter(
		({ property }) =>
			property.startsWith(`${output.property}`) &&
			property.split('.').length === currentLevel,
	);

	if (!currentOutput.find((val) => val.property === output.property)) return;

	return (
		<Flex direction="column" gap={4} key={currentLevel}>
			{Array.isArray(value) &&
				value.map((_value: any, index: number) => {
					return (
						<Flex key={index} direction="column" gap={1}>
							<Text fontWeight="semibold">Element {index + 1}</Text>
							<Flex
								direction="column"
								gap={4}
								pl={2}
								borderLeft="1px solid #E1E1E1"
							>
								{currentOutput.map((op, opIndex) => {
									const property = `${baseProperty}.${index}.${op.property
										.split('.')
										.pop()}`;
									const currentValue = _.get(rawOutput, property);
									const OutputWidget = renderOutputWidget(op.type);

									if (OutputWidget) {
										return (
											<OutputWidget
												key={op.property + opIndex}
												output={op}
												rawOutput={rawOutput}
												outputSkeleton={outputSkeleton}
												value={currentValue}
												baseProperty={property}
												level={currentLevel}
												isProcessing={isProcessing}
												view={view}
											/>
										);
									}
									return null;
								})}
							</Flex>
						</Flex>
					);
				})}
		</Flex>
	);
};

export default ArrayOutputWidget;
