import React, { FC, useState } from 'react';
import {
	Box,
	Collapse,
	Flex,
	HStack,
	List,
	ListItem,
	ListIcon,
	Text,
} from '@chakra-ui/react';
import 'src/styles/hover.css';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { usePopup } from 'src/contexts/PopupContext';
import { IMenuItem } from 'src/services/menu';
import { iconMapper } from 'src/services/iconMapper';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

const refreshRequiredUrls = ['/projects/campaigns/new'];

interface NestedNavListProps extends IMenuItem {
	shouldRefresh?: boolean;
	setDrawerOpen?: (open: boolean) => void;
}

const NestedNavList: FC<NestedNavListProps> = ({
	label,
	icon,
	children,
	setDrawerOpen,
}) => {
	const { pathname } = useLocation();
	const [search] = useSearchParams();
	const navigate = useNavigate();
	const { setAdTemplateModalOpen } = usePopup();

	const [isExpanded, setIsExpanded] = useState<boolean>(() => {
		return children.some(
			(item) =>
				pathname === item.url ||
				(item.url && pathname.startsWith(`${item.url}/`)),
		);
	});

	const toggleMenu = () => {
		setIsExpanded((prev) => !prev);
	};

	const activeListProps = children.some(
		(item) =>
			pathname === item.url ||
			(item.url && pathname.startsWith(`${item.url}/`)),
	)
		? {
				color: 'orange.base',
				bg: '#EDEEEF',
				borderLeftRadius: 0,
		  }
		: {};

	const activeLinkProps = {
		color: 'orange.base',
		bg: 'orange.lighter',
	};

	const category = search.get('category');

	const isCategoryActive = (link: string) => {
		if (pathname === '/projects/campaigns/new') return false;

		const isTasksLink = link === '/tasks';

		const isCatActive =
			(link &&
				(isTasksLink ? pathname === link : pathname.includes(link)) &&
				!(
					pathname.includes('/projects/campaigns/') &&
					link === '/projects/campaigns'
				) &&
				!(pathname.includes('tasks') && category)) ||
			(link && link.split('=').pop() === category);

		return isCatActive;
	};

	const handleNavigate = (link: string) => {
		if (setDrawerOpen) setDrawerOpen(false);

		if (refreshRequiredUrls.includes(link)) {
			navigate('/');
			setTimeout(() => navigate(link), 0);
		} else {
			navigate(link);
		}
	};

	return (
		<Flex>
			<Box
				borderRight={
					children.some(
						(item) =>
							pathname === item.url ||
							(item.url && pathname.startsWith(`${item.url}/`)),
					)
						? '3px solid #F7480B'
						: '3px solid transparent'
				}
				borderRadius="0px 4px 4px 0px"
				height="48px"
			/>
			<Box w="full" id={label}>
				<ListItem cursor="pointer">
					<HStack
						className="container"
						_hover={{
							color: 'orange.base',
						}}
						p={3}
						borderRadius="base"
						pl="20px"
						onClick={toggleMenu}
						{...activeListProps}
					>
						{icon && (
							<ListIcon
								as={iconMapper[icon as keyof typeof iconMapper] ?? (() => null)}
							/>
						)}
						<Text
							className="text"
							flex={1}
							fontWeight="500"
							lineHeight="175%"
							letterSpacing="0.2px"
							{...activeListProps}
						>
							{label}
						</Text>

						<ListIcon
							color="#414141"
							as={isExpanded ? IoChevronUpOutline : IoChevronDownOutline}
						/>
					</HStack>
				</ListItem>
				<Collapse in={isExpanded}>
					<List>
						{children.map((item) => {
							if (item.label === 'Ad Templates') {
								return (
									<ListItem key={item._id} className="container" pl="30px">
										<HStack
											className="container"
											_hover={{
												color: 'orange.base',
												cursor: 'pointer',
											}}
											p={2}
											pl="20px"
											borderRadius="6px"
											onClick={() => {
												setAdTemplateModalOpen(true);
												if (setDrawerOpen) setDrawerOpen(false);
											}}
											{...(isCategoryActive(item.url ?? '')
												? activeLinkProps
												: {})}
										>
											<Text
												className="text"
												fontWeight={500}
												color={
													isCategoryActive(item.url ?? '') ? 'orange.base' : ''
												}
											>
												{item.label}
											</Text>
										</HStack>
									</ListItem>
								);
							} else if (item.children && item.children.length > 0) {
								return (
									<NestedNavList
										key={item._id}
										{...item}
										setDrawerOpen={setDrawerOpen}
									/>
								);
							} else {
								return (
									<ListItem
										key={item._id}
										className="container"
										pl="30px"
										cursor="pointer"
									>
										<HStack
											className="container"
											_hover={{
												color: 'orange.base',
											}}
											p={2}
											pl="20px"
											borderRadius="6px"
											onClick={() => handleNavigate(item.url ?? '')}
											{...(isCategoryActive(item.url ?? '')
												? activeLinkProps
												: {})}
										>
											<Text
												className="text"
												fontWeight={500}
												color={
													isCategoryActive(item.url ?? '') ? 'orange.base' : ''
												}
											>
												{item.label}
											</Text>
										</HStack>
									</ListItem>
								);
							}
						})}
					</List>
				</Collapse>
			</Box>
		</Flex>
	);
};

export default NestedNavList;
