import { Box, Card } from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import CardActions from './CardActions';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { useContext, useState } from 'react';
import { CampaignContext } from 'src/contexts';
import {
	getCampaign,
	regenerateCampaignCreative,
	updateCampaignCreative,
} from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import Design from 'src/pages/design/Design';
import EditCreativeModal from './EditCreativeModal';

interface CreativeCardProps {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
	children: React.ReactNode;
	title: string;
	creativesConfig?: IChannelCreativeAttributes[];
}

const CreativeCard = ({
	creative,
	withActions = true,
	size = 'md',
	children,
	title,
	creativesConfig,
}: CreativeCardProps) => {
	const creativeModalToggle = useToggleWithPayload<ICreative>(creative);
	const { id: campaignId, setCampaign } = useContext(CampaignContext);
	const [editingCreative, setEditingCreative] = useState<ICreative | null>(
		null,
	);
	const config = creativesConfig?.find((c) => c.id === creative.channel);
	const withImage = creative.adEngine === 'creatomate';

	const handleRefetchCreatives = async () => {
		if (!campaignId) return;
		try {
			const response = await getCampaign(campaignId);
			setCampaign(response);
		} catch (error: any) {
			toastError(error.message || 'Failed to fetch creatives');
		}
	};

	const handleRegenerateCreative = async () => {
		if (!campaignId) return;
		try {
			await regenerateCampaignCreative(campaignId, { creativeId: creative.id });
			const response = await getCampaign(campaignId);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleEditCreative = () => {
		//Always open simple editor in this version
		creativeModalToggle.onOpen(creative);
		// if (withImage) {
		// 	setEditingCreative(creative);
		// } else {
		// 	creativeModalToggle.onOpen(creative);
		// }
	};

	const handleClose = () => {
		setEditingCreative(null);
	};

	const handleSave = async (updatedTemplate: any) => {
		if (!campaignId || !editingCreative) return;

		const updatedPayload = {
			attributes: updatedTemplate,
		};

		await updateCampaignCreative(
			campaignId,
			editingCreative.id,
			updatedPayload,
		);

		handleRefetchCreatives();
		handleClose();
		toastSuccess('Your changes have been saved');
	};

	const handleChange = (updatedTemplate: any) => {
		setEditingCreative((prev) => {
			if (!prev) return prev;
			return {
				...prev,
				attributes: {
					...prev.attributes,
					elements: updatedTemplate.elements,
				},
			};
		});
	};

	if (
		editingCreative?.attributes &&
		Array.isArray((editingCreative as any).attributes.elements)
	) {
		return (
			<Box
				position="fixed"
				top="72px"
				left="0"
				right="0"
				bottom="0"
				zIndex="overlay"
				bg="white"
				overflow="hidden"
			>
				<Design
					template={editingCreative.attributes}
					onClose={handleClose}
					onSave={handleSave}
					onChange={handleChange}
				/>
			</Box>
		);
	}

	return (
		<>
			<Box maxW={size}>
				{withActions && (
					<CardActions
						title={title}
						onEdit={handleEditCreative}
						onRegenerate={handleRegenerateCreative}
						mb="10px"
					/>
				)}
				<Card
					border="1px"
					borderRadius="lg"
					borderColor="gray.200"
					bg="white"
					boxShadow="md"
				>
					{children}
				</Card>
			</Box>
			{creativeModalToggle.payload && creativeModalToggle.isOpen && (
				<EditCreativeModal
					isOpen
					onClose={creativeModalToggle.onClose}
					initialValues={creativeModalToggle.payload}
					config={config}
					handleRefetchCreatives={handleRefetchCreatives}
				/>
			)}
		</>
	);
};

export default CreativeCard;
