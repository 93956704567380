import http from 'src/services/http';

export const processCallback = async (
	callbackUrl: string,
	maxAttempts: number = 100,
	attempt = 1,
): Promise<any> => {
	if (attempt > maxAttempts) {
		console.error('Max attempts reached, aborting call');
		return { status: 'timeout', maxAttemptsReached: true };
	}

	try {
		const response = await http.get(callbackUrl);
		const { status, body } = response.data;

		switch (status) {
			case 'processing':
			case 'error':
			case 'unsatisfiedDependencies':
			case 'timeout':
			case 'pending':
				if (attempt < maxAttempts) {
					await new Promise((resolve) => setTimeout(resolve, 1000));
					return await processCallback(callbackUrl, maxAttempts, attempt + 1);
				} else {
					console.error(
						`Max attempts reached for processing/pending ${callbackUrl}`,
						body,
					);
					return { ...body, status: 'timeout', maxAttemptsReached: true };
				}

			case 'partialContent':
				if (!body?.elements) {
					if (attempt < maxAttempts) {
						await new Promise((resolve) => setTimeout(resolve, 1000));
						return await processCallback(callbackUrl, maxAttempts, attempt + 1);
					} else {
						console.error(
							'Max attempts reached on partialContent without valid elements',
						);
						return { ...body, status: 'timeout', maxAttemptsReached: true };
					}
				} else {
					const validElements = body.elements.every(
						(element: any) => element && typeof element === 'object',
					);
					if (!validElements) {
						console.error(`Invalid elements in template ${callbackUrl}`, body);
						return { ...body, status: 'error', maxAttemptsReached: true };
					}
					return { ...body, status };
				}

			case 'successful':
				if (Object.entries(body).length === 0) {
					console.error('Invalid structure in response body', body);
					return { ...body, status: 'timeout', maxAttemptsReached: true };
				} else {
					if (body.elements) {
						const validElements = body.elements.every(
							(element: any) => element && typeof element === 'object',
						);
						if (!validElements) {
							console.error(
								`Invalid elements in template ${callbackUrl}`,
								body,
							);
							return { ...body, status: 'error', maxAttemptsReached: true };
						}
					}
					return { ...body, status };
				}
			default:
				console.error(`Default case for ${callbackUrl}`, body);
				return { ...body, status: 'error', maxAttemptsReached: true };
		}
	} catch (error: any) {
		console.error('Error in processCallback:', error);
		return { status: 'error', error: error.message, maxAttemptsReached: true };
	}
};
