import { Box, Text, Flex, HStack, Avatar } from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import CommentIcon from 'src/assets/icons/Comment';
import RetweetIcon from 'src/assets/icons/Retweet';
import LikeIcon from 'src/assets/icons/Like';
import StatsIcon from 'src/assets/icons/Stats';
import UploadIcon from 'src/assets/icons/Upload';
import { useContext } from 'react';
import UserContext from 'src/contexts/user/UserContext';
import CreativeCard from './CreativeCard';

interface TwitterCreativeProps {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
}
const TwitterCreative = ({
	creative,
	withActions = true,
	size = 'md',
}: TwitterCreativeProps) => {
	const { user, account } = useContext(UserContext);
	const content = (creative as any).attributes.content;

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="Twitter"
		>
			<Box p={4}>
				<Flex alignItems="center" mb={4} gap={3}>
					<Avatar
						src={account?.logo}
						sx={{
							img: {
								objectFit: 'contain',
							},
						}}
					/>
					<Box>
						<Text fontWeight="bold">{user?.name}</Text>
					</Box>
				</Flex>
				<Text data-testid="twitter-content" mb={2}>
					{content}
				</Text>
				<HStack spacing={10} mt={2}>
					<CommentIcon />
					<RetweetIcon />
					<LikeIcon />
					<StatsIcon />
					<UploadIcon />
				</HStack>
			</Box>
		</CreativeCard>
	);
};

export default TwitterCreative;
