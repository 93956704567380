import {
	useState,
	useEffect,
	useRef,
	useImperativeHandle,
	forwardRef,
	memo,
} from 'react';
import { Preview } from '@creatomate/preview';
import { keyframes, Box, Image } from '@chakra-ui/react';
import FusionLoading from '../common/FusionLoading';
import { shouldShowTemplateIds } from 'src/utils/enviroment';

export interface TemplatePreviewHandle {
	updateTemplate(newTemplate: any): void;
}

interface TemplatePreviewProps {
	template: any;
	width?: string | number;
	height?: string | number;
	borderRadius?: string;
	mode?: 'interactive' | 'player';
	onError?: (templateId?: string) => void;
	onReady?: () => void;
	showThumbnail?: boolean;
	showOverlay?: boolean;
	showLoadingEffect?: boolean;
	showTemplateId?: boolean;
	isPending?: boolean;
	showFallback?: boolean;
}

const invertAnimation = keyframes`
	0%, 80% { backdrop-filter: invert(0%); }
	50% { backdrop-filter: invert(100%); }
  `;

const TemplatePreview = forwardRef<TemplatePreviewHandle, TemplatePreviewProps>(
	(
		{
			showOverlay = false,
			template,
			width = '100%',
			height = '100%',
			borderRadius,
			mode = 'player',
			onError,
			onReady,
			showThumbnail = false,
			showLoadingEffect = false,
			showTemplateId = false,
			isPending = false,
			showFallback = true,
		},
		ref,
	) => {
		const previewContainerRef = useRef<HTMLDivElement>(null);
		const previewRef = useRef<Preview | null>(null);
		const [internalShowThumbnail, setInternalShowThumbnail] =
			useState(showThumbnail);
		const [sdkReady, setSdkReady] = useState(false);
		const [localIsPending, setLocalIsPending] = useState(isPending);

		const isStringTemplate = typeof template === 'string';
		const isCreatomateTemplate =
			!isStringTemplate &&
			(!!template?.elements?.length || !!template?.outputs?.elements?.length);

		useEffect(() => {
			if (isPending) {
				setLocalIsPending(true);
			} else {
				const timer = setTimeout(() => {
					setLocalIsPending(false);
				}, 500);
				return () => clearTimeout(timer);
			}
		}, [isPending]);

		useImperativeHandle(ref, () => ({
			async updateTemplate(newTemplate: any) {
				try {
					if (typeof newTemplate === 'string') {
						return;
					}
					if (!sdkReady) {
						template = newTemplate;
						return;
					}
					if (previewRef.current) {
						await previewRef.current.setSource(newTemplate);
					}
				} catch (error) {
					onError?.(newTemplate.id);
					console.error('Error updating template:', error);
				}
			},
		}));

		useEffect(() => {
			if (isStringTemplate) return;

			const setUpPreview = async (htmlElement: HTMLDivElement) => {
				try {
					const preview = new Preview(
						htmlElement,
						mode,
						'public-tf0h78wui59f8xo3b7uysq3n',
					);

					preview.onReady = async () => {
						onReady?.();
						//@ts-ignore
						window['creatomateDebbuger'] = window['creatomateDebbuger'] || {};
						//@ts-ignore
						window['creatomateDebbuger'][template.templateId || template.id] =
							preview;

						try {
							const source = template?.outputs?.elements
								? { ...template.outputs, elements: template.outputs.elements }
								: template;

							await preview.setSource(source);
							setSdkReady(true);
							setInternalShowThumbnail(false);
						} catch (error) {
							console.error('Error loading template:', template.id, error);
							onError?.(template.id);
							setInternalShowThumbnail(true);
						}
					};

					previewRef.current = preview;
				} catch (error) {
					onError?.(template.id);
					console.error('Preview initialization error:', error);
					setInternalShowThumbnail(true);
				}
			};

			if (previewContainerRef.current && isCreatomateTemplate) {
				setUpPreview(previewContainerRef.current);
			}
		}, [isStringTemplate, isCreatomateTemplate]);

		useEffect(() => {
			if (isStringTemplate || !sdkReady || !previewRef.current) return;

			previewRef.current.setSource(template);
		}, [template, sdkReady, isStringTemplate]);

		if (isStringTemplate) {
			return (
				<div style={{ position: 'relative', width, height }}>
					<Image
						src={template}
						alt="Template Thumbnail"
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							borderRadius,
						}}
						onError={() => onError?.()}
					/>
				</div>
			);
		}

		return (
			<div style={{ position: 'relative', width, height }}>
				{!sdkReady && showFallback && (
					<Box
						position="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						zIndex={10}
						alignContent="center"
						alignItems="center"
					>
						<FusionLoading isLoading={true} />
					</Box>
				)}
				 {showTemplateId && shouldShowTemplateIds() && template && (
					<Box
						position="absolute"
						top={2}
						left={2}
						zIndex={3}
						bg="blackAlpha.700"
						color="white"
						px={2}
						py={1}
						borderRadius="md"
						fontSize="xs"
						fontFamily="monospace"
					>
						{template.templateId || template.id}
					</Box>
				)} 
				{isCreatomateTemplate && (
					<div
						ref={previewContainerRef}
						style={{
							width: '100%',
							height: '100%',
							borderRadius,
							visibility: internalShowThumbnail ? 'hidden' : 'visible',
							pointerEvents: 'none',
							...(showLoadingEffect &&
								localIsPending && {
									filter: 'grayscale(100%)',
								}),
						}}
					/>
				)}

				{showLoadingEffect &&
					localIsPending &&
					isCreatomateTemplate &&
					(!showFallback || sdkReady) && (
						<>
							<Box
								position="absolute"
								top="0"
								left="0"
								right="0"
								bottom="0"
								borderRadius={borderRadius}
								pointerEvents="none"
								animation={`${invertAnimation} 2s infinite ease-in-out`}
								zIndex={5}
							/>
							<Box
								position="absolute"
								top="0"
								left="0"
								right="0"
								bottom="0"
								borderRadius={borderRadius}
								bg="rgba(211, 218, 227, 0.5)"
								pointerEvents="none"
								zIndex={6}
							/>
						</>
					)}
			</div>
		);
	},
);

TemplatePreview.displayName = 'TemplatePreview';

export default memo(TemplatePreview, (prevProps, nextProps) => {
	return (
		prevProps.template?.id === nextProps.template?.id &&
		prevProps.template?.status === nextProps.template?.status
	);
});
