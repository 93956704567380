import React, { useContext, useState } from 'react';
import PlanCard from './PlanCard';
import { subscribe } from 'src/services/billing';
import { formatPrice } from 'src/lib/utils/billing';
import UserContext from 'src/contexts/user/UserContext';
import { openIntercom } from 'src/lib/utils/intercom';
import { customToast, toastSuccess } from 'src/services/toast';

interface PlanListProps {
	plans: any[];
	currentPlan: any;
}

const PlanList: React.FC<PlanListProps> = ({ plans, currentPlan }) => {
	const { fetchSubscription } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const selectedLevel = currentPlan?.level;

	const handleUpgrade = async (planId: string) => {
		try {
			setIsLoading(true);
			await subscribe(planId);
			await fetchSubscription();
			toastSuccess('Subscription upgraded successfully');
		} catch (error) {
			console.error(error);
			customToast('Failed to upgrade subscription. Please try again.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{plans.map((plan) => {
				const isSelected = plan._id === currentPlan?._id;
				let actionButtonText = '';
				let onActionButton = () => {};

				if (!isSelected && selectedLevel !== undefined) {
					if (plan.level < selectedLevel) {
						actionButtonText = 'Downgrade';
						onActionButton = () =>
							openIntercom('Hello, I would like to downgrade my plan');
					} else if (plan.level > selectedLevel) {
						actionButtonText = 'Upgrade';
						onActionButton = () => handleUpgrade(plan._id);
					}
				}

				return (
					<PlanCard
						key={plan._id}
						title={plan.name}
						features={plan.features}
						price={formatPrice(plan.amount)}
						actionButtonText={actionButtonText}
						onActionButton={onActionButton}
						isSelected={isSelected}
						isLoading={isLoading}
					/>
				);
			})}
		</>
	);
};

export default PlanList;
