import { useContext, useEffect, useState } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import {
	DataTable,
	DataTablePagination,
} from 'src/components/common/DataTable';
import FusionLoading from 'src/components/common/FusionLoading';
import { InvalidAccountBanner } from 'src/components/dashboard';
import UserContext from 'src/contexts/user/UserContext';
import usePagination from 'src/hooks/usePagination';
import { getCampaignsListingColumns } from 'src/components/campaign/parts/campaignListingColumns';
import {
	getCampaigns,
	archiveCampaign,
	duplicateCampaign,
} from 'src/services/campaign';
import { ICampaign, ICampaignsListingItem } from 'src/lib/schemas';
import { toastError, toastSuccess } from 'src/services/toast';
import useGroups from 'src/hooks/useGroups';
import CustomContainer from 'src/components/common/CustomContainer';

interface CampaignsProps {
	withContainer?: boolean;
	simplified?: boolean;
	noCampaignMessage?: () => JSX.Element;
	onCampaignsLoaded?: (campaigns: ICampaignsListingItem[]) => void;
}
const Campaigns = ({
	withContainer = true,
	simplified = false,
	noCampaignMessage,
	onCampaignsLoaded,
}: CampaignsProps) => {
	const [campaigns, setCampaigns] = useState<Array<ICampaignsListingItem>>();
	const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
	const { data: groups, isLoading: isGroupsLoading } = useGroups();
	const { account, isLoading: isAccountLoading } = useContext(UserContext);

	const pagination = usePagination();
	const navigate = useNavigate();

	const handleCreateCampaign = () => {
		navigate('/projects/campaigns/new');
	};

	const fetchCampaigns = async () => {
		setIsLoadingCampaigns(true);
		try {
			const query = `page=${pagination.page}&status[]=published&status[]=draft`;

			const { results, totalPages } = await getCampaigns(query);
			const filteredResults = results.filter(
				(result) => result.status !== 'archived',
			);
			let mappedResults = filteredResults.map((result) => ({
				id: result.id,
				title: result.title,
				status: result.status,
				progress: 0,
				group: result.group,
			}));

			if (simplified) {
				mappedResults = mappedResults.slice(0, 5);
			}

			setCampaigns(mappedResults);
			pagination.setPages(totalPages);
		} catch (error: any) {
			toastError(error);
		}
		setIsLoadingCampaigns(false);
	};

	useEffect(() => {
		fetchCampaigns();
	}, [pagination.page, simplified]);

	useEffect(() => {
		campaigns && onCampaignsLoaded && onCampaignsLoaded(campaigns);
	}, [campaigns]);

	const handleViewCampaign = (id: string) => {
		navigate(`/projects/campaigns/${id}`);
	};

	const handleDeleteCampaign = async (id: string): Promise<void> => {
		try {
			await archiveCampaign(id);

			setCampaigns(
				(prevCampaigns) =>
					prevCampaigns?.filter((campaign) => campaign.id !== id),
			);
		} catch (error: any) {
			toastError(error);
		}
	};
	const handleDuplicateCampaign = async (id: string): Promise<void> => {
		try {
			const duplicatedCampaign = await duplicateCampaign(id);
			const newCampaign: ICampaignsListingItem = {
				id: duplicatedCampaign.id,
				title: duplicatedCampaign.title,
				status: duplicatedCampaign.status,
				progress: 0,
				group: duplicatedCampaign.group,
			};

			setCampaigns((prevCampaigns) => [...(prevCampaigns ?? []), newCampaign]);

			navigate(`/projects/campaigns/${duplicatedCampaign.id}`);
		} catch (error) {
			toastError('Failed to duplicate campaign.');
		}
	};
	const columns = getCampaignsListingColumns({
		onViewCampaign: handleViewCampaign,
		onDeleteCampaign: handleDeleteCampaign,
		onDuplicateCampaign: handleDuplicateCampaign,
		groups: groups ?? [],
		simplified,
	});

	const showCampaigns = !isAccountLoading;

	const renderPage = () => (
		<Box>
			<FusionLoading isLoading={isAccountLoading} />
			{showCampaigns && (
				<Flex direction="column" gap={6}>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading>{simplified ? 'Recent campaigns' : 'Campaigns'}</Heading>
						<Button
							size="sm"
							onClick={handleCreateCampaign}
							variant="orangeSolid"
						>
							Create new campaign
						</Button>
					</Flex>
					{!campaigns?.length && noCampaignMessage ? (
						<Box>{noCampaignMessage()}</Box>
					) : (
						campaigns && (
							<>
								<DataTable
									columns={columns}
									data={campaigns!}
									isLoading={isLoadingCampaigns || isGroupsLoading}
									tableProps={{
										border: '1px solid #F5F5F5',
										minH: '400px',
									}}
									trProps={{
										borderBottomWidth: '1px',
										borderColor: '#F5F5F5',
									}}
								/>
								{!simplified && <DataTablePagination {...pagination} />}
							</>
						)
					)}
				</Flex>
			)}
		</Box>
	);

	return (
		<Box w="-webkit-fill-available">
			{withContainer ? (
				<CustomContainer>{renderPage()}</CustomContainer>
			) : (
				renderPage()
			)}
		</Box>
	);
};

export default Campaigns;
