import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';

interface CustomLayerPromptProps {
	selectedLayer: string;
	availableLayers: string[];
	customDescription: string;
	onLayerChange: (layerName: string) => void;
	onDescriptionChange: (value: string) => void;
	onRemove?: () => void;
}

export const CustomLayerPrompt = ({
	selectedLayer,
	availableLayers,
	customDescription,
	onLayerChange,
	onDescriptionChange,
	onRemove,
}: CustomLayerPromptProps) => {
	return (
		<Box display="flex" alignItems="center" mb={3} gap={2}>
			<Box width="200px">
				<Menu>
					<MenuButton
						as={Box}
						border="1px solid"
						w="100%"
						h="40px"
						borderRadius="md"
						px={3}
						display="flex"
						alignItems="center"
						role="button"
						_hover={{ borderColor: 'gray.500', bg: 'gray.400' }}
					>
						{selectedLayer || 'Select a layer'}
					</MenuButton>
					<MenuList maxH="300px" overflowY="auto">
						{availableLayers.length > 0 ? (
							availableLayers.map((layer) => (
								<MenuItem key={layer} onClick={() => onLayerChange(layer)}>
									{layer}
								</MenuItem>
							))
						) : (
							<MenuItem isDisabled>No layers available</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Box>
			<Box flex={1} display="flex" gap={2}>
				<input
					type="text"
					value={customDescription}
					onChange={(e) => onDescriptionChange(e.target.value)}
					placeholder="Enter description"
					disabled={!selectedLayer}
					style={{
						width: '100%',
						padding: '8px',
						border: '1px solid',
						borderRadius: '6px',
					}}
				/>
					
					<IconButton
					size="sm"
					colorScheme="red"
					aria-label="Remove Layer"
					icon={<DeleteIcon />}
						onClick={onRemove}
					/>
			</Box>
		</Box>
	);
};
