import React, { useState, useEffect, ReactNode } from 'react';
import authService from 'src/services/auth';
import useMyProfile from 'src/hooks/useMyProfile';
import { toastError } from 'src/services/toast';
import { getCompanyAccount } from 'src/services/account';
import { getMenu } from 'src/services/menu';
import { getPlans, getSubscriptionDetails } from 'src/services/billing';
import * as amplitude from '@amplitude/analytics-browser';
import UserContext from './UserContext';
import { ICompanyAccount, IUser } from 'src/lib/schemas';
import { IMenuItem } from 'src/services/menu';
import { IPlan, ISubscription } from 'src/models/account/interfaces';
import * as Sentry from '@sentry/react';

interface CustomWindow extends Window {
	dataLayer: Array<Record<string, any>>;
	newrelic: any;
	intercomSettings: any;
}

declare const window: CustomWindow;

interface UserProviderProps {
	children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<IUser | null>(authService.getCurrentUser());
	const [account, setAccount] = useState<ICompanyAccount | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [navList, setNavList] = useState<IMenuItem[]>([]);
	const [subscription, setSubscription] = useState<ISubscription | null>(null);
	const [plans, setPlans] = useState<IPlan[]>([]);
	const [currentPlan, setCurrentPlan] = useState<IPlan | undefined>(undefined);
	const profile = useMyProfile();

	useEffect(() => {
		if (!user?.account) return;

		const isInternalUser =
			user.email.endsWith('fusionads.ai') ||
			user.email.endsWith('fusionos.ai') ||
			user.email.endsWith('fusioncloud.ai');

		window.dataLayer.push({
			event: 'identify',
			user_id: user.id,
			email: user.email,
			name: user.name,
			account_id: user.account,
			is_internal_user: isInternalUser,
		});

		window.intercomSettings = {
			app_id: 'xdw1c26p',
			user_id: user.id,
			email: user.email,
			name: user.name,
			company: {
				company_id: user.account,
			},
			is_internal_user: isInternalUser,
		};

		if (typeof window.Intercom === 'function') {
			if (user.isEmailVerified) {
				window.Intercom('boot', window.intercomSettings);
			} else {
				window.Intercom('shutdown');
			}
		}

		if (window.newrelic && typeof window.newrelic.setUserId === 'function') {
			window.newrelic.setUserId(user.id);
			window.newrelic.setCustomAttribute('email', user.email);
			if (isInternalUser) {
				window.newrelic.pauseReplay();
			}
		}
		if (Sentry) {
			Sentry.setUser({ email: user?.email, id: user?.id });
		}
	}, [user]);

	useEffect(() => {
		amplitude.setUserId(user?.id);
	}, [user]);

	useEffect(() => {
		if (profile.user) {
			setUser(profile.user);
		}
	}, [profile.user]);

	const fetchCompanyAccount = async () => {
		if (!user) return;
		try {
			const data = await getCompanyAccount(user.account);
			setAccount(data);
		} catch (error) {
			toastError(error);
		}
		setIsLoading(false);
	};

	const fetchMenu = async () => {
		try {
			const menuData = await getMenu();
			setNavList(menuData);
		} catch (error) {
			console.error('Error fetching menu:', error);
		}
	};

	const fetchSubscription = async () => {
		if (account?.subscription) {
			try {
				const subscription = await getSubscriptionDetails(
					account.subscription._id,
				);
				setCurrentPlan(plans.find((plan) => plan._id === subscription.plan.id));
				setSubscription(subscription);
				return subscription;
			} catch (error) {
				console.error('Error fetching subscription:', error);
			}
		}
	};

	const fetchPlans = async () => {
		try {
			const plansData = await getPlans();
			setPlans(plansData);
		} catch (error) {
			console.error('Error fetching subscription plans:', error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchCompanyAccount();
			fetchMenu();
			fetchPlans();
		}
	}, [user]);

	useEffect(() => {
		if (account?.subscription) {
			setCurrentPlan(
				plans.find(
					(plan) => plan._id === account?.subscription?.internalPlanId,
				),
			);
			fetchSubscription();
			const intervalId = setInterval(() => {
				fetchSubscription();
			}, 60000);
			return () => clearInterval(intervalId);
		}
	}, [account?.subscription, plans]);

	const pendingAccountSettings = () => {
		const pendingSettings = [];
		if (!account?.websiteLink) pendingSettings.push('websiteLink');
		if (!account?.name) pendingSettings.push('name');
		if (!account?.description) pendingSettings.push('description');
		if (!account?.industry) pendingSettings.push('industry');
		if (!account?.keywords || account.keywords.length === 0)
			pendingSettings.push('keywords');
		if (!account?.logo) pendingSettings.push('logo');
		if (!account?.brandIdentity?.fontType) pendingSettings.push('fontType');
		return pendingSettings;
	};

	return (
		<UserContext.Provider
			value={{
				user,
				account,
				isLoading,
				navList,
				setUser,
				setAccount,
				setNavList,
				pendingAccountSettings,
				subscription,
				plans,
				currentPlan,
				fetchSubscription,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
