import {
	IGetThreadParams,
	IThreadResponse,
	IThreadMessageParams,
	IMessageResponse,
} from 'src/lib/schemas';
import http from './http';


export const getThreadByReference = async ({
	type,
	reference,
	sub_reference,
	property,
}: IGetThreadParams) => {
	const threadUrl = `/v1/threads?type=${type}&reference=${reference}&sub_reference=${sub_reference}&property=${property}`;
	const { data } = await http.get<IThreadResponse>(threadUrl);
	return data;
};

export const sendMessage = async ({
	assistantId,
	payload,
	threadId,
}: IThreadMessageParams) => {
	const threadIdUrlParam = threadId ? '/' + threadId : '';
	const threadUrl = `/v2/assistants/${assistantId}/thread${threadIdUrlParam}`;
	const { data } = await http.post<IMessageResponse>(threadUrl, payload);
	return data;
};
