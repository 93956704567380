import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Text, Image, Box, Flex, VStack } from '@chakra-ui/react';
import { RiLockLine } from 'react-icons/ri';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';
import { CampaignContext } from 'src/contexts';
import { editCampaignDesignDirections } from 'src/services/campaign';
import CustomModal from 'src/components/common/CustomModal';
import CardActions from '../creatives/CardActions';
import { cloneDeepWith } from 'lodash';
import TemplatePreview from 'src/components/templates/TemplatePreview';
import { isCreatomateAttributes } from 'src/lib/schemas/campaign/newFlowCampaign';

interface DesignLockModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onCancel: () => void;
	designDirections?: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
}

const normalizeCreatomateTemplate = (attributes: any) => {
	const clone = JSON.parse(JSON.stringify(attributes));
	if (Array.isArray(clone.elements)) {
		clone.elements = clone.elements.map((el: any) => {
			if (typeof el.x === 'string') el.x = el.x.trim();
			if (typeof el.y === 'string') el.y = el.y.trim();
			if (typeof el.width === 'string') el.width = el.width.trim();
			if (typeof el.height === 'string') el.height = el.height.trim();
			if (el.elements?.length) {
				el.elements = el.elements.map((subEl: any) => {
					if (typeof subEl.x === 'string') subEl.x = subEl.x.trim();
					if (typeof subEl.y === 'string') subEl.y = subEl.y.trim();
					if (typeof subEl.width === 'string') subEl.width = subEl.width.trim();
					if (typeof subEl.height === 'string')
						subEl.height = subEl.height.trim();
					return subEl;
				});
			}
			return el;
		});
	}
	return clone;
};

const DesignLockModal: React.FC<DesignLockModalProps> = ({
	designDirections,
	setDesignDirections,
	isOpen,
	onSubmit,
	onClose,
	onCancel,
}) => {
	const { id: campaignId, campaign, setCampaign } = useContext(CampaignContext);
	const [lockedOptions, setLockedOptions] = useState<string[]>([]);
	const [isHovering, setIsHovering] = useState<string | null>(null);
	const [amountToRegenerate, setAmountToRegenerate] = useState(3);
	const [initialDesignDirections, setInitialDesignDirections] = useState<
		IDesignDirection[]
	>([]);

	// Se agrega la cache para almacenar los templates normalizados
	const templatesCache = useRef<{ [id: string]: any }>({});

	useEffect(() => {
		if (designDirections && isOpen) {
			setInitialDesignDirections(cloneDeepWith(designDirections));
		}
	}, [designDirections, isOpen]);

	useEffect(() => {
		if (designDirections) {
			const lockedIds = designDirections
				.filter((dd) => dd.locked)
				.map((dd) => dd.id);
			setLockedOptions(lockedIds);
		}
	}, [designDirections]);

	useEffect(() => {
		if (!designDirections?.length) return;

		const updatedDesignDirections = designDirections.map((dd) => ({
			...dd,
			locked: lockedOptions.includes(dd.id),
		}));

		const hasChanged = designDirections.some(
			(dd, index) => dd.locked !== updatedDesignDirections[index].locked,
		);

		if (hasChanged) {
			setDesignDirections(updatedDesignDirections);
			campaign &&
				setCampaign({ ...campaign, designDirections: updatedDesignDirections });
		}
	}, [lockedOptions]);

	const totalDesigns = designDirections?.length ?? 0;
	const unlockedDesigns = totalDesigns - lockedOptions.length;

	useEffect(() => {
		const designsToRegenerate = unlockedDesigns > 3 ? 3 : unlockedDesigns;
		setAmountToRegenerate(designsToRegenerate);
	}, [unlockedDesigns]);

	const isLocked = (id: string) => lockedOptions.includes(id);

	const toggleLock = async (id: string) => {
		if (!campaignId) return;
		setLockedOptions((prev) => {
			const newLocked = prev.includes(id)
				? prev.filter((lockedId) => lockedId !== id)
				: [...prev, id];
			return newLocked;
		});
		try {
			await editCampaignDesignDirections(campaignId, id, {
				locked: !isLocked(id),
			});
		} catch (error) {}
	};

	const handleSubmit = async () => {
		if (!campaign || !campaignId) return;
		onClose();
		onSubmit();
	};

	const handleCancel = () => {
		setDesignDirections(initialDesignDirections);
		onClose();
		onCancel();
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			header="Do you want your changes to be reflected in the designs?"
			footer={
				<>
					<Button variant="orangeOutline" onClick={handleCancel}>
						No
					</Button>
					<Button variant="orangeSolid" ml={3} onClick={handleSubmit}>
						Yes
					</Button>
				</>
			}
		>
			<Text mb={4} color="black">
				Click lock{' '}
				<Box as="span" display="inline-flex" alignItems="center">
					<RiLockLine style={{ marginRight: '4px' }} />
				</Box>
				on each design you want to keep without any change.
			</Text>

			{unlockedDesigns > 3 && (
				<Text color="black" mb={4} mt="-14px">
					We will regenerate up to {amountToRegenerate} designs.
				</Text>
			)}

			<Flex align="center" gap={2} wrap="wrap">
				{designDirections?.map((dd) => {
					let normalizedTemplate = null;
					if (
						dd.adEngine === 'creatomate' &&
						isCreatomateAttributes(dd.attributes)
					) {
						// Se utiliza la cache: si ya se procesó, se reutiliza
						if (templatesCache.current[dd.id]) {
							normalizedTemplate = templatesCache.current[dd.id];
						} else {
							normalizedTemplate = normalizeCreatomateTemplate(dd.attributes);
							templatesCache.current[dd.id] = normalizedTemplate;
						}
					}

					return (
						<Box
							key={dd.id}
							textAlign="center"
							w="200px"
							position="relative"
							onMouseEnter={() => setIsHovering(dd.id)}
							onMouseLeave={() => setIsHovering(null)}
							cursor="pointer"
						>
							<Box position="relative">
								{dd.adEngine === 'creatomate' && normalizedTemplate ? (
									<Box
										w="200px"
										h="200px"
										borderRadius="2xl"
										overflow="hidden"
										position="relative"
									>
										<TemplatePreview template={normalizedTemplate} />
									</Box>
								) : (
									<Image
										src={(dd.attributes as any).image.flatFile}
										alt={dd.name}
										mb={2}
										borderRadius="2xl"
										fallbackSrc="/fusion-loader.gif"
									/>
								)}

								{(isHovering === dd.id || isLocked(dd.id)) && (
									<Flex
										position="absolute"
										top={0}
										left={0}
										w="full"
										h="full"
										bg="blackAlpha.700"
										align="center"
										justify="center"
										borderRadius="2xl"
										zIndex={1}
										onClick={() => toggleLock(dd.id)}
									>
										<VStack>
											{isLocked(dd.id) ? (
												<>
													<RiLockLine color="white" size={35} />
													<Text color="white">Locked design</Text>
												</>
											) : (
												<>
													<RiLockLine color="white" size={35} />
													<Text color="white">Lock design</Text>
												</>
											)}
										</VStack>
									</Flex>
								)}
							</Box>

							<CardActions
								title={`Option ${dd.variant}`}
								onLock={() => toggleLock(dd.id)}
								isLocked={isLocked(dd.id)}
							/>
						</Box>
					);
				})}
			</Flex>
		</CustomModal>
	);
};

export default DesignLockModal;
