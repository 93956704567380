import http from './http';

const apiVersion = 'v1';
const uploadUrl = `/${apiVersion}/images/upload`;

const uploadFile = async (
	file: File | string,
	required = true,
	minWidth?: number,
	minHeight?: number,
	minShortestSide?: number,
	minLongestSide?: number,
) => {
	const formData = new FormData();
	
	if (typeof file === 'string') {
		formData.append('image_url', file);
	} else {
		formData.append('image_file', file);
	}

	const params = new URLSearchParams();
	params.append('required', String(required));
	if (minWidth !== undefined) {
		params.append('minWidth', String(minWidth));
	}
	if (minHeight !== undefined) {
		params.append('minHeight', String(minHeight));
	}
	if (minShortestSide && minLongestSide) {
		params.append('minShortestSide', String(minShortestSide));
		params.append('minLongestSide', String(minLongestSide));
	}
	const { data } = await http.post(
		`${uploadUrl}?${params.toString()}`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	);
	return data;
};

export { uploadFile };
