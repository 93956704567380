import { IPlan, ISubscription } from 'src/models/account/interfaces';
import http from './http';

const apiVersion = 'v1';
const billingUrl = `/${apiVersion}/billing`;
const subscriptionUrl = `${billingUrl}/subscriptions`;
const planUrl = `${billingUrl}/plans`;

export const getSubscriptionDetails = async (subscriptionId: string) => {
	const { data } = await http.get<ISubscription>(
		`${subscriptionUrl}/${subscriptionId}`,
	);
	return data;
};

export const subscribe = async (planId: string) => {
	const { data } = await http.post(`${subscriptionUrl}`, { planId });
	return data;
};

export const getSubscriptionLink = async (mode: 'setup' | 'subscription') => {
	const { data } = await http.post<{ checkoutUrl: string }>(
		`${subscriptionUrl}/checkout`,
		{ mode },
	);
	return data;
};

export const getPlans = async () => {
	const { data } = await http.get<IPlan[]>(planUrl);
	return data;
};
