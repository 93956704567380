import {
	useState,
	useRef,
	useEffect,
	useMemo,
	useCallback,
	useContext,
} from 'react';
import {
	Flex,
	Box,
	Step,
	StepIndicator,
	StepStatus,
	Stepper,
	StepNumber,
	StepDescription,
	StepSeparator,
} from '@chakra-ui/react';

import {
	CampaignForm,
	CampaignBrief,
	CampaignBudget,
	DesignOutput,
	CampaignSchedule,
	LaunchCampaign,
} from 'src/components/campaign';
import { CampaignHeader } from 'src/components/campaign/parts';
import {
	AppInputConfigContext,
	CampaignContext,
	withAppConfigProvider,
	withCampaignProvider,
} from 'src/contexts';
import FusionLoading from 'src/components/common/FusionLoading';

import {
	createOrUpdateCampaign,
	genrateCampaignCreatives,
	genrateCampaignDesignDirections,
	getCampaign,
	launchCampaign,
} from 'src/services/campaign';
import {
	ICampaignBrief,
	ICampaignSchedule,
	IChannelCreativeAttributes,
	IChannelGroup,
	IChannelMediaAttributes,
	IDesignDirection,
} from 'src/lib/schemas';
import {
	transformCampaignToGenAIAppPayload,
	transformCampaignToGenAIPayload,
} from 'src/lib/utils';
import useCampaignConfig from 'src/hooks/useCampaignConfig';
import {
	generateCampaignBrief,
	generateCampaignDescription,
	generateCampaignGoals,
} from 'src/services/genai';
import { enrichText } from 'src/services/appsai';
import { getAvailableChannels } from 'src/services/account';
import { generateBudgetBreakdown } from 'src/components/campaign/CampaignBudget/parts/utils';
import { validateCampaignConfig } from 'src/components/campaign/utils/validations';
import { cleanObject_id } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { IOption } from 'src/lib/schemas/misc';
import UserContext from 'src/contexts/user/UserContext';
import CustomContainer from 'src/components/common/CustomContainer';
import { CheckIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';

const CreateCampaign = () => {
	const { integrations } = useMyIntegrations();
	const location = useLocation();
	const [group, setGroup] = useState<string | undefined>();
	const [availableChannelGroups, setAvailableChannelGroups] = useState<
		IChannelGroup[] | null
	>(null);
	const [availableAttributes, setAvailableAttributes] = useState<
		IChannelCreativeAttributes[] | null
	>(null);

	const [isLoadingChannels, setIsLoadingChannels] = useState(false);
	const [isGeneratingBrief, setIsGeneratingBrief] = useState(false);
	const [isGeneratingDesignDirections, setIsGeneratingDesignDirections] =
		useState(false);
	const [showDDSection, setShowDDSection] = useState(false);
	const [isBriefAndPromptVisible, setIsBriefAndPromptVisible] = useState(false);
	const [campaignTitle, setCampaignTitle] = useState('');
	const campaignConfigRef = useRef<HTMLDivElement>(null);
	const campaignBriefRef = useRef<HTMLDivElement>(null);
	const campaignDesignRef = useRef<HTMLDivElement>(null);
	const { config, isLoading: isConfigLoading } = useCampaignConfig();
	const [activeStep, setActiveStep] = useState(1);
	const [metaOptions, setMetaOptions] = useState<IOption[]>([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [selectedPlacements, setSelectedPlacements] = useState<string[]>([]);

	const {
		campaign,
		id: campaignId,
		isFetching,
		setCampaign,
	} = useContext(CampaignContext);
	const { inputConfig } = useContext(AppInputConfigContext);
	const tonesOptions = inputConfig['Tone'] ?? [];
	const audienceOptions = inputConfig['Audience'] ?? [];
	const { account } = useContext(UserContext);

	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			setScrollPosition(position);
		};
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const isDataLoaded = useRef(false);

	useEffect(() => {
		if (!campaign) return;
		let newStep = activeStep;
		if (
			campaign.designDirections &&
			campaign.designDirections.length > 0 &&
			newStep < 2
		) {
			newStep = 2;
		}
		if (campaign?.schedule?.startDate && newStep < 3) {
			newStep = 3;
		}
		if (!isDataLoaded.current && campaign.designDirections !== undefined) {
			isDataLoaded.current = true;
		}
		if (isDataLoaded.current && activeStep !== newStep) {
			setActiveStep(newStep);
		}
	}, [campaign]);

	const steps = [
		{ title: '', description: '' },
		{ title: '', description: 'Generate designs' },
		{ title: '', description: 'Review ads' },
		{ title: '', description: 'Publish ads' },
	];

	useEffect(() => {
		const getChannels = async () => {
			if (!account?.id) return;
			setIsLoadingChannels(true);
			const { channels, creatives } = await getAvailableChannels(account.id);
			setAvailableChannelGroups(channels);
			setAvailableAttributes(creatives);
			setIsLoadingChannels(false);
		};
		getChannels();
	}, [account]);

	const handleCampaignSubmit = async (totalBudget: number) => {
		if (!campaignId || !campaign) return;
		try {
			const budget = { ...campaign.budget, total: totalBudget };
			const genAIPayload = transformCampaignToGenAIPayload(
				{ ...campaign, budget },
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
			);
			setShowDDSection(true);
			setIsGeneratingBrief(true);
			setTimeout(() => {
				scrollIntoBrief();
			}, 100);
			setIsGeneratingDesignDirections(true);
			const brief = await generateCampaignBrief(genAIPayload);

			const response = await createOrUpdateCampaign({ brief }, campaignId);
			setCampaign(response);
			setIsGeneratingBrief(false);
		} catch (error: any) {
			toastError(error);
			setIsGeneratingBrief(false);
		}
	};

	const scrollIntoBrief = () => {
		if (!campaignBriefRef) return;
		const elementPosition =
			campaignBriefRef!.current!.getBoundingClientRect().top +
			window.pageYOffset;
		const offsetPosition = elementPosition - 150;
		setTimeout(() => {
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}, 100);
	};

	const handleRegenerateBrief = async () => {
		if (!campaignId || !campaign) return;
		try {
			const genAIPayload = transformCampaignToGenAIPayload(
				campaign,
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
			);
			const brief = await generateCampaignBrief(genAIPayload);
			const response = await createOrUpdateCampaign({ brief }, campaignId);

			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRegenerateDescription = async () => {
		if (!campaign || !campaignId || !campaign.brief) return;
		try {
			const genAIPayload = transformCampaignToGenAIPayload(
				campaign,
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
			);
			const description = await generateCampaignDescription(genAIPayload);
			const response = await createOrUpdateCampaign(
				{ brief: { ...cleanObject_id(campaign.brief), description } },
				campaignId,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRegenerateGoals = async () => {
		if (!campaign || !campaignId || !campaign.brief) return;
		try {
			const genAIPayload = transformCampaignToGenAIPayload(
				campaign,
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
			);
			const goals = await generateCampaignGoals(genAIPayload);
			const response = await createOrUpdateCampaign(
				{
					brief: {
						...cleanObject_id(campaign.brief),
						goals,
					},
				},
				campaignId,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleEnrichDescription = async (text: string) => {
		if (!campaign || !campaignId || !campaign.brief) return;
		try {
			const genAIPayload = transformCampaignToGenAIAppPayload(
				campaign,
				config,
				metaOptions,
				tonesOptions,
				audienceOptions,
				account,
				text,
			);
			const description = await enrichText(genAIPayload);
			const response = await createOrUpdateCampaign(
				{ brief: { ...cleanObject_id(campaign.brief), description } },
				campaignId,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleSaveBrief = async (brief: ICampaignBrief) => {
		if (!campaignId || !campaign) return;
		try {
			const resp = await createOrUpdateCampaign(
				{ brief: cleanObject_id(brief) },
				campaignId,
			);
			setCampaign(resp);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleGenerateCreatives = async (designDirectionId?: string) => {
		if (!campaignId) return;
		try {
			// await executeCampaignCreatives(campaignId, campaign?.brief?.prompt);
			await genrateCampaignCreatives(campaignId, designDirectionId);
			const data = await getCampaign(campaignId);
			setCampaign(data);
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		if (campaign?.creatives?.length && !campaign?.budget.breakdown?.length) {
			handleGenerateBudgetBreakdown();
		}
	}, [campaign?.creatives]);

	const handleGenerateDesignDirections = async () => {
		if (!campaignId) return;
		try {
			await genrateCampaignDesignDirections(campaignId);
			const data = await getCampaign(campaignId);
			setCampaign(data);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleGenerateBudgetBreakdown = async () => {
		if (!campaignId || !campaign) return;
		try {
			const initialBreakdown = generateBudgetBreakdown(
				campaign.channels ?? [],
				availableChannelGroups ?? [],
				campaign.budget.total,
			);
			const response = await createOrUpdateCampaign(
				{
					budget: { ...campaign.budget, breakdown: initialBreakdown },
				},
				campaignId,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleSubmitSchedule = async (schedule: ICampaignSchedule) => {
		if (!campaignId || !campaign) return;
		try {
			const response = await createOrUpdateCampaign(
				{
					schedule,
				},
				campaignId,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleLaunch = async () => {
		if (!campaignId) return;
		await launchCampaign(campaignId);
	};

	const handleScrollToView = (ref: React.RefObject<HTMLDivElement>) => {
		setTimeout(() => {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}, 100);
	};

	const isLoading = isFetching || isConfigLoading || isLoadingChannels;

	const availableChannels = useMemo(
		() =>
			(availableChannelGroups || []).flatMap(
				(channelGroup) => channelGroup.channels,
			),
		[availableChannelGroups],
	);

	const birefDetails = useMemo(() => {
		const campaignTones = campaign?.tone || [];
		const campaignChannels = campaign?.channels || [];
		const campaignAudience = campaign?.audience || [];

		const mappedChannels = (availableChannels || [])
			.filter((c) => campaignChannels.includes(c.id))
			.map((c) => c.name);

		const mappedTones = tonesOptions
			.filter((t) => campaignTones.includes(t.value))
			.map((t) => t.label);

		const mappedAudience = audienceOptions
			.filter((t) => campaignAudience.includes(t.value))
			.map((t) => t.label);

		return {
			budget: campaign?.budget?.total || 0,
			channels: mappedChannels,
			tones: mappedTones,
			audience: mappedAudience,
		};
	}, [campaign, availableChannels, tonesOptions, audienceOptions]);

	const handleMetaOptions = useCallback((options: IOption[]) => {
		if (options.length) {
			setMetaOptions(options);
		}
	}, []);

	const removeDuplicateLayers = useCallback((data?: IDesignDirection[]) => {
		if (!data) return [];
		return data.map((item: any) => {
			const attributes = item.attributes as IChannelMediaAttributes;
			const layers = attributes.image.layers;
			const uniqueLayers: any[] = [];
			const layerNames = new Set();

			if (!layers) return item;
			layers.forEach((layer) => {
				if (!layerNames.has(layer.name)) {
					uniqueLayers.push(layer);
					layerNames.add(layer.name);
				}
			});

			return {
				...item,
				attributes: {
					...item.attributes,
					image: {
						...item.attributes.image,
						layers: uniqueLayers,
					},
				},
			};
		});
	}, []);

	const memorizedDesignDirections = useMemo(() => {
		return removeDuplicateLayers(campaign?.designDirections);
	}, [campaign?.designDirections, removeDuplicateLayers]);

	useEffect(() => {
		if (location.state?.selectedChannel && campaignId) {
			const selectedChannel = availableChannels.find(
				(channel) => channel.id === location.state.selectedChannel,
			);

			// Check if selectedChannel is found and has placements
			if (selectedChannel && selectedChannel.placements) {
				const placements = selectedChannel.placements.map(
					(placement: any) => placement.id,
				);
				const channel = location.state.selectedChannel;

				createOrUpdateCampaign({ channels: [channel], placements }, campaignId);
			}
		}
	}, [location.state, campaignId, availableChannels]);

	useEffect(() => {
		if (campaign?.group) {
			setGroup(campaign.group);
		}
	}, [campaign]);

	useEffect(() => {
		Boolean(campaign?.brief) && setShowDDSection(true);
	}, [campaign]);

	const isValidCampaignConfig = useMemo(() => {
		if (!campaign) return false;
		return validateCampaignConfig(campaign, metaOptions ?? []);
	}, [campaign, metaOptions]);

	const isLaunchEnabled = !!campaign?.schedule;

	return (
		<Box w="full" position="relative">
			<CampaignHeader
				campaignDetail={{
					title: !campaignTitle ? 'New Campaign' : campaignTitle,
					group,
					lastEditTime: campaign?.updatedAt,
					lastEditBy: campaign?.lastUpdatedBy?.name,
				}}
				onGroupChange={(value) => setGroup(value)}
			/>
			<Box
				position="fixed"
				top={scrollPosition > 50 ? '72px' : '50px'}
				left="250px"
				right="0"
				zIndex="docked"
				backgroundColor="white"
				pr="150px"
				pl="50px"
				shadow={scrollPosition > 10 ? 'md' : 'none'}
			>
				<Flex justify="center" p={4} direction="column">
					<Stepper size="sm" index={activeStep} gap="0" colorScheme="red">
						{steps.map((step, index) => (
							<Step key={index}>
								<Flex
									direction={scrollPosition > 50 ? 'row' : 'column'}
									align="center"
									mt={scrollPosition > 50 ? '0' : '5'}
								>
									<StepIndicator
										bg="white"
										mr={index === 0 ? '-20px' : scrollPosition > 50 ? 1 : 0}
										mt={
											index === 0 ? (scrollPosition > 50 ? '0' : '-12px') : '0'
										}
									>
										<StepStatus
											complete={
												index === 0 ? (
													<Box
														display="flex"
														justifyContent="center"
														alignItems="center"
													></Box>
												) : (
													<Box
														display="flex"
														justifyContent="center"
														alignItems="center"
													>
														<CheckIcon />
													</Box>
												)
											}
											incomplete={
												index === activeStep ? (
													<Box
														bg="currentColor"
														borderRadius="full"
														boxSize="8px"
														my="0px"
														display="flex"
														justifyContent="center"
														alignItems="center"
													>
														<StepNumber
															style={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																height: '0px',
																lineHeight: '0px',
															}}
														>
															{index}
														</StepNumber>
													</Box>
												) : (
													<StepNumber
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
															height: '0px',
															lineHeight: '0px',
														}}
													>
														{index}
													</StepNumber>
												)
											}
											active={
												<StepNumber
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														height: '0px',
														lineHeight: '0px',
													}}
												>
													{index}
												</StepNumber>
											}
										/>
									</StepIndicator>
									<Box mt={scrollPosition > 50 ? 0 : 2}>
										<StepDescription>{step.description}</StepDescription>
									</Box>
								</Flex>
								{index < steps.length - 1 && <Box as={StepSeparator} />}
							</Step>
						))}
					</Stepper>
				</Flex>
			</Box>

			<FusionLoading isLoading={isLoading} boxProps={{ mt: 16 }} />
			<Flex
				display={isLoading ? 'none' : 'flex'}
				direction="column"
				mt="60px"
				minH="calc(100vh-72px)"
			>
				<Box ref={campaignConfigRef} />
				<CustomContainer>
					<CampaignForm
						group={group}
						availableChannels={availableChannels}
						onCampaignTitleChange={setCampaignTitle}
						onCampaignSubmit={handleCampaignSubmit}
						onMetaOptionsChange={handleMetaOptions}
						selectedPlacements={selectedPlacements}
						setSelectedPlacements={setSelectedPlacements}
					/>
					<Box ref={campaignBriefRef} />
					<CampaignBrief
						brief={campaign?.brief}
						birefDetails={birefDetails}
						isValidCampaignConfig={isValidCampaignConfig}
						onRegenerateBrief={handleRegenerateBrief}
						onRegenerateGoals={handleRegenerateGoals}
						onRegenerateDescription={handleRegenerateDescription}
						onEnrichDescription={handleEnrichDescription}
						onSaveBrief={handleSaveBrief}
						isGeneratingBrief={isGeneratingBrief}
						campaignTitle={campaignTitle}
					/>
					<Box ref={campaignDesignRef} />
					{showDDSection && (
						<DesignOutput
							creatives={campaign?.creatives}
							designDirections={memorizedDesignDirections}
							channelCreativeAttributes={availableAttributes}
							onGenerateCreatives={handleGenerateCreatives}
							onGenerateDesignDirections={handleGenerateDesignDirections}
							setIsBriefAndPromptVisible={setIsBriefAndPromptVisible}
							isBriefAndPromptVisible={isBriefAndPromptVisible}
							isGeneratingDesignDirections={isGeneratingDesignDirections}
							setIsGeneratingDesignDirections={setIsGeneratingDesignDirections}
						/>
					)}
					{!!campaign?.creatives?.length && (
						<>
							{!!campaign?.creatives?.length && (
								<CampaignSchedule
									schedule={campaign?.schedule}
									onScheduleSubmit={handleSubmitSchedule}
								/>
							)}
							<Box mt={4} />
							{!!campaign?.budget.breakdown?.length &&
								!!availableChannelGroups && (
									<CampaignBudget
										availableChannelGroups={availableChannelGroups}
									/>
								)}

							<LaunchCampaign
								isValidCampaignConfig={isValidCampaignConfig}
								isSocialEnabled={integrations?.social.fb.enabled}
								onLaunch={handleLaunch}
							/>
						</>
					)}
				</CustomContainer>
			</Flex>
		</Box>
	);
};

const CreateCampaignWithProvider = withCampaignProvider(CreateCampaign);
const CreateCampaignWithAppConfigProvider = withAppConfigProvider(
	CreateCampaignWithProvider,
);

export default CreateCampaignWithAppConfigProvider;
