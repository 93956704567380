interface Map {
	[key: string]: MapValue;
}

interface MapValue {
	keywords?: string[];
	alternativeIndustries: string[];
}


export const DEFAULT_VALUES: Map = {
	'QWdyaWN1bHR1cmU=': { keywords: [], alternativeIndustries: [] },
	QWdyaWN1bHR1cmUgJiBGb3Jlc3RyeQ: {
		alternativeIndustries: ['TGFuZHNjYXBlcnM='],
		keywords: [
			'organic farming supplies',
			'agricultural equipment',
			'farm tools',
			'seeds and seedlings',
			'fertilizers',
			'pesticides',
			'irrigation systems',
			'greenhouse supplies',
			'farming machinery',
			'harvest equipment',
			'crop protection products',
			'soil amendments',
			'plant nutrients',
			'farm safety equipment',
			'animal feed',
			'livestock supplies',
			'agricultural chemicals',
			'farming tools',
			'crop seeds',
			'organic pesticides',
			'hydroponic supplies',
			'agricultural technology',
			'farm storage solutions',
			'crop monitoring systems',
			'farm vehicles',
			'agricultural drones',
			'farming software',
			'soil testing kits',
			'sustainable farming supplies',
			'planting equipment',
			'harvesting tools',
			'agricultural fencing',
			'pest control products',
			'composting supplies',
			'farm maintenance equipment',
			'agricultural biologicals',
			'farming accessories',
			'water management systems',
			'precision farming tools',
			'farm automation systems',
			'organic fertilizers',
			'crop care products',
		],
	},
	QW50aXF1ZXMgJiBDb2xsZWN0aWJsZXM: {
		alternativeIndustries: [
			'SG9iYmllcyAmIExlaXN1cmU',
			'THV4dXJ5IEdvb2Rz',
			'RnVybml0dXJl',
			'THV4dXJ5IEdvb2Rz',
		],
		keywords: [
			'antique furniture',
			'vintage collectibles',
			'rare artifacts',
			'antique jewelry',
			'classic art',
			'vintage watches',
			'antique coins',
			'rare stamps',
			'vintage toys',
			'antique books',
			'vintage clothing',
			'classic memorabilia',
			'antique pottery',
			'vintage cameras',
			'classic records',
			'antique clocks',
			'vintage electronics',
			'rare military items',
			'antique musical instruments',
			'vintage sports memorabilia',
			'antique maps',
			'vintage advertisements',
			'classic postcards',
			'antique weapons',
			'vintage dolls',
			'classic comic books',
			'antique glass',
			'vintage magazines',
			'rare documents',
			'antique lighting',
			'vintage accessories',
			'classic movie memorabilia',
			'antique rugs',
			'vintage gaming items',
			'rare photographs',
			'antique silver',
			'vintage automotive parts',
			'classic vinyl records',
			'antique kitchenware',
			'vintage fashion accessories',
			'rare artifacts appraisal',
		],
	},
	QXJjaGl0ZWN0dXJl: {
		alternativeIndustries: ['UmVhbCBFc3RhdGU', 'QXJ0cyAmIEVudGVydGFpbm1lbnQ'],
		keywords: [
			'architectural design',
			'residential architect',
			'commercial architecture',
			'interior design',
			'sustainable architecture',
			'modern architecture',
			'architectural planning',
			'building design',
			'renovation design',
			'custom home design',
			'architectural consulting',
			'3D visualization',
			'construction documentation',
			'green building design',
			'urban planning',
			'landscape architecture',
			'architectural rendering',
			'building permits',
			'space planning',
			'adaptive reuse',
			'historic preservation',
			'commercial renovation',
			'architectural drafting',
			'LEED certification',
			'building codes compliance',
			'design-build services',
			'project management',
			'facade design',
			'site planning',
			'architectural modeling',
			'sustainable design',
			'architectural blueprints',
			'BIM services',
			'architectural consultation',
			'structural design',
			'architectural restoration',
			'office design',
			'retail architecture',
			'industrial architecture',
		],
	},
	QXJ0cyAmIEVudGVydGFpbm1lbnQ: {
		alternativeIndustries: ['RXZlbnRz', 'Q29ycG9yYXRlIEV2ZW50cw'],
		keywords: [
			'movie reviews',
			'entertainment news',
			'celebrity updates',
			'film industry',
			'TV shows',
			'music reviews',
			'concert events',
			'theater performances',
			'art galleries',
			'music festivals',
			'streaming services',
			'movie releases',
			'live performances',
			'entertainment tickets',
			'Broadway shows',
			'art exhibitions',
			'film criticism',
			'music industry',
			'celebrity interviews',
			'event listings',
			'concert tickets',
			'theater reviews',
			'movie trailers',
			'album releases',
			'artist profiles',
			'entertainment guides',
			'event calendar',
			'art auctions',
			'film festivals',
			'music streaming',
			'theater productions',
			'movie showtimes',
			'concert venues',
			'art criticism',
			'celebrity news',
			'music events',
			'stage performances',
			'entertainment podcasts',
			'film screenings',
			'art shows',
			'music venues',
			'entertainment podcasts',
		],
	},
	VmVoaWNsZSBQYXJ0cyAmIFNlcnZpY2Vz: {
		alternativeIndustries: [
			'VmVoaWNsZSBSZXBhaXIgJiBNYWludGVuYW5jZQ',
			'QXV0b3MgJiBWZWhpY2xlcw',
			'Q2FyIHJlbnRhbA==',
		],
		keywords: [
			'auto parts',
			'car accessories',
			'motorcycle parts',
			'car engines',
			'brake systems',
			'transmission parts',
			'suspension parts',
			'exhaust systems',
			'car batteries',
			'spark plugs',
			'oil filters',
			'motorcycle accessories',
			'performance parts',
			'engine parts',
			'car lighting',
			'auto body parts',
			'wheel parts',
			'automotive tools',
			'steering components',
			'radiators',
			'fuel pumps',
			'motorcycle gear',
			'car interior parts',
			'air filters',
			'auto electrical parts',
			'motorcycle engines',
			'brake pads',
			'ignition systems',
			'aftermarket parts',
			'car electronics',
			'tire accessories',
			'performance upgrades',
			'car maintenance parts',
			'motorcycle accessories',
			'auto repair tools',
			'auto diagnostics',
			'car audio systems',
			'engine tuning parts',
			'vehicle safety parts',
			'custom motorcycle parts',
		],
	},
	VmVoaWNsZSBSZXBhaXIgJiBNYWludGVuYW5jZQ: {
		alternativeIndustries: [
			'VmVoaWNsZSBQYXJ0cyAmIFNlcnZpY2Vz',
			'QXV0b3MgJiBWZWhpY2xlcw',
			'Q2FyIGRldGFpbGluZw==',
		],
		keywords: [
			'auto repair services',
			'car mechanic',
			'motorcycle repair',
			'engine diagnostics',
			'brake service',
			'transmission repair',
			'oil change service',
			'tire service',
			'auto maintenance',
			'car inspection',
			'motorcycle maintenance',
			'engine repair',
			'vehicle repairs',
			'collision repair',
			'suspension service',
			'radiator repair',
			'auto electrical repair',
			'tune up service',
			'brake repair shop',
			'car AC repair',
			'motorcycle service center',
			'check engine light',
			'wheel alignment',
			'transmission service',
			'auto body repair',
			'car battery service',
			'motorcycle tune up',
			'exhaust repair',
			'auto diagnostics',
			'preventive maintenance',
			'fleet maintenance',
			'automotive repair shop',
			'car repair near me',
			'motorcycle repair shop',
			'auto service center',
			'engine rebuilding',
			'brake replacement',
		],
	},
	QXV0b3MgJiBWZWhpY2xlcw: {
		alternativeIndustries: [
			'VmVoaWNsZSBSZXBhaXIgJiBNYWludGVuYW5jZQ',
			'VmVoaWNsZSBQYXJ0cyAmIFNlcnZpY2Vz',
			'Q2FyIHJlbnRhbA==',
		],
		keywords: [
			'new cars for sale',
			'used cars',
			'motorcycle dealers',
			'car dealership',
			'used motorcycles',
			'auto sales',
			'vehicle listings',
			'certified pre-owned',
			'motorcycle sales',
			'new motorcycles',
			'car prices',
			'motorcycle prices',
			'auto financing',
			'vehicle warranty',
			'sports bikes',
			'luxury cars',
			'SUV sales',
			'truck sales',
			'electric vehicles',
			'classic cars',
			'motorcycle financing',
			'car comparison',
			'test drive',
			'auto insurance',
			'car buying guide',
			'motorcycle buyer guide',
			'vehicle inspection',
			'trade-in value',
			'car lease deals',
			'custom motorcycles',
			'car reviews',
			'motorcycle reviews',
			'vehicle specifications',
			'dealership near me',
			'auto loans',
			'motorcycle gear',
			'vehicle registration',
			'car maintenance history',
		],
	},
	RmFtaWx5: {
		alternativeIndustries: [
			'UGV0cyAmIEFuaW1hbHM',
			'VG95cw',
			'SG9iYmllcyAmIExlaXN1cmU',
		],
		keywords: [
			'baby clothes',
			'diapers',
			'baby furniture',
			'infant toys',
			'baby gear',
			'strollers',
			'car seats',
			'baby monitors',
			'cribs',
			'baby feeding',
			'baby carriers',
			'baby bedding',
			'nursery decor',
			'baby safety',
			'baby bath',
			'baby bottles',
			'pacifiers',
			'baby skincare',
			'diaper bags',
			'high chairs',
			'baby swings',
			'baby bouncers',
			'baby formula',
			'baby food',
			'baby shoes',
			'maternity wear',
			'baby books',
			'baby gates',
			'baby walkers',
			'baby blankets',
			'baby slings',
			'baby healthcare',
			'baby grooming',
			'baby proofing',
			'teething products',
			'baby travel gear',
			'baby wipes',
			'pregnancy products',
			'baby accessories',
		],
	},
	QmVhdXR5ICYgRml0bmVzcw: {
		alternativeIndustries: [
			'THV4dXJ5IEdvb2Rz',
			'SGFpciBDYXJl',
			'SGFpciBEcmVzc2Vy',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'beauty products',
			'skincare',
			'makeup',
			'cosmetics',
			'hair care',
			'natural beauty products',
			'organic beauty',
			'vegan beauty',
			'cruelty-free beauty',
			'anti-aging',
			'face cream',
			'serum',
			'moisturizer',
			'cleanser',
			'toner',
			'face mask',
			'eye cream',
			'lipstick',
			'foundation',
			'concealer',
			'mascara',
			'eyeliner',
			'eyeshadow',
			'blush',
			'bronzer',
			'highlighter',
			'makeup brushes',
			'makeup tools',
			'shampoo',
			'conditioner',
			'hair styling products',
			'hair treatments',
			'hair oils',
			'beauty tips',
			'beauty trends',
			'beauty routine',
			'beauty essentials',
			'beauty blog',
			'beauty vlog',
			'beauty influencer',
			'beauty brand',
			'beauty store',
			'beauty salon',
			'beauty treatment',
			'beauty subscription box',
			'beauty gift set',
			'beauty deals',
			'beauty sale',
			'beauty discount',
			'beauty review',
			'beauty tutorial',
			'beauty how-to',
			'beauty advice',
			'beauty community',
			'beauty industry',
			'beauty innovation',
			'beauty technology',
			'beauty gadgets',
			'beauty devices',
			'at-home beauty',
			'DIY beauty',
			'natural ingredients',
			'clean beauty',
			'sustainable beauty',
			'eco-friendly beauty',
			'beauty packaging',
			'beauty e-commerce',
			'beauty online store',
			'beauty shopping',
			'beauty gift ideas',
			'beauty must-haves',
			'cult beauty products',
			'beauty bestsellers',
			'beauty awards',
			'beauty editor picks',
			'celebrity beauty',
			'professional beauty',
			'luxury beauty',
			'affordable beauty',
			'drugstore beauty',
			'indie beauty brands',
			'beauty startups',
			'beauty entrepreneurship',
			"men's grooming",
			"men's skincare",
			"men's hair care",
			'beauty for all skin types',
			'beauty for all skin tones',
			'inclusive beauty',
			'diverse beauty',
			'global beauty trends',
			'K-beauty',
			'J-beauty',
			'European beauty',
			'American beauty',
		],
	},
	Qm9va3MgJiBMaXRlcmF0dXJl: {
		alternativeIndustries: [
			'RWR1Y2F0aW9u',
			'QW50aXF1ZXMgJiBDb2xsZWN0aWJsZXM',
			'SG9iYmllcyAmIExlaXN1cmU',
		],
		keywords: [
			'fiction',
			'non-fiction',
			'bestsellers',
			'new releases',
			'classic literature',
			'novels',
			'biographies',
			'memoirs',
			'poetry',
			'short stories',
			'audiobooks',
			'e-books',
			'hardcover',
			'paperback',
			'book reviews',
			'author interviews',
			'book clubs',
			'reading lists',
			'literary fiction',
			'genre fiction',
			'mystery',
			'thriller',
			'romance',
			'science fiction',
			'fantasy',
			'historical fiction',
			"children's books",
			'young adult books',
			'self-help',
			'personal development',
			'business',
			'finance',
			'travel',
			'cooking',
			'food',
			'health',
			'wellness',
			'religion',
			'spirituality',
			'philosophy',
			'art',
			'photography',
			'coffee table books',
			'gift books',
			'signed copies',
			'limited editions',
			'book accessories',
			'bookmarks',
			'book lights',
			'book stands',
			'reading glasses',
		],
	},
	QWVyb3NwYWNlICYgRGVmZW5zZQ: {
		alternativeIndustries: [
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'RWR1Y2F0aW9u',
			'Q29uc3VsdGluZw',
			'Q29ycG9yYXRlIEV2ZW50cw',
			'U2FhUw==',
			'U29mdHdhcmU',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QXJ0cyAmIEVudGVydGFpbm1lbnQ',
			'T25saW5lIENvbW11bml0aWVz',
			'QWR2ZXJ0aXNpbmcgJiBNYXJrZXRpbmc',
			'TGF3ICYgR292ZXJubWVudA',
		],
		keywords: [
			'business services',
			'professional services',
			'consulting services',
			'business solutions',
			'business support',
			'business outsourcing',
			'business process optimization',
			'business strategy',
			'business planning',
			'business development',
			'business growth',
			'business efficiency',
			'business technology',
			'business software',
			'business automation',
			'business analytics',
			'business intelligence',
			'business finance',
			'business accounting',
			'business legal services',
			'business HR services',
			'business marketing services',
			'business branding',
			'business advertising',
			'business web design',
			'business app development',
			'business IT services',
			'business cloud services',
			'business cybersecurity',
			'business continuity',
			'business risk management',
			'business compliance',
			'business sustainability',
			'business innovation',
			'business transformation',
			'business change management',
			'business coaching',
			'business training',
			'business mentoring',
			'business networking',
			'business events',
			'business conferences',
			'business workshops',
			'business seminars',
			'business webinars',
			'business ecommerce',
			'business logistics',
			'business supply chain',
			'business operations',
			'business project management',
			'business quality assurance',
			'business customer service',
			'business sales',
			'business lead generation',
			'business partnerships',
			'business collaboration',
			'business globalization',
			'business localization',
			'business translation',
			'business interpretation',
			'business research',
			'business analysis',
			'business benchmarking',
			'business best practices',
		],
	},
	'Q2FyIGRldGFpbGluZw==': {
		alternativeIndustries: [
			'VmVoaWNsZSBQYXJ0cyAmIFNlcnZpY2Vz',
			'VmVoaWNsZSBSZXBhaXIgJiBNYWludGVuYW5jZQ',
			'Q2FyIHdhc2g=',
			'Q2xlYW5pbmcgU2VydmljZXM',
		],
		keywords: [
			'car detailing',
			'auto detailing',
			'mobile car detailing',
			'car detailing services',
			'professional car detailing',
			'car wash and detailing',
			'car interior detailing',
			'car exterior detailing',
			'car detailing near me',
			'car detailing prices',
			'car detailing products',
			'car detailing tips',
			'car detailing business',
			'car detailing supplies',
			'car detailing equipment',
			'car paint correction',
			'car ceramic coating',
			'car paint protection',
			'car waxing',
			'car polishing',
			'car steam cleaning',
			'car upholstery cleaning',
			'car leather cleaning',
			'car engine detailing',
			'car headlight restoration',
		],
	},
	'Q2FyIHJlbnRhbA==': {
		alternativeIndustries: [
			'QXV0b3MgJiBWZWhpY2xlcw',
			'VmVoaWNsZSBSZXBhaXIgJiBNYWludGVuYW5jZQ',
			'VHJhdmVs',
		],
		keywords: [
			'car rental',
			'rent a car',
			'vehicle rental',
			'car hire',
			'automobile rental',
			'luxury car rental',
			'sports car rental',
			'SUV rental',
			'convertible rental',
			'economy car rental',
			'compact car rental',
			'long-term car rental',
			'short-term car rental',
			'one-way car rental',
			'airport car rental',
			'cheap car rental',
			'discount car rental',
			'online car rental',
			'car rental deals',
			'car rental rates',
			'car rental reservations',
			'car rental locations',
			'car rental fleet',
			'car rental insurance',
			'car rental age requirements',
			'car rental terms and conditions',
			'car rental FAQ',
			'car rental reviews',
		],
	},
	'Q2FyIHdhc2g=': {
		alternativeIndustries: [
			'Q2FyIGRldGFpbGluZw==',
			'SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw',
			'UGFpbnRpbmc=',
			'SG9tZSBJbXByb3ZlbWVudA',
		],
		keywords: [
			'car wash',
			'car detailing',
			'auto detailing',
			'car cleaning',
			'vehicle cleaning',
			'hand car wash',
			'touchless car wash',
			'mobile car wash',
			'car wash near me',
			'car wash services',
			'car wash and wax',
			'full service car wash',
			'express car wash',
			'self service car wash',
			'car interior cleaning',
			'car exterior cleaning',
			'car polish',
			'car paint protection',
			'car wash prices',
			'car wash coupons',
			'car wash discounts',
			'car wash membership',
			'car wash subscription',
			'eco-friendly car wash',
			'waterless car wash',
		],
	},
	Q2FycGVudHJ5: {
		alternativeIndustries: [
			'UGFpbnRpbmc=',
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'V2luZG93IFNlcnZpY2luZw==',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
			'UGVzdCBDb250cm9s',
		],
		keywords: [
			'carpentry services',
			'woodworking',
			'custom carpentry',
			'wood craftsmanship',
			'cabinetry',
			'furniture making',
			'wood construction',
			'carpenter',
			'joinery',
			'framing',
			'finish carpentry',
			'trim work',
			'wood restoration',
			'door installation',
			'window installation',
			'decking',
			'fencing',
			'shelving',
			'built-ins',
			'mantels',
			'staircases',
			'wood carving',
			'wood turning',
			'millwork',
			'residential carpentry',
			'commercial carpentry',
		],
	},
	Q2xlYW5pbmcgU2VydmljZXM: {
		alternativeIndustries: [
			'Q2FyIGRldGFpbGluZw==',
			'Q2FyIHdhc2g=',
			'SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw',
			'UGFpbnRpbmc=',
			'SG9tZSBJbXByb3ZlbWVudA',
		],
		keywords: [
			'cleaning services',
			'house cleaning',
			'apartment cleaning',
			'office cleaning',
			'maid service',
			'professional cleaning',
			'deep cleaning',
			'move-in cleaning',
			'move-out cleaning',
			'post-construction cleaning',
			'carpet cleaning',
			'window cleaning',
			'pressure washing',
			'disinfection services',
			'eco-friendly cleaning',
			'green cleaning',
			'residential cleaning',
			'commercial cleaning',
			'janitorial services',
			'spring cleaning',
			'one-time cleaning',
			'recurring cleaning',
			'same-day cleaning',
			'affordable cleaning',
			'reliable cleaning',
			'experienced cleaners',
			'insured cleaners',
			'bonded cleaners',
			'local cleaning company',
		],
	},
	Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M: {
		alternativeIndustries: [
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
			'R2FtZXM',
			'SG9iYmllcyAmIExlaXN1cmU',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'U2FhUw==',
			'T2ZmaWNlIFN1cHBsaWVz',
		],
		keywords: [
			'computers',
			'laptops',
			'desktops',
			'PC',
			'computer hardware',
			'computer parts',
			'computer components',
			'processors',
			'CPUs',
			'graphics cards',
			'GPUs',
			'motherboards',
			'RAM',
			'memory',
			'hard drives',
			'HDDs',
			'SSDs',
			'solid state drives',
			'power supplies',
			'cases',
			'computer cases',
			'cooling',
			'fans',
			'heatsinks',
			'peripherals',
			'monitors',
			'keyboards',
			'mice',
			'mouse',
			'webcams',
			'microphones',
			'headsets',
			'speakers',
			'printers',
			'scanners',
			'cables',
			'adapters',
			'docking stations',
			'laptop accessories',
			'gaming computers',
			'gaming laptops',
			'gaming desktops',
			'gaming hardware',
			'overclocking',
			'computer upgrades',
			'computer maintenance',
			'computer repair',
			'computer troubleshooting',
			'computer deals',
			'computer discounts',
			'computer sales',
			'refurbished computers',
			'used computers',
		],
	},
	Q29uY2llcmdlIHNlcnZpY2U: {
		alternativeIndustries: ['THV4dXJ5IEdvb2Rz', 'VHJhdmVs', 'V2VkZGluZ3M='],
		keywords: [
			'concierge services',
			'personal concierge',
			'luxury concierge',
			'travel concierge',
			'lifestyle management',
			'errand services',
			'time-saving services',
			'VIP concierge',
			'private concierge',
			'corporate concierge',
			'hotel concierge',
			'event planning',
			'reservation services',
			'personal assistant',
			'bespoke concierge',
			'24/7 concierge',
			'on-demand concierge',
			'concierge company',
			'concierge membership',
			'exclusive concierge',
		],
	},
	Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U: {
		alternativeIndustries: [
			'UGFpbnRpbmc=',
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'V2luZG93IFNlcnZpY2luZw==',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
			'UGVzdCBDb250cm9s',
		],
		keywords: [
			'construction services',
			'maintenance services',
			'building maintenance',
			'facility maintenance',
			'property maintenance',
			'building repairs',
			'construction company',
			'general contractor',
			'handyman services',
			'remodeling services',
			'renovation services',
			'home improvement',
			'commercial construction',
			'residential construction',
			'plumbing services',
			'electrical services',
			'Heating',
			'Ventilation',
			'and Air Conditioning services',
			'roofing services',
			'painting services',
			'landscaping services',
			'carpentry services',
			'masonry services',
			'flooring services',
			'welding services',
			'concrete services',
			'excavation services',
			'demolition services',
			'drywall services',
			'insulation services',
			'window replacement',
			'door replacement',
			'energy efficiency upgrades',
			'safety inspections',
			'preventive maintenance',
			'emergency repairs',
			'24/7 service',
			'licensed and insured',
			'experienced contractors',
			'quality workmanship',
			'customer satisfaction',
			'free estimates',
			'competitive pricing',
			'timely completion',
			'project management',
		],
	},
	Q29uc3VsdGluZw: {
		alternativeIndustries: [
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'Q29ycG9yYXRlIEV2ZW50cw',
			'U2FhUw==',
			'U29mdHdhcmU',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QXJ0cyAmIEVudGVydGFpbm1lbnQ',
			'R2FtZXM',
			'T25saW5lIENvbW11bml0aWVz',
			'RWR1Y2F0aW9u',
		],
		keywords: [
			'consulting services',
			'business consulting',
			'management consulting',
			'strategy consulting',
			'IT consulting',
			'financial consulting',
			'HR consulting',
			'marketing consulting',
			'operations consulting',
			'technology consulting',
			'small business consulting',
			'startup consulting',
			'digital transformation consulting',
			'change management consulting',
			'performance improvement consulting',
			'risk management consulting',
			'sustainability consulting',
			'innovation consulting',
			'data analytics consulting',
			'supply chain consulting',
		],
	},
	Q29uc3VtZXIgRWxlY3Ryb25pY3M: {
		alternativeIndustries: [
			'R2FtZXM',
			'SG9iYmllcyAmIExlaXN1cmU',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'U2FhUw==',
			'T2ZmaWNlIFN1cHBsaWVz',
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
		],
		keywords: [
			'consumer electronics',
			'electronics store',
			'online electronics',
			'home electronics',
			'electronics deals',
			'electronics sale',
			'electronics accessories',
			'gadgets',
			'tech gadgets',
			'smart home devices',
			'smartphones',
			'tablets',
			'laptops',
			'computers',
			'TVs',
			'smart TVs',
			'home theater systems',
			'soundbars',
			'headphones',
			'earbuds',
			'speakers',
			'bluetooth speakers',
			'wearables',
			'smartwatches',
			'fitness trackers',
			'cameras',
			'digital cameras',
			'action cameras',
			'drones',
			'gaming consoles',
			'video games',
			'office electronics',
			'printers',
			'scanners',
			'projectors',
			'computer accessories',
			'phone accessories',
			'tablet accessories',
			'chargers',
			'power banks',
			'cables',
			'screen protectors',
			'cases',
			'mounts',
			'stands',
			'electronics gifts',
			'electronics reviews',
			'electronics comparisons',
			'electronics guides',
			'electronics warranty',
			'electronics support',
		],
	},
	Q29ycG9yYXRlIEV2ZW50cw: {
		alternativeIndustries: [
			'RXZlbnRz',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'U29mdHdhcmU',
			'T25saW5lIENvbW11bml0aWVz',
			'RWR1Y2F0aW9u',
			'U2FhUw==',
		],
		keywords: [
			'corporate events',
			'corporate event planning',
			'corporate event management',
			'corporate event services',
			'corporate event venues',
			'corporate event ideas',
			'corporate event themes',
			'corporate event entertainment',
			'corporate event catering',
			'corporate event logistics',
			'corporate event production',
			'corporate event design',
			'corporate event technology',
			'virtual corporate events',
			'hybrid corporate events',
			'corporate event trends',
			'corporate event best practices',
			'corporate event case studies',
			'corporate event ROI',
			'corporate event marketing',
			'corporate event branding',
			'corporate event sponsorships',
			'corporate event budgeting',
			'corporate event scheduling',
			'corporate event staffing',
		],
	},
	RWR1Y2F0aW9u: {
		alternativeIndustries: [
			'Q29ycG9yYXRlIEV2ZW50cw',
			'Qm9va3MgJiBMaXRlcmF0dXJl',
			'RXZlbnRz',
			'U2FhUw==',
		],
		keywords: [
			'online courses',
			'e-learning',
			'distance learning',
			'online education',
			'virtual classroom',
			'learning management system',
			'LMS',
			'educational technology',
			'edtech',
			'online degree',
			'online certification',
			'online training',
			'online tutoring',
			'online classes',
			'online school',
			'online university',
			'online college',
			'online learning platform',
			'online learning resources',
			'online curriculum',
			'online teaching',
			'online assessment',
			'online exam',
			'online quiz',
			'online assignment',
			'online homework',
			'online study materials',
			'online textbooks',
			'online lectures',
			'online videos',
			'online simulations',
			'online labs',
			'online discussion forums',
			'online collaboration tools',
			'online student portal',
			'online faculty portal',
			'online admissions',
			'online enrollment',
			'online registration',
			'online payment',
			'online financial aid',
			'online scholarships',
			'online grants',
			'online loans',
			'online career services',
			'online job placement',
			'online alumni network',
			'school',
			'education',
			'learning',
			'academics',
			'students',
			'teachers',
			'curriculum',
			'classes',
			'courses',
			'programs',
			'extracurricular activities',
			'admissions',
			'enrollment',
			'tuition',
			'scholarships',
			'campus',
			'facilities',
			'elementary school',
			'middle school',
			'high school',
			'private school',
			'public school',
			'charter school',
			'college prep',
			'STEM',
		],
	},
	RWxlY3RyaWNpYW5z: {
		alternativeIndustries: [
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SG9tZSBCdWlsZGVy',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'UGVzdCBDb250cm9s',
			'V2luZG93IFNlcnZpY2luZw==',
		],
		keywords: [
			'electrician',
			'electrical services',
			'electrical contractor',
			'electrical repairs',
			'electrical installation',
			'electrical wiring',
			'electrical panel upgrade',
			'electrical maintenance',
			'emergency electrician',
			'residential electrician',
			'commercial electrician',
			'licensed electrician',
			'master electrician',
			'electrical safety',
			'electrical inspection',
			'electrical code compliance',
			'electrical troubleshooting',
			'electrical outlet installation',
			'electrical switch installation',
			'electrical lighting installation',
			'electrical home improvements',
			'electrical remodeling',
			'electrical car charging station installation',
			'generator installation',
			'surge protection',
			'electrical service upgrade',
			'electrical energy efficiency',
			'electrical repair near me',
			'local electrician',
		],
	},
	RXZlbnRz: {
		alternativeIndustries: [
			'Q29ycG9yYXRlIEV2ZW50cw',
			'V2VkZGluZ3M=',
			'U2FhUw==',
			'V2F0ZXIgQWN0aXZpdGllcw',
			'SG9iYmllcyAmIExlaXN1cmU',
		],
		keywords: [
			'event planning',
			'event management',
			'event organizer',
			'event coordination',
			'event production',
			'event logistics',
			'event marketing',
			'event promotion',
			'event ticketing',
			'event registration',
			'event scheduling',
			'event venue',
			'event location',
			'event space',
			'event rentals',
			'event supplies',
			'event decorations',
			'event catering',
			'event entertainment',
			'event speakers',
			'event sponsors',
			'event budget',
			'event timeline',
			'event agenda',
			'event program',
			'event activities',
			'event games',
			'event contests',
			'event giveaways',
			'event swag',
			'event photography',
			'event videography',
			'event live streaming',
			'event social media',
			'event hashtags',
			'event feedback',
			'event surveys',
			'event analytics',
			'event ROI',
			'corporate events',
			'private events',
			'special events',
			'virtual events',
			'hybrid events',
			'trade shows',
			'conferences',
			'seminars',
			'workshops',
			'weddings',
			'parties',
			'celebrations',
			'fundraisers',
			'galas',
			'festivals',
			'fairs',
			'concerts',
			'performances',
		],
	},
	QXBwYXJlbA: {
		alternativeIndustries: [
			'THV4dXJ5IEdvb2Rz',
			'SmV3ZWxyeQ==',
			'QmVhdXR5ICYgRml0bmVzcw',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'fashion',
			'clothing',
			'clothes',
			'apparel',
			'style',
			'trendy',
			'fashion trends',
			"women's fashion",
			"men's fashion",
			'dresses',
			'tops',
			'bottoms',
			'jeans',
			'pants',
			'shorts',
			'skirts',
			'sweaters',
			'jackets',
			'coats',
			'activewear',
			'athleisure',
			'swimwear',
			'lingerie',
			'underwear',
			'sleepwear',
			'shoes',
			'boots',
			'heels',
			'sneakers',
			'sandals',
			'flats',
			'accessories',
			'jewelry',
			'bags',
			'handbags',
			'purses',
			'wallets',
			'belts',
			'hats',
			'scarves',
			'sunglasses',
			'watches',
			'plus size',
			'petite',
			'tall',
			'maternity',
			'designer',
			'luxury',
			'affordable',
			'casual',
			'formal',
			'work wear',
			'evening wear',
			'party wear',
			'seasonal',
			'summer',
			'winter',
			'fall',
			'spring',
			'outfits',
			'looks',
			'street style',
			'celebrity style',
			'fashion tips',
			'fashion advice',
			'fashion blog',
			'fashion magazine',
			'online shopping',
			'ecommerce',
		],
	},
	RmluYW5jZQ: {
		alternativeIndustries: [
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'Q29uc3VsdGluZw',
			'Q29ycG9yYXRlIEV2ZW50cw',
			'RWR1Y2F0aW9u',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'QWR2ZXJ0aXNpbmcgJiBNYXJrZXRpbmc',
			'RXZlbnRz',
			'U2FhUw==',
			'U29mdHdhcmU',
			'Sm9icw',
			'TGF3ICYgR292ZXJubWVudA',
		],
		keywords: [
			'accounting services',
			'bookkeeping services',
			'tax preparation services',
			'financial reporting',
			'payroll services',
			'business accounting',
			'accountant',
			'certified public accountant',
			'local accounting firm',
			'monthly bookkeeping services',
			'business tax preparation services',
			'financial statement preparation',
			'accounts payable and receivable services',
			'QuickBooks accounting services',
			'audit preparation assistance',
			'tax planning help',
			'reduce tax liability',
			'retail business accounting',
			'e-commerce bookkeeping',
			'experienced accountant',
			'professional accounting services',
			'reliable bookkeeping services',
			'financial advisory',
			'accountancy',
			'tax',
			'audit',
			'accountants',
		],
	},
	RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz: {
		alternativeIndustries: [
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'UGx1bWJlcnM=',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'RWxlY3RyaWNpYW5z',
			'UGVzdCBDb250cm9s',
		],
		keywords: [
			'fire protection',
			'fire safety',
			'fire prevention',
			'fire alarm systems',
			'fire extinguishers',
			'fire sprinkler systems',
			'fire suppression',
			'fire risk assessment',
			'fire safety training',
			'fire evacuation plans',
			'security systems',
			'security cameras',
			'CCTV',
			'access control',
			'intrusion detection',
			'burglar alarms',
			'security monitoring',
			'security guards',
			'security consulting',
			'security risk assessment',
			'integrated security solutions',
			'smart home security',
			'remote monitoring',
			'24/7 monitoring',
			'smoke detectors',
			'carbon monoxide detectors',
			'emergency lighting',
			'fire doors',
			'fire escape plans',
			'fire safety equipment',
			'security fencing',
			'security gates',
			'keyless entry',
			'video surveillance',
			'security audits',
			'security maintenance',
			'security upgrades',
			'fire and security inspections',
			'fire and security compliance',
		],
	},
	RmlzaGluZw: {
		alternativeIndustries: [
			'SHVudGluZw',
			'UGVzdCBDb250cm9s',
			'SG9iYmllcyAmIExlaXN1cmU',
			'VHJhdmVs',
			'SGlraW5nICYgQ2FtcGluZw',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'fishing gear',
			'fishing rods',
			'fishing reels',
			'fishing lures',
			'fishing tackle',
			'fishing accessories',
			'fishing line',
			'fishing hooks',
			'fishing bait',
			'fishing nets',
			'fishing boats',
			'fishing kayaks',
			'fishing apparel',
			'fishing waders',
			'fishing boots',
			'fishing hats',
			'fishing sunglasses',
			'fishing electronics',
			'fish finders',
			'fishing GPS',
			'fishing trips',
			'fishing guides',
			'fishing charters',
			'fishing destinations',
			'fishing techniques',
			'fishing tips',
			'fishing knots',
			'fishing regulations',
			'fishing licenses',
			'fishing tournaments',
			'fishing camping',
			'ice fishing',
			'fly fishing',
			'bass fishing',
			'trout fishing',
			'saltwater fishing',
			'freshwater fishing',
		],
	},
	'RmxvcmlzdA==': {
		alternativeIndustries: [
			'QXJ0cyAmIEVudGVydGFpbm1lbnQ',
			'Q29ycG9yYXRlIEV2ZW50cw',
			'RXZlbnRz',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QmVhdXR5ICYgRml0bmVzcw',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'lorist',
			'flower delivery',
			'flower arrangements',
			'bouquets',
			'wedding flowers',
			'sympathy flowers',
			'online florist',
			'flower shop',
			'same-day delivery',
			'fresh flowers',
			'floral gifts',
			'anniversary flowers',
			'birthday flowers',
			'roses',
			'lilies',
			'tulips',
			'orchids',
			'carnations',
			'daisies',
			'sunflowers',
			'flower subscription',
			"Mother's Day flowers",
			"Valentine's Day flowers",
			'Christmas flowers',
			'Thanksgiving centerpieces',
			'get well soon flowers',
			'new baby flowers',
			'funeral flowers',
			'flower delivery near me',
			'local florist',
			'international flower delivery',
			'flower care tips',
			'seasonal flowers',
			'flower meanings',
		],
	},
	Rm9vZCAmIERyaW5r: {
		alternativeIndustries: [
			'UmVzdGF1cmFudHM',
			'Q29uY2llcmdlIHNlcnZpY2U',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'food and beverage',
			'food products',
			'beverage products',
			'snacks',
			'drinks',
			'groceries',
			'gourmet food',
			'specialty food',
			'organic food',
			'natural food',
			'healthy snacks',
			'energy drinks',
			'soft drinks',
			'juices',
			'tea',
			'coffee',
			'bottled water',
			'beer',
			'wine',
			'spirits',
			'food delivery',
			'online grocery',
			'food subscription',
			'meal kits',
			'food gifts',
			'food packaging',
			'food ingredients',
			'food processing',
			'food safety',
			'food trends',
			'food industry',
			'food service',
			'food manufacturing',
			'food distribution',
			'food retail',
		],
	},
	RnVybml0dXJl: {
		alternativeIndustries: [
			'THV4dXJ5IEdvb2Rz',
			'SG9tZSAmIEdhcmRlbg',
			'SG9tZSBJbXByb3ZlbWVudA',
			'UmVhbCBFc3RhdGU',
			'SG9iYmllcyAmIExlaXN1cmU',
		],
		keywords: [
			'furniture',
			'home furniture',
			'living room furniture',
			'bedroom furniture',
			'dining room furniture',
			'office furniture',
			'outdoor furniture',
			'sofas',
			'couches',
			'sectionals',
			'recliners',
			'chairs',
			'tables',
			'desks',
			'beds',
			'mattresses',
			'dressers',
			'cabinets',
			'bookcases',
			'entertainment centers',
			'tv stands',
			'patio furniture',
			'garden furniture',
			'furniture sets',
			'furniture collections',
			'modern furniture',
			'contemporary furniture',
			'traditional furniture',
			'rustic furniture',
			'wooden furniture',
			'metal furniture',
			'upholstered furniture',
			'leather furniture',
			'fabric furniture',
			'custom furniture',
			'furniture design',
			'furniture brands',
			'furniture stores',
			'online furniture',
			'furniture deals',
			'furniture sales',
			'furniture discounts',
			'furniture delivery',
			'furniture assembly',
			'furniture care',
			'furniture maintenance',
		],
	},
	R2FtZXM: {
		alternativeIndustries: [
			'SG9iYmllcyAmIExlaXN1cmU',
			'U29mdHdhcmU',
			'U2FhUw==',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'video games',
			'pc games',
			'console games',
			'online games',
			'multiplayer games',
			'gaming accessories',
			'game controllers',
			'gaming headsets',
			'gaming keyboards',
			'gaming mice',
			'game consoles',
			'PlayStation',
			'Xbox',
			'Nintendo',
			'gaming chairs',
			'game downloads',
			'game reviews',
			'new releases',
			'pre-orders',
			'game trailers',
			'gaming news',
			'esports',
			'gaming tournaments',
			'gaming gift cards',
			'game bundles',
			"collector's editions",
			'retro games',
			'arcade games',
			'gaming laptops',
			'gaming desktops',
			'VR games',
			'game guides',
			'walkthroughs',
			'cheat codes',
			'game mods',
			'game skins',
		],
	},
	SGFpciBDYXJl: {
		alternativeIndustries: [
			'SGFpciBEcmVzc2Vy',
			'QmVhdXR5ICYgRml0bmVzcw',
			'THV4dXJ5IEdvb2Rz',
			'SG9iYmllcyAmIExlaXN1cmU',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'hair care',
			'hair products',
			'shampoo',
			'conditioner',
			'hair treatment',
			'hair mask',
			'hair oil',
			'hair serum',
			'hair spray',
			'hair gel',
			'hair mousse',
			'hair cream',
			'hair tonic',
			'hair vitamins',
			'hair supplements',
			'hair growth',
			'hair loss',
			'hair damage',
			'hair repair',
			'hair color',
			'hair dye',
			'hair styling',
			'hair tools',
			'hair accessories',
			'hair brush',
			'hair comb',
			'hair dryer',
			'hair straightener',
			'hair curler',
			'natural hair care',
			'organic hair care',
			'vegan hair care',
			'sulfate-free',
			'paraben-free',
			'hair type',
			'hair texture',
			'hair porosity',
			'hair density',
			'scalp care',
			'dandruff',
			'dry scalp',
			'oily scalp',
			'itchy scalp',
			'sensitive scalp',
			"men's hair care",
			"women's hair care",
			"kids' hair care",
		],
	},
	SGFpciBEcmVzc2Vy: {
		alternativeIndustries: [
			'SGFpciBDYXJl',
			'QmVhdXR5ICYgRml0bmVzcw',
			'SG9iYmllcyAmIExlaXN1cmU',
			'V2VkZGluZ3M=',
		],
		keywords: [
			'hair salon',
			'hairdresser',
			'hair stylist',
			'haircut',
			'hair color',
			'hair styling',
			'hair extensions',
			'hair treatments',
			'hair care',
			'hair products',
			'hair salon near me',
			'hair salon services',
			'hair highlights',
			'hair balayage',
			'hair ombre',
			'hair keratin treatment',
			'hair perm',
			'hair relaxer',
			'hair blowout',
			'hair updo',
			'wedding hairstyles',
			"men's haircuts",
			"children's haircuts",
			'hair salon appointments',
			'hair salon reviews',
		],
	},
	SGVhbHRo: {
		alternativeIndustries: [
			'T3JhbCAmIERlbnRhbCBDYXJl',
			'RWR1Y2F0aW9u',
			'UGV0cyAmIEFuaW1hbHM',
		],
		keywords: [
			'health products',
			'health services',
			'wellness',
			'fitness',
			'nutrition',
			'supplements',
			'vitamins',
			'natural health',
			'alternative medicine',
			'holistic health',
			'mental health',
			'stress relief',
			'weight loss',
			'diet',
			'exercise',
			'healthy lifestyle',
			'preventive care',
			'medical supplies',
			'health equipment',
			'telemedicine',
			'online pharmacy',
			'health insurance',
			'disease prevention',
			'pain management',
			'skincare',
			'personal care',
			'beauty',
			'anti-aging',
			'health blog',
			'health tips',
			'health resources',
			'health education',
			'health coaching',
		],
	},
	SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n: {
		alternativeIndustries: [
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SG9tZSBCdWlsZGVy',
			'RWxlY3RyaWNpYW5z',
			'Um9vZmluZw==',
			'V2luZG93IFNlcnZpY2luZw==',
			'VHJlZSBjdXR0aW5n',
			'SG9tZSBCdWlsZGVy',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'UGVzdCBDb250cm9s',
		],
		keywords: [
			'Heating',
			'Ventilation',
			'and Air Conditioning services',
			'heating and cooling',
			'air conditioning repair',
			'furnace repair',
			'AC maintenance',
			'Heating',
			'Ventilation',
			'and Air Conditioning installation',
			'Heating',
			'Ventilation',
			'and Air Conditioning contractor',
			'air conditioning service',
			'heating service',
			'AC replacement',
			'furnace replacement',
			'indoor air quality',
			'air filtration',
			'ductwork',
			'thermostat',
			'energy efficient Heating',
			'Ventilation',
			'and Air Conditioning',
			'emergency Heating',
			'Ventilation',
			'and Air Conditioning',
			'local Heating',
			'Ventilation',
			'and Air Conditioning company',
			'licensed Heating',
			'Ventilation',
			'and Air Conditioning technician',
			'Heating',
			'Ventilation',
			'and Air Conditioning maintenance plan',
			'commercial Heating',
			'Ventilation',
			'and Air Conditioning',
		],
	},
	SGlraW5nICYgQ2FtcGluZw: {
		alternativeIndustries: [
			'RmlzaGluZw',
			'SHVudGluZw',
			'VHJhdmVs',
			'V2F0ZXIgQWN0aXZpdGllcw',
			'SG9iYmllcyAmIExlaXN1cmU',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'hiking gear',
			'camping equipment',
			'outdoor accessories',
			'backpacking supplies',
			'trekking poles',
			'hiking boots',
			'trail running shoes',
			'camping tents',
			'sleeping bags',
			'camping stoves',
			'camping cookware',
			'camping furniture',
			'hiking backpacks',
			'hydration packs',
			'water filters',
			'camping lanterns',
			'headlamps',
			'camping knives',
			'multi-tools',
			'camping food',
			'freeze-dried meals',
			'hiking snacks',
			'energy bars',
			'hiking clothing',
			'outdoor apparel',
			'hiking socks',
			'hiking pants',
			'hiking jackets',
			'camping hammocks',
			'camping pillows',
			'camping blankets',
			'camping coolers',
			'camping first aid kits',
			'hiking maps',
			'compasses',
			'GPS devices',
			'binoculars',
			'hiking watches',
			'sun protection',
			'insect repellent',
			'outdoor survival gear',
		],
	},
	SG9iYmllcyAmIExlaXN1cmU: {
		alternativeIndustries: [
			'RmlzaGluZw',
			'SHVudGluZw',
			'VHJhdmVs',
			'R2FtZXM',
			'VG95cw',
			'Qm9va3MgJiBMaXRlcmF0dXJl',
			'QW50aXF1ZXMgJiBDb2xsZWN0aWJsZXM',
			'SG9tZSAmIEdhcmRlbg',
			'THV4dXJ5IEdvb2Rz',
			'RWR1Y2F0aW9u',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'hobbies',
			'leisure activities',
			'crafts',
			'DIY',
			'art supplies',
			'hobby supplies',
			'games',
			'puzzles',
			'outdoor recreation',
			'sports equipment',
			'camping gear',
			'hiking',
			'fishing',
			'hunting',
			'gardening',
			'home brewing',
			'model building',
			'RC vehicles',
			'photography',
			'music instruments',
			'collectibles',
			'sewing',
			'knitting',
			'scrapbooking',
			'jewelry making',
			'woodworking',
			'painting',
			'drawing',
			'sculpting',
			'ceramics',
			'stationery',
			'party supplies',
			'travel accessories',
			'outdoor furniture',
			'grilling',
			'entertainment',
			'family fu',
		],
	},
	SG9tZSAmIEdhcmRlbg: {
		alternativeIndustries: [
			'TGFuZHNjYXBlcnM=',
			'VHJlZSBjdXR0aW5n',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'SG9iYmllcyAmIExlaXN1cmU',
			'SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw',
			'WWFyZCAmIFBhdGlv',
		],
		keywords: [
			'home decor',
			'garden supplies',
			'outdoor furniture',
			'indoor plants',
			'home improvement',
			'landscaping',
			'patio furniture',
			'gardening tools',
			'home renovation',
			'interior design',
			'lawn care',
			'garden decor',
			'home organization',
			'backyard living',
			'kitchen accessories',
			'bathroom accessories',
			'outdoor living',
			'home accents',
			'garden planters',
			'home maintenance',
		],
	},
	SG9tZSBCdWlsZGVy: {
		alternativeIndustries: [
			'UGFpbnRpbmc=',
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'V2luZG93IFNlcnZpY2luZw==',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
			'UGVzdCBDb250cm9s',
		],
		keywords: [
			'home builder',
			'custom home builder',
			'new home construction',
			'home construction company',
			'residential construction',
			'custom home design',
			'luxury home builder',
			'home building process',
			'home floor plans',
			'home design ideas',
			'energy efficient homes',
			'green home building',
			'home builder portfolio',
			'home construction financing',
			'home warranties',
			'home building materials',
			'home builder reviews',
			'local home builders',
			'home builder associations',
			'home building trends',
		],
	},
	SG9tZSBJbXByb3ZlbWVudA: {
		alternativeIndustries: [
			'UGFpbnRpbmc=',
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'V2luZG93IFNlcnZpY2luZw==',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
		],
		keywords: [
			'home improvement',
			'home renovation',
			'home remodeling',
			'home repair',
			'home maintenance',
			'home services',
			'home contractor',
			'home construction',
			'kitchen remodeling',
			'bathroom remodeling',
			'room additions',
			'home extensions',
			'flooring installation',
			'tile installation',
			'carpentry services',
			'plumbing services',
			'electrical services',
			'Heating',
			'Ventilation',
			'and Air Conditioning services',
			'roofing services',
			'window replacement',
			'door replacement',
			'siding installation',
			'deck building',
			'patio construction',
			'garage renovation',
			'basement finishing',
			'attic conversion',
			'home painting',
			'drywall services',
			'handyman services',
			'home energy efficiency',
			'green home improvements',
			'home accessibility modifications',
			'aging-in-place renovations',
			'home restoration',
			'historic home renovation',
			'home improvement financing',
			'home improvement loans',
			'home improvement grants',
			'DIY home improvement',
			'home improvement tips',
			'home improvement ideas',
			'home improvement projects',
			'home improvement tools',
			'home improvement supplies',
		],
	},
	VHJhdmVs: {
		alternativeIndustries: [
			'UmVhbCBFc3RhdGU',
			'SGlraW5nICYgQ2FtcGluZw',
			'SHVudGluZw',
			'RmlzaGluZw',
		],
		keywords: [
			'hospitality',
			'tourism',
			'travel',
			'hotels',
			'resorts',
			'accommodations',
			'vacation packages',
			'holiday deals',
			'flight bookings',
			'car rentals',
			'cruises',
			'destinations',
			'tourist attractions',
			'sightseeing',
			'tours',
			'activities',
			'adventure travel',
			'luxury travel',
			'budget travel',
			'family vacations',
			'romantic getaways',
			'business travel',
			'travel planning',
			'travel agency',
			'travel guides',
			'travel tips',
			'travel reviews',
			'travel insurance',
			'travel rewards',
			'loyalty programs',
			'online booking',
			'reservations',
			'customer service',
			'amenities',
			'dining',
			'entertainment',
			'events',
			'conferences',
			'meetings',
			'weddings',
			'honeymoons',
			'spa',
			'wellness',
			'eco-tourism',
			'sustainable tourism',
			'cultural tourism',
			'historical sites',
			'natural wonders',
			'beaches',
			'mountains',
			'cities',
			'international travel',
			'domestic travel',
			'group travel',
			'solo travel',
			'last-minute deals',
			'all-inclusive',
			'transportation',
			'visa services',
			'concierge',
		],
	},
	SHVudGluZw: {
		alternativeIndustries: [
			'UGVzdCBDb250cm9s',
			'RmlzaGluZw',
			'SG9iYmllcyAmIExlaXN1cmU',
			'VHJhdmVs',
			'SGlraW5nICYgQ2FtcGluZw',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'hunting gear',
			'hunting equipment',
			'hunting supplies',
			'hunting accessories',
			'hunting clothes',
			'hunting apparel',
			'hunting boots',
			'hunting optics',
			'hunting scopes',
			'hunting binoculars',
			'hunting rangefinders',
			'hunting knives',
			'hunting backpacks',
			'hunting blinds',
			'hunting treestands',
			'hunting decoys',
			'hunting calls',
			'hunting game calls',
			'hunting ammunition',
			'hunting firearms',
			'hunting bows',
			'hunting crossbows',
			'hunting arrows',
			'hunting safety',
			'hunting licenses',
			'hunting regulations',
			'hunting seasons',
			'hunting trips',
			'hunting guides',
			'hunting lodges',
			'hunting dogs',
			'hunting GPS',
			'hunting cameras',
			'hunting gifts',
			'hunting products',
			'hunting store',
		],
	},
	SW50ZXJuZXQgJiBUZWxlY29t: {
		alternativeIndustries: [
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'T2ZmaWNlIFN1cHBsaWVz',
			'U29sYXIgcGFuZWw=',
			'RWxlY3RyaWNpYW5z',
			'U2FhUw==',
		],
		keywords: [
			'internet service provider',
			'ISP',
			'broadband internet',
			'high-speed internet',
			'fiber optic internet',
			'cable internet',
			'DSL internet',
			'wireless internet',
			'internet plans',
			'internet packages',
			'home internet',
			'business internet',
			'internet speeds',
			'internet reliability',
			'internet coverage',
			'internet availability',
			'internet connection',
			'internet setup',
			'internet installation',
			'internet support',
			'internet customer service',
			'telecommunications',
			'telecom services',
			'telecom solutions',
			'telecom technology',
			'telecom infrastructure',
			'telecom networks',
			'telecom equipment',
			'telecom consulting',
			'telecom management',
			'VoIP',
			'voice over internet protocol',
			'cloud communications',
			'unified communications',
			'network security',
			'data connectivity',
			'mobile connectivity',
			'internet of things',
			'IoT connectivity',
		],
	},
	'SmV3ZWxyeQ==': {
		alternativeIndustries: [
			'QmVhdXR5ICYgRml0bmVzcw',
			'QXBwYXJlbA',
			'THV4dXJ5IEdvb2Rz',
		],
		keywords: [
			'jewelry',
			'necklaces',
			'bracelets',
			'earrings',
			'rings',
			'pendants',
			'charms',
			'gold jewelry',
			'silver jewelry',
			'diamond jewelry',
			'gemstone jewelry',
			'fashion jewelry',
			'fine jewelry',
			'handmade jewelry',
			'custom jewelry',
			'jewelry sets',
			'wedding jewelry',
			'engagement rings',
			'jewelry gifts',
			'jewelry for women',
			'jewelry for men',
			'jewelry sales',
			'jewelry deals',
			'jewelry designers',
			'jewelry trends',
			'vintage jewelry',
			'antique jewelry',
			'jewelry care',
			'jewelry cleaning',
			'jewelry repair',
			'jewelry storage',
			'jewelry boxes',
			'jewelry making',
			'jewelry supplies',
			'jewelry wholesale',
			'jewelry store',
			'online jewelry shop',
		],
	},
	Sm9icw: {
		alternativeIndustries: [
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'Q29uc3VsdGluZw',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'QWR2ZXJ0aXNpbmcgJiBNYXJrZXRpbmc',
			'RWR1Y2F0aW9u',
		],
		keywords: [
			'job openings',
			'career opportunities',
			'job listings',
			'job search',
			'job vacancies',
			'employment opportunities',
			'job postings',
			'job ads',
			'job board',
			'job market',
			'job seekers',
			'job hunting',
			'job applications',
			'job interviews',
			'job requirements',
			'job qualifications',
			'job descriptions',
			'job titles',
			'job categories',
			'job locations',
			'job salaries',
			'job benefits',
			'job perks',
			'job flexibility',
			'job security',
			'job satisfaction',
			'job growth',
			'job trends',
			'job skills',
			'job experience',
			'job training',
			'job education',
			'job certifications',
			'job references',
			'job networking',
			'job fairs',
			'job agencies',
			'job recruiters',
			'job placement',
			'job orientation',
			'job onboarding',
			'job retention',
			'job advancement',
			'job promotions',
			'job transitions',
			'job changes',
			'job layoffs',
			'job terminations',
			'job resignations',
			'job references',
			'job recommendations',
			'job reviews',
			'job ratings',
			'job feedback',
			'job support',
			'job assistance',
			'job resources',
			'job tools',
			'job templates',
			'job examples',
			'job samples',
			'job guides',
			'job tips',
			'job advice',
			'job best practices',
			'job success stories',
			'job case studies',
			'job testimonials',
			'job quotes',
			'job inspiration',
			'job motivation',
			'job encouragement',
			'job empowerment',
			'job fulfillment',
			'job happiness',
			'job passion',
			'job purpose',
			'job mission',
			'job vision',
			'job values',
			'job culture',
			'job diversity',
			'job inclusion',
			'job equity',
			'job equality',
			'job fairness',
			'job justice',
			'job ethics',
			'job compliance',
			'job regulations',
			'job laws',
			'job policies',
			'job procedures',
			'job standards',
			'job quality',
			'job excellence',
			'job innovation',
			'job creativity',
			'job problem-solving',
			'job decision-making',
			'job leadership',
			'job management',
			'job collaboration',
			'job teamwork',
			'job communication',
			'job customer service',
			'job sales',
			'job marketing',
			'job branding',
			'job advertising',
			'job public relations',
			'job social media',
			'job online presence',
			'job reputation',
			'job image',
			'job identity',
			'job personality',
			'job charisma',
			'job influence',
			'job impact',
			'job significance',
			'job importance',
			'job relevance',
			'job timeliness',
			'job urgency',
			'job priority',
			'job focus',
			'job attention',
			'job concentration',
			'job dedication',
			'job commitment',
			'job loyalty',
			'job reliability',
			'job dependability',
			'job consistency',
			'job stability',
			'job endurance',
			'job perseverance',
			'job resilience',
			'job adaptability',
			'job flexibility',
			'job versatility',
			'job agility',
			'job speed',
			'job efficiency',
			'job productivity',
			'job performance',
			'job results',
			'job outcomes',
			'job achievements',
			'job successes',
			'job wins',
			'job victories',
			'job triumphs',
			'job celebrations',
			'job rewards',
			'job recognition',
			'job appreciation',
			'job gratitude',
			'job satisfaction',
			'job fulfillment',
			'job happiness',
			'job joy',
			'job excitement',
			'job enthusiasm',
			'job optimism',
			'job hope',
			'job faith',
			'job belief',
			'job confidence',
			'job courage',
			'job bravery',
			'job boldness',
			'job assertiveness',
			'job proactivity',
			'job initiative',
			'job self-motivation',
			'job self-discipline',
			'job self-control',
			'job self-awareness',
			'job self-reflection',
			'job self-improvement',
			'job self-development',
			'job self-growth',
			'job self-realization',
			'job self-actualization',
			'job self-transcendence',
			'job self-mastery',
			'job self-excellence',
			'job self-perfection',
			'job self-fulfillment',
			'job self-satisfaction',
			'job self-happiness',
			'job self-joy',
			'job self-love',
			'job self-respect',
			'job self-esteem',
			'job self-worth',
			'job self-value',
			'job self-confidence',
			'job self-belief',
			'job self-efficacy',
			'job self-reliance',
			'job self-sufficiency',
			'job self-determination',
			'job self-direction',
			'job self-leadership',
			'job self-empowerment',
			'job self-transformation',
			'job self-reinvention',
			'job self-discovery',
			'job self-expression',
			'job self-realization',
		],
	},
	'TGFuZHNjYXBlcnM=': {
		alternativeIndustries: [
			'QWdyaWN1bHR1cmUgJiBGb3Jlc3RyeQ',
			'SG9tZSAmIEdhcmRlbg',
			'RmxvcmlzdA==',
			'SG9iYmllcyAmIExlaXN1cmU',
			'WWFyZCAmIFBhdGlv',
		],
		keywords: [
			'landscaping services',
			'landscape design',
			'landscape maintenance',
			'lawn care',
			'lawn mowing',
			'tree trimming',
			'tree removal',
			'shrub pruning',
			'mulching',
			'garden design',
			'hardscaping',
			'patio installation',
			'retaining walls',
			'outdoor living spaces',
			'irrigation systems',
			'sod installation',
			'landscape lighting',
			'water features',
			'xeriscaping',
			'commercial landscaping',
			'residential landscaping',
		],
	},
	TGF3ICYgR292ZXJubWVudA: {
		alternativeIndustries: [
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'Q29uc3VsdGluZw',
			'Sm9icw',
			'RmluYW5jZQ',
			'RWR1Y2F0aW9u',
			'QWR2ZXJ0aXNpbmcgJiBNYXJrZXRpbmc',
		],
		keywords: [
			'personal injury lawyer',
			'family law attorney',
			'criminal defense lawyer',
			'corporate lawyer',
			'real estate attorney',
			'employment lawyer',
			'immigration attorney',
			'estate planning lawyer',
			'car accident lawyer',
			'divorce attorney consultation',
			'workers compensation lawyer',
			'medical malpractice attorney',
			'wrongful death lawyer',
			'child custody attorney',
			'how to file for divorce',
			'what to do after car accident',
			'need lawyer for business contract',
			'help with immigration papers',
			'legal advice for startup',
			'file insurance claim lawyer',
			'top-rated attorney',
			'experienced lawyer',
			'affordable lawyer',
			'legal services',
			'corporate legal services',
			'family attorney services',
			'immigrant legal assistance',
			'startup legal counsel',
			'trusted law firm',
			'successful lawyer track record',
			'legal expertise',
			'professional services',
			'divorce',
			'civil litigation',
			'immigration law',
		],
	},
	WWFyZCAmIFBhdGlv: {
		alternativeIndustries: [
			'TGFuZHNjYXBlcnM=',
			'SG9tZSAmIEdhcmRlbg',
			'RmxvcmlzdA==',
			'Q2xlYW5pbmcgU2VydmljZXM',
		],
		keywords: [
			'lawn care',
			'lawn maintenance',
			'landscaping',
			'yard work',
			'grass cutting',
			'lawn mowing',
			'yard maintenance',
			'garden maintenance',
			'weed control',
			'fertilizing',
			'aeration',
			'overseeding',
			'lawn treatment',
			'lawn care services',
			'lawn care near me',
			'lawn care company',
			'professional lawn care',
			'residential lawn care',
			'commercial lawn care',
			'lawn care tips',
			'green lawn',
			'healthy lawn',
		],
	},
	THV4dXJ5IEdvb2Rz: {
		alternativeIndustries: [
			'RnVybml0dXJl',
			'SmV3ZWxyeQ==',
			'QmVhdXR5ICYgRml0bmVzcw',
		],
		keywords: [
			'luxury goods',
			'high-end products',
			'premium items',
			'designer brands',
			'luxury fashion',
			'luxury accessories',
			'luxury watches',
			'luxury jewelry',
			'luxury handbags',
			'luxury shoes',
			'luxury cosmetics',
			'luxury skincare',
			'luxury fragrances',
			'luxury home decor',
			'luxury furniture',
			'luxury cars',
			'luxury travel',
			'luxury experiences',
			'luxury gifts',
			'exclusive products',
			'limited edition items',
			'handcrafted goods',
			'bespoke products',
			'luxury lifestyle',
			'luxury shopping',
			'luxury boutique',
			'luxury e-commerce',
		],
	},
	QWR2ZXJ0aXNpbmcgJiBNYXJrZXRpbmc: {
		alternativeIndustries: [
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'Q29uc3VsdGluZw',
			'Q29ycG9yYXRlIEV2ZW50cw',
			'U2FhUw==',
			'U29mdHdhcmU',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QXJ0cyAmIEVudGVydGFpbm1lbnQ',
			'R2FtZXM',
			'T25saW5lIENvbW11bml0aWVz',
			'RWR1Y2F0aW9u',
		],
		keywords: [
			'marketing services',
			'digital marketing',
			'online marketing',
			'marketing agency',
			'marketing strategies',
			'marketing solutions',
			'marketing campaigns',
			'marketing consulting',
			'marketing plan',
			'marketing ideas',
			'marketing tips',
			'marketing tools',
			'marketing analytics',
			'marketing automation',
			'content marketing',
			'social media marketing',
			'email marketing',
			'search engine marketing',
			'pay-per-click advertising',
			'PPC management',
			'SEO services',
			'branding services',
			'brand strategy',
			'logo design',
			'graphic design',
			'web design',
			'copywriting',
			'video marketing',
			'mobile marketing',
			'influencer marketing',
			'affiliate marketing',
			'marketing ROI',
			'marketing effectiveness',
			'marketing trends',
			'marketing best practices',
		],
	},
	TmV3cw: {
		alternativeIndustries: ['T25saW5lIENvbW11bml0aWVz'],
		keywords: [
			'breaking news',
			'latest news',
			'current events',
			'world news',
			'local news',
			'national news',
			'top stories',
			'headlines',
			'news updates',
			'politics',
			'business news',
			'financial news',
			'sports news',
			'entertainment news',
			'celebrity news',
			'tech news',
			'science news',
			'health news',
			'weather news',
			'crime news',
			'international news',
			'news analysis',
			'news opinion',
			'news videos',
			'news photos',
			'news blog',
			'news podcast',
			'news live stream',
			'news alerts',
			'news app',
			'news subscription',
			'news website',
		],
	},
	T2ZmaWNlIFN1cHBsaWVz: {
		alternativeIndustries: [
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'U2FhUw==',
		],
		keywords: [
			'office supplies',
			'office products',
			'office stationery',
			'office equipment',
			'office furniture',
			'office accessories',
			'office essentials',
			'office necessities',
			'office basics',
			'office must-haves',
			'office organization',
			'office storage',
			'office decor',
			'office chairs',
			'office desks',
			'office filing cabinets',
			'office binders',
			'office folders',
			'office paper',
			'office pens',
			'office pencils',
			'office markers',
			'office staplers',
			'office tape',
			'office scissors',
			'office calculators',
			'office printers',
			'office ink',
			'office toner',
			'bulk office supplies',
			'discount office supplies',
			'cheap office supplies',
			'office supply deals',
			'office supply sales',
			'office supply coupons',
			'office supply delivery',
			'office supply store',
		],
	},
	T25saW5lIENvbW11bml0aWVz: {
		alternativeIndustries: ['RWR1Y2F0aW9u', 'SG9iYmllcyAmIExlaXN1cmU'],
		keywords: [
			'online community',
			'virtual community',
			'social network',
			'online forum',
			'discussion board',
			'online group',
			'online chat',
			'online interaction',
			'online engagement',
			'online participation',
			'user-generated content',
			'online collaboration',
			'online support',
			'online relationships',
			'online connections',
			'online networking',
			'online discussion',
			'online conversation',
			'online sharing',
			'online communication',
			'online platform',
			'online space',
			'online gathering',
			'online meetup',
			'online tribe',
		],
	},
	T3JhbCAmIERlbnRhbCBDYXJl: {
		alternativeIndustries: ['SGVhbHRo', 'RWR1Y2F0aW9u', 'UGV0cyAmIEFuaW1hbHM'],
		keywords: [
			'dentist',
			'dental clinic',
			'oral care',
			'dental care',
			'teeth cleaning',
			'dental hygiene',
			'tooth decay',
			'cavity prevention',
			'gum disease',
			'periodontal treatment',
			'dental implants',
			'teeth whitening',
			'cosmetic dentistry',
			'veneers',
			'dental crowns',
			'root canal',
			'oral surgery',
			'wisdom teeth removal',
			'pediatric dentistry',
			'emergency dental care',
			'dental x-rays',
			'orthodontics',
			'braces',
			'clear aligners',
			'dental insurance',
			'dental plans',
			'dental financing',
			'sedation dentistry',
			'dental anxiety',
			'oral health',
			'dental checkup',
			'dental fillings',
			'dentures',
			'dental bridge',
			'smile makeover',
			'dental technology',
			'experienced dentist',
			'family dentistry',
			'dental education',
			'preventive dentistry',
		],
	},
	'T3RoZXI=': { alternativeIndustries: [], keywords: [''] },
	'UGFpbnRpbmc=': {
		alternativeIndustries: [
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'V2luZG93IFNlcnZpY2luZw==',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
		],
		keywords: [
			'painting services',
			'house painting',
			'interior painting',
			'exterior painting',
			'residential painting',
			'commercial painting',
			'paint colors',
			'painting contractors',
			'painting company',
			'room painting',
			'wall painting',
			'ceiling painting',
			'paint finishes',
			'painting estimates',
			'painting costs',
			'painting techniques',
			'paint preparation',
			'paint removal',
		],
	},
	UGVzdCBDb250cm9s: {
		alternativeIndustries: [
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'UGx1bWJlcnM=',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
		],
		keywords: [
			'pest control',
			'pest control services',
			'exterminator',
			'pest exterminator',
			'pest control company',
			'pest control near me',
			'pest removal',
			'insect control',
			'rodent control',
			'termite control',
			'bed bug exterminator',
			'ant control',
			'cockroach control',
			'mosquito control',
			'wasp removal',
			'spider control',
			'mice exterminator',
			'rat exterminator',
			'wildlife control',
			'pest inspection',
			'pest treatment',
			'fumigation',
			'pest prevention',
			'integrated pest management',
			'eco-friendly pest control',
			'organic pest control',
		],
	},
	UGV0cyAmIEFuaW1hbHM: {
		alternativeIndustries: ['SGVhbHRo', 'T3JhbCAmIERlbnRhbCBDYXJl'],
		keywords: [
			'pet supplies',
			'pet food',
			'dog food',
			'cat food',
			'pet toys',
			'pet accessories',
			'pet grooming',
			'pet care',
			'pet health',
			'veterinary care',
			'pet adoption',
			'pet rescue',
			'pet training',
			'dog training',
			'cat litter',
			'pet beds',
			'pet carriers',
			'pet insurance',
			'pet-friendly',
			'pet store',
			'pet shop',
			'pet products',
			'pet services',
			'pet sitting',
			'dog walking',
			'pet boarding',
			'pet daycare',
			'pet grooming services',
			'pet vaccinations',
			'pet medications',
			'pet supplements',
			'pet dental care',
			'pet first aid',
			'pet travel',
			'pet-friendly hotels',
			'pet-friendly restaurants',
			'pet-friendly destinations',
			'pet events',
			'pet shows',
			'pet contests',
			'pet photography',
			'pet portraits',
			'pet memorials',
			'pet cremation',
			'pet urns',
			'pet cemetery',
			'pet loss',
			'pet bereavement',
			'pet support groups',
		],
	},
	'UGx1bWJlcnM=': {
		alternativeIndustries: [
			'Q2FycGVudHJ5',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'RWxlY3RyaWNpYW5z',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'SG9tZSAmIEdhcmRlbg',
			'SG9tZSBCdWlsZGVy',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SHVudGluZw',
			'TGFuZHNjYXBlcnM=',
			'UGVzdCBDb250cm9s',
			'SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'VHJlZSBjdXR0aW5n',
			'V2luZG93IFNlcnZpY2luZw==',
		],
		keywords: [
			'plumbing services',
			'plumber',
			'plumbing company',
			'plumbing repair',
			'emergency plumbing',
			'plumbing maintenance',
			'drain cleaning',
			'leak detection',
			'water heater repair',
			'water heater installation',
			'burst pipe repair',
			'sewer line repair',
			'clogged toilet',
			'bathroom plumbing',
			'kitchen plumbing',
			'commercial plumbing',
			'residential plumbing',
			'plumbing fixtures',
			'plumbing tools',
			'plumbing supplies',
			'24/7 plumbing',
			'licensed plumber',
			'experienced plumber',
			'affordable plumbing',
			'plumbing inspection',
			'plumbing quote',
			'plumbing estimate',
			'plumbing contractor',
			'plumbing installation',
			'plumbing replacement',
			'plumbing upgrade',
			'plumbing system',
			'plumbing solutions',
			'plumbing tips',
			'plumbing advice',
			'plumbing blog',
			'plumbing FAQ',
			'plumbing near me',
			'local plumber',
			'reliable plumber',
			'professional plumber',
			'certified plumber',
			'master plumber',
		],
	},
	SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw: {
		alternativeIndustries: [
			'Q2FyIGRldGFpbGluZw==',
			'Q2FyIHdhc2g=',
			'SG9tZSBTd2ltbWluZyBQb29scywgU2F1bmFzICYgU3Bhcw',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'V2F0ZXIgQWN0aXZpdGllcw',
			'WWFyZCAmIFBhdGlv',
			'TGFuZHNjYXBlcnM=',
		],
		keywords: [
			'pool cleaning',
			'pool maintenance',
			'swimming pool cleaning',
			'pool cleaning service',
			'pool cleaning company',
			'professional pool cleaning',
			'residential pool cleaning',
			'commercial pool cleaning',
			'pool cleaning prices',
			'pool cleaning near me',
			'pool cleaning schedule',
			'pool cleaning tips',
			'pool cleaning equipment',
			'pool cleaning chemicals',
			'pool cleaning supplies',
			'pool cleaning cost',
			'pool cleaning frequency',
			'pool cleaning contract',
			'pool cleaning and maintenance',
			'pool cleaning and repair',
		],
	},
	UmVhbCBFc3RhdGU: {
		alternativeIndustries: [
			'VHJhdmVs',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SG9tZSAmIEdhcmRlbg',
			'SG9tZSBCdWlsZGVy',
			'TGFuZHNjYXBlcnM=',
			'QXJjaGl0ZWN0dXJl',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
		],
		keywords: [
			'real estate',
			'homes for sale',
			'houses for sale',
			'property listings',
			'MLS listings',
			'real estate agent',
			'realtor',
			'real estate broker',
			'home buying',
			'home selling',
			'mortgage rates',
			'home loans',
			'property search',
			'luxury homes',
			'condos for sale',
			'apartments for sale',
			'foreclosures',
			'open houses',
			'virtual tours',
			'home values',
			'real estate market',
			'housing market',
			'real estate investing',
			'real estate trends',
			'first-time homebuyer',
			'down payment assistance',
			'home inspection',
			'home appraisal',
			'real estate attorney',
			'title insurance',
			'escrow',
			'closing costs',
		],
	},
	UmVzdGF1cmFudHM: {
		alternativeIndustries: ['Rm9vZCAmIERyaW5r', 'VHJhdmVs'],
		keywords: [
			'restaurant',
			'dining',
			'food',
			'cuisine',
			'menu',
			'dishes',
			'recipes',
			'chef',
			'ingredients',
			'local',
			'fresh',
			'healthy',
			'organic',
			'gluten-free',
			'vegan',
			'vegetarian',
			'cocktails',
			'wine',
			'beer',
			'happy hour',
			'brunch',
			'lunch',
			'dinner',
			'catering',
			'private events',
			'takeout',
			'delivery',
			'reservations',
			'ambiance',
			'service',
			'location',
			'hours',
			'contact',
			'reviews',
			'food photos',
			'food videos',
			'food blog',
		],
	},
	'Um9vZmluZw==': {
		alternativeIndustries: [
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SG9tZSBCdWlsZGVy',
			'V2luZG93IFNlcnZpY2luZw==',
			'VHJlZSBjdXR0aW5n',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
		],
		keywords: [
			'roofing services',
			'roof repair',
			'roof replacement',
			'roofing contractor',
			'roofing company',
			'roof installation',
			'roof maintenance',
			'roof leak repair',
			'residential roofing',
			'commercial roofing',
			'shingle roofing',
			'metal roofing',
			'flat roofing',
			'roof inspection',
			'emergency roof repair',
			'roof damage',
			'roofing materials',
			'roofing estimate',
			'local roofer',
			'professional roofer',
			'licensed roofer',
			'experienced roofer',
			'affordable roofing',
			'quality roofing',
			'roofing warranty',
			'roof ventilation',
			'roof insulation',
			'green roofing',
			'energy-efficient roofing',
			'roof financing',
			'roofing solutions',
			'roofing specialists',
			'roofing experts',
		],
	},
	'U2FhUw==': {
		alternativeIndustries: [
			'U29mdHdhcmU',
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'T2ZmaWNlIFN1cHBsaWVz',
			'Q29uc3VsdGluZw',
			'RmluYW5jZQ',
		],
		keywords: [
			'SaaS',
			'software as a service',
			'cloud software',
			'cloud-based software',
			'web-based software',
			'on-demand software',
			'subscription software',
			'business software',
			'enterprise software',
			'software solution',
			'software platform',
			'software application',
			'online software',
			'software for business',
		],
	},
	U29mdHdhcmU: {
		alternativeIndustries: [
			'U2FhUw==',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'Q29uc3VtZXIgRWxlY3Ryb25pY3M',
			'SG9iYmllcyAmIExlaXN1cmU',
			'QWVyb3NwYWNlICYgRGVmZW5zZQ',
			'T2ZmaWNlIFN1cHBsaWVz',
			'Q29uc3VsdGluZw',
			'RmluYW5jZQ',
		],
		keywords: [
			'software',
			'software solutions',
			'software programs',
			'software tools',
			'software applications',
			'software development',
			'software engineering',
			'software design',
			'software architecture',
			'software testing',
			'software quality assurance',
			'software maintenance',
			'software support',
			'software consulting',
			'software implementation',
			'software integration',
			'software customization',
			'software licensing',
			'software as a service (SaaS)',
			'cloud software',
			'enterprise software',
			'business software',
			'productivity software',
			'collaboration software',
			'project management software',
			'customer relationship management (CRM) software',
			'enterprise resource planning (ERP) software',
			'accounting software',
			'human resources (HR) software',
			'marketing automation software',
			'business intelligence software',
			'data analytics software',
			'artificial intelligence (AI) software',
			'machine learning software',
		],
	},
	'U29sYXIgcGFuZWw=': {
		alternativeIndustries: [
			'Q29tcHV0ZXJzICYgRWxlY3Ryb25pY3M',
			'RWxlY3RyaWNpYW5z',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'SGVhdGluZywgVmVudGlsYXRpb24sIGFuZCBBaXIgQ29uZGl0aW9uaW5n',
			'SG9tZSBCdWlsZGVy',
			'SG9tZSBJbXByb3ZlbWVudA',
			'SW50ZXJuZXQgJiBUZWxlY29t',
			'Um9vZmluZw==',
			'U29mdHdhcmU',
			'V2luZG93IFNlcnZpY2luZw==',
		],
		keywords: [
			'solar panels',
			'solar energy',
			'solar power',
			'solar installation',
			'solar panel installation',
			'residential solar',
			'commercial solar',
			'solar panel cost',
			'solar panel efficiency',
			'solar panel maintenance',
			'solar panel financing',
			'solar panel warranty',
			'solar panel brands',
			'solar panel reviews',
			'solar panel incentives',
			'solar panel rebates',
			'solar panel tax credits',
			'solar panel savings',
			'solar panel ROI',
			'solar panel technology',
			'solar panel systems',
			'solar panel kits',
			'solar panel mounting',
			'solar panel inverters',
			'solar panel batteries',
			'off-grid solar',
			'grid-tied solar',
			'hybrid solar',
			'solar panel quotes',
			'solar panel contractors',
			'solar panel companies',
			'solar panel suppliers',
			'solar panel manufacturers',
			'renewable energy',
			'green energy',
			'sustainable energy',
			'energy independence',
			'energy savings',
			'clean energy',
			'solar energy benefits',
			'solar energy myths',
			'solar energy facts',
			'solar energy trends',
			'solar energy future',
			'solar energy jobs',
			'solar energy education',
			'solar energy news',
			'solar energy blog',
			'solar energy case studies',
			'solar energy testimonials',
			'solar energy FAQs',
			'solar energy glossary',
			'solar energy calculator',
			'solar energy assessment',
			'solar energy consultation',
		],
	},
	VG95cw: {
		alternativeIndustries: [
			'RmFtaWx5',
			'UGV0cyAmIEFuaW1hbHM',
			'V2F0ZXIgQWN0aXZpdGllcw',
		],
		keywords: [
			'toys',
			"children's toys",
			'kids toys',
			'educational toys',
			'learning toys',
			'wooden toys',
			'stuffed animals',
			'board games',
			'puzzles',
			'outdoor toys',
			'ride-on toys',
			'building blocks',
			'toy cars',
			'action figures',
			'dolls',
			'play sets',
			'pretend play',
			'toy kitchen',
			'toy tools',
			'toy musical instruments',
			'baby toys',
			'toddler toys',
			'preschool toys',
			'toy storage',
			'toy organizers',
			'toy boxes',
			'toy chests',
			'toy brands',
			'popular toys',
			'bestselling toys',
			'toy deals',
			'toy discounts',
			'toy coupons',
			'toy reviews',
			'toy safety',
			'age-appropriate toys',
			'gender-neutral toys',
			'eco-friendly toys',
			'sustainable toys',
			'toy trends',
			'holiday toys',
			'birthday gifts',
			'toy gift guides',
		],
	},
	VHJlZSBjdXR0aW5n: {
		alternativeIndustries: [
			'VHJlZSBjdXR0aW5n',
			'TGFuZHNjYXBlcnM=',
			'QWdyaWN1bHR1cmUgJiBGb3Jlc3RyeQ',
			'SG9tZSAmIEdhcmRlbg',
			'WWFyZCAmIFBhdGlv',
		],
		keywords: [
			'tree cutting services',
			'tree removal',
			'tree trimming',
			'tree pruning',
			'stump grinding',
			'tree felling',
			'arborist services',
			'tree care',
			'tree maintenance',
			'tree surgery',
			'tree lopping',
			'tree shaping',
			'tree cabling',
			'tree bracing',
			'emergency tree removal',
			'tree risk assessment',
			'tree health',
			'tree disease treatment',
			'tree pest control',
			'tree fertilization',
			'tree planting',
			'tree transplanting',
			'tree mulching',
			'tree crown reduction',
			'tree crown thinning',
			'tree crown raising',
			'tree limb removal',
			'tree debris removal',
			'tree wood chipping',
			'tree root removal',
			'tree hazard assessment',
			'certified arborist',
			'professional tree services',
			'affordable tree services',
			'local tree services',
			'residential tree services',
			'commercial tree services',
		],
	},
	V2F0ZXIgQWN0aXZpdGllcw: {
		alternativeIndustries: ['VHJhdmVs', 'RXZlbnRz'],
		keywords: [
			'water sports',
			'water activities',
			'kayaking',
			'canoeing',
			'paddleboarding',
			'stand up paddleboarding',
			'SUP',
			'surfing',
			'windsurfing',
			'kiteboarding',
			'kitesurfing',
			'wakeboarding',
			'water skiing',
			'jet skiing',
			'snorkeling',
			'scuba diving',
			'freediving',
			'swimming',
			'boating',
			'boat rentals',
			'boat tours',
			'fishing',
			'fishing charters',
			'fishing gear',
			'water parks',
			'aqua parks',
			'inflatable water parks',
			'water trampolines',
			'water slides',
			'life jackets',
			'wetsuits',
			'drysuits',
			'rashguards',
			'water shoes',
			'waterproof bags',
			'waterproof cases',
			'waterproof cameras',
			'underwater cameras',
			'beach gear',
			'beach accessories',
			'lake activities',
			'river activities',
			'ocean activities',
			'water adventures',
			'extreme water sports',
			'family-friendly water activities',
			'water safety',
			'water sports equipment',
			'water sports rentals',
			'water sports lessons',
			'water sports instruction',
			'water sports schools',
			'water sports camps',
			'water sports events',
			'water sports competitions',
			'water sports videos',
			'water sports photos',
			'water sports blog',
		],
	},
	'V2VkZGluZ3M=': {
		alternativeIndustries: [
			'QmVhdXR5ICYgRml0bmVzcw',
			'QXBwYXJlbA',
			'RmxvcmlzdA==',
			'SmV3ZWxyeQ==',
			'SGFpciBEcmVzc2Vy',
			'VHJhdmVs',
			'RXZlbnRz',
		],
		keywords: [
			'wedding venues',
			'wedding dresses',
			'wedding photographers',
			'wedding invitations',
			'wedding cakes',
			'wedding flowers',
			'wedding decorations',
			'wedding favors',
			'wedding rings',
			'wedding planning',
			'bridal gowns',
			'bridal accessories',
			'bridal showers',
			'wedding receptions',
			'wedding catering',
			'wedding music',
			'wedding entertainment',
			'wedding transportation',
			'wedding rentals',
			'wedding officiants',
			'wedding videography',
			'destination weddings',
			'wedding registries',
			'wedding gift ideas',
			'wedding etiquette',
			'wedding trends',
			'wedding inspiration',
			'wedding ideas',
			'wedding budgeting',
			'wedding checklists',
			'wedding timelines',
		],
	},
	'V2luZG93IFNlcnZpY2luZw==': {
		alternativeIndustries: [
			'SG9tZSAmIEdhcmRlbg',
			'Um9vZmluZw==',
			'U29sYXIgcGFuZWw=',
			'Q2FycGVudHJ5',
			'Q2xlYW5pbmcgU2VydmljZXM',
			'Q29uc3RydWN0aW9uICYgTWFpbnRlbmFuY2U',
			'SG9tZSBCdWlsZGVy',
			'UGx1bWJlcnM=',
			'RmlyZSAmIFNlY3VyaXR5IFNlcnZpY2Vz',
			'TGFuZHNjYXBlcnM=',
			'RWxlY3RyaWNpYW5z',
		],
		keywords: [
			'window servicing',
			'window repair',
			'window maintenance',
			'window cleaning',
			'window replacement',
			'window installation',
			'window restoration',
			'window caulking',
			'window weatherstripping',
			'window screen repair',
			'window glass replacement',
			'window frame repair',
			'window sash repair',
			'window lock repair',
			'window hinge repair',
			'window tinting',
			'window glazing',
			'window insulation',
			'energy efficient windows',
			'double pane windows',
			'triple pane windows',
			'window condensation',
			'window fogging',
			'window leak repair',
			'window draft repair',
			'window upgrade',
			'window renovation',
			'window contractor',
			'window specialist',
			'window technician',
		],
	},
};