// src/utils/layerUtils.ts

export interface Layer {
	name: string;
	source?: string;
	text?: string;
	visible: boolean
}

export const getRecursiveValues = (
	obj: any,
	currentLocation: string = '',
	elements: Layer[] = [],
): Layer[] => {
	if (typeof obj === 'string') {
		elements.push({
			name: currentLocation,
			text: obj,
			visible:true
		});
		if(currentLocation.startsWith('$campaign')){
			elements.push({
				name: currentLocation.replace('$campaign','$direction'),
				text: obj,
				visible:true
			});
		}
	} else if (Array.isArray(obj)) {
		obj.forEach((item, index) => {
			const location = `${currentLocation}[${index}]`;
			getRecursiveValues(item, location, elements);
		});
	} else if (typeof obj === 'object' && obj !== null) {
		for (const key in obj) {
			const location = currentLocation
				? `${currentLocation}.${key}`
				: `$${key}`;
			getRecursiveValues(obj[key], location, elements);
		}
	}
	return elements;
};

export const transformLayers = (template: any, output: any): Layer[] => {
	const elements: Layer[] = [];
	if (output.layers) {
		output.layers.forEach((layer: any) => {
			elements.push({
				name: layer.name,
				source: layer.imageUrl,
				visible:true
			});
		});
	}
	if (output.variables) {
		getRecursiveValues(output.variables, '', elements);
	}
	template.elements.filter( (layer: any) => { return (layer.name.startsWith('$') && layer.type == "composition" ) }).forEach((layer:any) => {
		const exists = elements.some((element)=> element.name==layer.name)
		if(!exists){
			elements.push({
				name: layer.name,
				visible: false,
			});
		}
	});
	return elements;
};
