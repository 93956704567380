import { useContext, useState } from 'react';
import { Box, Text, Heading, Button, Progress, Flex } from '@chakra-ui/react';
import UserContext from 'src/contexts/user/UserContext';
import { BillingStatus } from 'src/models/account/interfaces';
import { subscribe } from 'src/services/billing';
import {
	calculateTrialProgress,
	getRemainingTime,
	goToPaymentCheckout,
} from 'src/lib/utils/billing';
import { toastSuccess, toastError } from 'src/services/toast';

const CurrentPlanCard = () => {
	const { currentPlan, subscription, plans, fetchSubscription } =
		useContext(UserContext);
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	if (!subscription || !currentPlan) return null;

	const trialStartDate = new Date(subscription.startDate);
	const trialEndDate = new Date(subscription.freeTrialEnd);
	const remainingTime = getRemainingTime(trialEndDate);
	const progressPercent = calculateTrialProgress(trialStartDate, trialEndDate);
	const isTrial = subscription.billingStatus === BillingStatus.Trial;
	const nextPlan = plans.find((plan) => plan.level === currentPlan.level + 1);
	const hasPaymentMethod = subscription.paymentMethod?.card?.last4;

	const handleUpgrade = async () => {
		try {
			if (!nextPlan) return;
			await subscribe(nextPlan._id);
			fetchSubscription();
			toastSuccess('Subscription upgraded successfully');
		} catch (error) {
			toastError(error);
		}
	};

	const handleAddPayment = async () => {
		setIsButtonLoading(true);
		try {
			await goToPaymentCheckout('setup');
		} catch (error) {
			toastError(error);
		} finally {
			setIsButtonLoading(false);
		}
	};

	return (
		<Box p={4} w="full">
			<Flex mb={3}>
				<Heading as="h2" size="sm" mr={2}>
					Current Plan
				</Heading>
				<Button variant="orangeSolidWithoutEvent" py={0}>
					{currentPlan?.name}
				</Button>
			</Flex>

			{isTrial && (
				<>
					<Text fontSize="md" mb={3}>
						Your trial ends in <strong>{remainingTime}</strong>
					</Text>

					<Progress
						value={progressPercent > 1 ? progressPercent : 2}
						size="sm"
						borderRadius="md"
						mb={4}
					/>

					{!hasPaymentMethod && (
						<Button
							variant="orangeSolid"
							onClick={handleAddPayment}
							width="full"
							isLoading={isButtonLoading}
						>
							Add payment method
						</Button>
					)}
				</>
			)}
			{nextPlan && !isTrial && (
				<>
					<Text fontSize="md" mb={3}>
						Get {nextPlan.name} benefits
					</Text>
					<Button variant="orangeSolid" onClick={handleUpgrade} width="full">
						Upgrade
					</Button>
				</>
			)}
		</Box>
	);
};

export default CurrentPlanCard;
