import React, { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useRoutes } from 'react-router-dom';
import UserContext from './contexts/user/UserContext';
import { MenuProvider } from './contexts/app/menuContext';
import { DomainProvider } from './contexts/DomainContext';
import {
	CampaignConfigProvider,
	AccountConfigProvider,
	AssistantChatProvider,
} from './contexts';
import TemplateProvider from './contexts/templates/TemplatesProvider';
import { MyIntegrationsProvider } from './contexts/integration/IntegrationContext';
import FusionLoading from './components/common/FusionLoading';
import theme from './theme';
import getRoutes from './routes';
import { ToastContainer } from 'src/services/toast';
import useMyProfile from './hooks/useMyProfile';
import { UserProvider } from './contexts/user/UserProvider';

const AppContent: React.FC = () => {
	const { user } = useContext(UserContext);
	const routes = useRoutes(getRoutes(user));
	const profile = useMyProfile();

	return (
		<ChakraProvider theme={theme}>
			{profile.isLoading ? <FusionLoading boxProps={{ mt: 8 }} /> : routes}
		</ChakraProvider>
	);
};

const App: React.FC = () => {
	return (
		<UserProvider>
			<MenuProvider>
				<DomainProvider>
					<CampaignConfigProvider>
						<MyIntegrationsProvider>
							<AccountConfigProvider>
								<AssistantChatProvider>
									<TemplateProvider>
										<AppContent />
									</TemplateProvider>
								</AssistantChatProvider>
							</AccountConfigProvider>
						</MyIntegrationsProvider>
					</CampaignConfigProvider>
				</DomainProvider>
			</MenuProvider>
			<ToastContainer />
		</UserProvider>
	);
};

export default App;
