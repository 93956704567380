export const openIntercom = (message?: string) => {
	if (window.Intercom) {
		if (message) {
			window.Intercom('showNewMessage', message);
		} else {
			window.Intercom('show');
		}
	} else {
		console.error('Intercom not loaded');
	}
};
