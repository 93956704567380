import React, {
	FC,
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import { Box, Flex, Heading, Button } from '@chakra-ui/react';
import FusionLoading from 'src/components/common/FusionLoading';
import UserContext from 'src/contexts/user/UserContext';
import GoogleIntegrationCard, {
	GoogleIntegrationCardRef,
} from './GoogleIntegrationCard';
import { IGoogleIntegrationCustomers, IPage } from 'src/lib/schemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { toastError } from 'src/services/toast';
import {
	getFacebookAdAccounts,
	getFacebookPages,
	getInstagramPages,
} from 'src/services/integration';
import { getAccountIntegrations } from 'src/services/integration';
import IntegrationCard, { IntegrationCardRef } from './integrationCard';

interface IntegrationsProps {
	redirectedFromFB?: boolean;
	redirectedFromGoogle?: boolean;
	title?: string;
	isCampaignContext?: boolean;
	selectedChannels?: string[];
	hasBeenClosed?: boolean;
	isRoute?: boolean;
}

const Integrations: FC<IntegrationsProps> = ({
	redirectedFromFB = false,
	redirectedFromGoogle = false,
	title,
	isCampaignContext = false,
	selectedChannels,
	hasBeenClosed = false,
	isRoute = false,
}) => {
	const { user, account } = useContext(UserContext);
	const { state, pathname } = useLocation();
	const [pages, setPages] = useState<IPage[]>([]);
	const [instagramPages, setInstagramPages] = useState<IPage[]>([]);
	const [adAccounts, setAdAccounts] = useState<any[]>([]);
	const [adAccountsLoading, setAdAccountsLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [isConnectedToFb, setIsConnectedToFb] = useState(false);
	const [accessToken, setAccessToken] = useState<string | undefined>();
	const [noPagesAvailable, setNoPagesAvailable] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const {
		isLoading,
		integrations,
		mapPages,
		setShouldLaunch,
		googleCustomers,
		isGoogleLoading,
		saveIntegrations,
		googleErrorCode,
		setIntegrations,
	} = useMyIntegrations();
	const [selectedGoogleAccount, setSelectedGoogleAccount] =
		useState<IGoogleIntegrationCustomers | null>(null);
	const [noGoogleAccountsAvailable, setNoGoogleAccountsAvailable] =
		useState(false);
	const [isConnectedToGoogle, setIsConnectedToGoogle] = useState<
		boolean | undefined
	>(undefined);
	const [isSaving, setIsSaving] = useState(false);
	const facebookChannels = [
		'facebook',
		'facebookPaid',
		'instagram',
		'instagramPaid',
	];
	const googleChannels = ['google'];
	let needsFacebookIntegration = true;
	let needsGoogleIntegration = true;
	if (isCampaignContext) {
		needsFacebookIntegration =
			selectedChannels?.some((channel: string) =>
				facebookChannels.includes(channel),
			) ?? false;
		needsGoogleIntegration =
			selectedChannels?.some((channel: string) =>
				googleChannels.includes(channel),
			) ?? false;
	}
	const integrationCardRef = useRef<IntegrationCardRef>(null);
	const googleIntegrationCardRef = useRef<GoogleIntegrationCardRef>(null);
	const [hasFetched, setHasFetched] = useState(false);
	const saveAndPublish = pathname.includes('/projects/campaigns/');
	const navigate = useNavigate();
	const accountId = user?.account;
	const googleEnabled = integrations?.social?.google?.enabled ?? false;
	const finalIsConnectedToGoogle =
		googleEnabled && googleErrorCode !== 'revoked_token';
	const hasFetchedIntegrationsRef = useRef(false);

	// useEffect(() => {
	// 	if (isRoute && accountId && !hasFetchedIntegrationsRef.current) {
	// 		hasFetchedIntegrationsRef.current = true;
	// 		const fetchAccountIntegrations = async () => {
	// 			try {
	// 				const integrationsData = await getAccountIntegrations(accountId);
	// 				setIntegrations(integrationsData);
	// 			} catch (error: any) {
	// 				toastError(
	// 					error.message ||
	// 						'There was an error obtaining the account integrations.',
	// 				);
	// 				console.error('Error getting account integrations:', error);
	// 			}
	// 		};

	// 		fetchAccountIntegrations();
	// 	}
	// }, [isRoute, accountId, setIntegrations]);

	useEffect(() => {
		if (!isGoogleLoading && googleCustomers.length > 0) {
			setNoGoogleAccountsAvailable(false);
		} else {
			setNoGoogleAccountsAvailable(true);
		}
	}, [googleCustomers, isGoogleLoading]);

	useEffect(() => {
		setAccessToken(
			integrations?.social?.fb?.attributes?.tokens?.accessToken ??
				state?.facebookTokens?.access_token,
		);

		if (integrations) {
			const googleEnabled = integrations.social?.google?.enabled ?? false;
			setIsConnectedToGoogle(googleEnabled);

			if (!googleEnabled) {
				setError(null);
			}

			const fbEnabled = integrations.social?.fb?.enabled ?? false;
			setIsConnectedToFb(fbEnabled);
		} else {
			setIsConnectedToGoogle(undefined);
			setIsConnectedToFb(false);
		}
	}, [
		integrations,
		state,
		redirectedFromFB,
		redirectedFromGoogle,
		setShouldLaunch,
	]);

	useEffect(() => {
		if (redirectedFromFB || redirectedFromGoogle) {
			setInitialLoading(false);
		}
	}, [redirectedFromFB, redirectedFromGoogle]);

	useEffect(() => {
		if (!accessToken) {
			setInitialLoading(false);
			return;
		}
		if (hasFetched) {
			setInitialLoading(false);
			return;
		}
		const fetchAll = async () => {
			try {
				const [fbPagesData, fbAdAccountsData, igPagesData] = await Promise.all([
					getFacebookPages(accessToken),
					getFacebookAdAccounts(accessToken),
					getInstagramPages(accessToken),
				]);
				const mappedPages = mapPages(fbPagesData);
				setPages(mappedPages);
				if (!mappedPages || mappedPages.length === 0) {
					setNoPagesAvailable(true);
				} else {
					setNoPagesAvailable(false);
				}
				setError(null);
				const filteredAccounts = fbAdAccountsData.data.filter(
					(account: any) => account.account_status === 1,
				);
				setAdAccounts(filteredAccounts);
				const businesses = igPagesData.businesses?.data;
				if (businesses) {
					const pages: IPage[] = businesses.flatMap(
						(business: any) =>
							business.instagram_business_accounts?.data.map(
								(account: any) => ({
									id: account.id,
									name: account.username,
									image: account.profile_picture_url,
								}),
							) || [],
					);
					setInstagramPages(pages);
				}
				setHasFetched(true);
			} catch (error: any) {
				console.error('Error fetching integrations:', error);
			} finally {
				setInitialLoading(false);
			}
		};
		fetchAll();
	}, [accessToken, hasFetched, mapPages]);

	const getTitle = () => {
		if (title) return title;
		if (isCampaignContext)
			return 'Before you can publish, complete the integrations';
		if (!isConnectedToFb && !error)
			return 'Before you can publish, complete the integrations';
		return 'Integrations';
	};
	const isIntegrationsTitle = () => {
		const titleValue = getTitle();
		return titleValue === 'Integrations';
	};
	const handleSkipIntegrations = () => {
		if (saveAndPublish) {
			setShouldLaunch(true);
		}
	};

	const isFacebookIntegrationSaved =
		integrations?.social?.fb?.enabled &&
		(integrations.social.fb.attributes?.pagesToPublishContent?.length > 0 ||
			integrations.social.fb.attributes?.adAccounts?.length > 0);

	const isGoogleIntegrationSaved =
		integrations?.social?.google?.enabled &&
		(integrations.social.google.attributes?.customers?.length ?? 0) > 0;

	const [initialShowFacebookIntegration, setInitialShowFacebookIntegration] =
		useState<boolean | null>(null);
	const [initialShowGoogleIntegration, setInitialShowGoogleIntegration] =
		useState<boolean | null>(null);
	const initializedRef = useRef(false);

	useEffect(() => {
		if (!isCampaignContext) {
			setInitialShowFacebookIntegration(true);
			setInitialShowGoogleIntegration(true);
			return;
		}
		if (
			!initializedRef.current &&
			!initialLoading &&
			integrations &&
			selectedChannels
		) {
			const fb =
				needsFacebookIntegration &&
				!(isFacebookIntegrationSaved && redirectedFromGoogle) &&
				!(hasBeenClosed && isFacebookIntegrationSaved);
			const gg =
				needsGoogleIntegration &&
				!(isGoogleIntegrationSaved && redirectedFromFB) &&
				!(hasBeenClosed && isGoogleIntegrationSaved);
			setInitialShowFacebookIntegration(fb);
			setInitialShowGoogleIntegration(gg);
			initializedRef.current = true;
		}
	}, [
		isCampaignContext,
		initialLoading,
		integrations,
		selectedChannels,
		needsFacebookIntegration,
		needsGoogleIntegration,
		isFacebookIntegrationSaved,
		isGoogleIntegrationSaved,
		redirectedFromGoogle,
		redirectedFromFB,
		hasBeenClosed,
	]);

	const handleSaveIntegrations = async () => {
		setIsSaving(true);

		try {
			let fbIntegrationData = null;
			let googleIntegrationData = null;

			const isGoogleIntegrationRevoked =
				isConnectedToGoogle && googleErrorCode === 'revoked_token';

			if (integrationCardRef.current && isConnectedToFb) {
				fbIntegrationData = integrationCardRef.current.getIntegrationData();
			}

			if (
				googleIntegrationCardRef.current &&
				isConnectedToGoogle &&
				!isGoogleIntegrationRevoked
			) {
				googleIntegrationData =
					googleIntegrationCardRef.current.getIntegrationData();
			}

			if (fbIntegrationData || googleIntegrationData) {
				await saveIntegrations(fbIntegrationData, googleIntegrationData);
			}

			if (saveAndPublish) {
				setShouldLaunch(true);
			}
		} catch (error: any) {
			toastError(
				error.message || 'There was an error saving your integrations.',
			);
			console.error('Error saving integrations:', error);
		} finally {
			setIsSaving(false);
		}
	};

	const isGoogleIntegrationRevoked =
		isConnectedToGoogle && googleErrorCode === 'revoked_token';

	const showFacebook =
		initialShowFacebookIntegration === null
			? !isCampaignContext
			: initialShowFacebookIntegration;
	const showGoogle =
		initialShowGoogleIntegration === null
			? !isCampaignContext
			: initialShowGoogleIntegration;

	const hasVisibleIntegrationsConnected =
		(showFacebook && isConnectedToFb) ||
		(showGoogle && isConnectedToGoogle && !isGoogleIntegrationRevoked);

	const showSkipButton =
		isCampaignContext &&
		(!needsFacebookIntegration || noPagesAvailable) &&
		(!needsGoogleIntegration ||
			noGoogleAccountsAvailable ||
			isGoogleIntegrationRevoked);

	return (
		<Box
			justifyContent="center"
			w="full"
			h={isCampaignContext ? 'auto' : 'auto'}
			maxH={isCampaignContext ? '90vh' : 'auto'}
			p={0}
		>
			<Box
				maxW="600px"
				h={isCampaignContext ? 'full' : 'auto'}
				display="flex"
				flexDirection="column"
				p={0}
			>
				<FusionLoading isLoading={initialLoading} />
				{!initialLoading && (
					<>
						<Box
							position="sticky"
							top="0"
							bg="white"
							zIndex="1"
							pt={isIntegrationsTitle() ? 0 : 3}
							pb={isIntegrationsTitle() ? 0 : 1}
							w="100%"
						>
							<Heading
								fontSize="16px"
								textAlign="center"
								display={isIntegrationsTitle() ? 'flex' : 'block'}
							>
								{getTitle()}
							</Heading>
						</Box>
						<Flex
							direction="column"
							gap={3}
							flex={isCampaignContext ? '1' : 'none'}
							overflowY={isCampaignContext ? 'auto' : 'visible'}
							p={4}
							px={1}
							pb={1}
							css={{
								'&::-webkit-scrollbar': {
									width: '8px',
									backgroundColor: '#F5F5F5',
									visibility: 'visible',
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: '#888',
									borderRadius: '4px',
								},
								'&::-webkit-scrollbar-thumb:hover': {
									backgroundColor: '#555',
								},
							}}
						>
							{showFacebook && (
								<IntegrationCard
									ref={integrationCardRef}
									pages={pages}
									instagramPages={instagramPages ?? []}
									adAccounts={adAccounts}
									isConnetedToFb={isConnectedToFb}
									adAccountsLoading={adAccountsLoading}
									redirectedFromFB={redirectedFromFB}
									isCampaignContext={isCampaignContext}
									error={error}
									noPagesAvailable={noPagesAvailable}
								/>
							)}
							{showGoogle && (
								<GoogleIntegrationCard
									ref={googleIntegrationCardRef}
									isConnectedToGoogle={finalIsConnectedToGoogle}
									error={error || ''}
									noAccountsAvailable={noGoogleAccountsAvailable}
									setSelectedGoogleAccount={setSelectedGoogleAccount}
									redirectedFromGoogle={redirectedFromGoogle}
								/>
							)}
						</Flex>
						<Box
							position="sticky"
							bottom="0"
							bg="white"
							display="flex"
							zIndex="1"
							pt={3}
							pb={3}
							w="full"
							justifyContent="flex-end"
						>
							{saveAndPublish ? (
								showSkipButton ? (
									<Button
										w="auto"
										px={5}
										variant="orangeSolid"
										type="button"
										onClick={handleSkipIntegrations}
									>
										Skip
									</Button>
								) : hasVisibleIntegrationsConnected ? (
									<Button
										w="auto"
										px={5}
										variant="orangeSolid"
										type="button"
										onClick={handleSaveIntegrations}
										isLoading={isSaving}
										loadingText="Saving..."
									>
										Save and publish
									</Button>
								) : (
									<Button
										w="auto"
										px={5}
										variant="orangeSolid"
										type="button"
										onClick={handleSkipIntegrations}
									>
										Skip
									</Button>
								)
							) : hasVisibleIntegrationsConnected ? (
								<Button
									w="auto"
									px={5}
									variant="orangeSolid"
									type="button"
									onClick={handleSaveIntegrations}
									isLoading={isSaving}
									loadingText="Saving..."
								>
									Save
								</Button>
							) : null}
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default Integrations;
