import { Box } from '@chakra-ui/react';
import { useContext } from 'react';
import UserContext from 'src/contexts/user/UserContext';
import SubscriptionMessage from './SubscriptionMessage';
import PlanList from './PlanList';

const SubscriptionPage = () => {
	const { plans, currentPlan, subscription } = useContext(UserContext);

	return (
		<Box
			w="100%"
			minH="100vh"
			p={2}
			justifyContent="center"
			mx="auto"
			maxW="1500px"
		>
			<SubscriptionMessage subscription={subscription} />
			<PlanList plans={plans} currentPlan={currentPlan} />
		</Box>
	);
};

export default SubscriptionPage;
