import { createContext } from 'react';
import { ICompanyAccount, IUser } from 'src/lib/schemas';
import { IMenuItem } from 'src/services/menu';
import { IPlan, ISubscription } from 'src/models/account/interfaces';

export interface IUserContext {
	user: IUser | null;
	account: ICompanyAccount | null;
	navList: IMenuItem[];
	isLoading: boolean;
	setUser: (user: IUser | null) => void;
	setAccount: (account: ICompanyAccount | null) => void;
	setNavList: (navList: IMenuItem[]) => void;
	pendingAccountSettings: () => string[];
	subscription: ISubscription | null;
	plans: IPlan[];
	currentPlan?: IPlan;
	fetchSubscription: () => void;
}

const UserContext = createContext<IUserContext>({
	user: null,
	account: null,
	navList: [],
	isLoading: false,
	setUser: () => {},
	setAccount: () => {},
	setNavList: () => {},
	pendingAccountSettings: () => [],
	subscription: null,
	plans: [],
	currentPlan: undefined,
	fetchSubscription: () => {},
});

export default UserContext;
