import React, { Suspense, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import FusionLoading from 'src/components/common/FusionLoading';
import useWarnBeforeUnload from 'src/hooks/useWarnIfUnsavedChanges';

const DesignerModule = React.lazy(() => import('designer_module/editor'));

interface IDesignProps {
	onClose: () => void;
	onSave: (updatedTemplate: any) => Promise<void>;
	onChange: (updatedTemplate: any) => void;
	template: any;
	fonts: any;
}

const Design = ({
	fonts,
	template,
	onSave,
	onClose,
	onChange,
}: IDesignProps) => {
	const [isLoading, setIsLoading] = useState(true);

	useWarnBeforeUnload(true);

	useEffect(() => {
		const currentPath = window.location.pathname;
		if (!currentPath.endsWith('/editing')) {
			window.history.replaceState({}, '', currentPath + '/editing');
		}

		return () => {
			let newPath = window.location.pathname.replace(/\/editing$/, '');
			newPath = newPath.replace(/\/design$/, '');
			window.history.replaceState({}, '', newPath);
		};
	}, []);

	return (
		<Box w="100%" h="100vh" bg="whitesmoke">
			{isLoading && <FusionLoading isLoading={isLoading} />}
			<Suspense>
				<Box visibility={isLoading ? 'hidden' : 'visible'}>
					<DesignerModule
						fonts={fonts}
						template={template}
						onSave={onSave}
						onClose={onClose}
						onChange={onChange}
						onLoad={() => setIsLoading(false)}
					/>
				</Box>
			</Suspense>
		</Box>
	);
};

export default Design;
