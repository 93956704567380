import React, { FC, useState, useMemo } from 'react';
import { Flex, Image, Text, Box, VStack } from '@chakra-ui/react';
import { IoMdRefreshCircle } from 'react-icons/io';
import { LuEye } from 'react-icons/lu';
import LoadingList from './LoadingList';
import TemplatePreview from 'src/components/templates/TemplatePreview';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	isCreatomateAttributes,
	CommonAttributes,
} from 'src/lib/schemas/campaign/newFlowCampaign';

interface CarouselImageProps {
	designDirection: IDesignDirection;
	onRetry: (designDirection: IDesignDirection) => void;
	onSelect: (designDirection: IDesignDirection) => void;
	isSelected: boolean;
}

const isMediaAttributes = (
	attributes: CommonAttributes,
): attributes is IChannelMediaAttributes => {
	return (attributes as IChannelMediaAttributes).image !== undefined;
};

const DDImage: FC<CarouselImageProps> = ({
	designDirection,
	onRetry,
	onSelect,
	isSelected,
}) => {
	const { attributes, status, adEngine } = designDirection;
	const [isHovering, setIsHovering] = useState(false);
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const isPendingGeneration = status !== 'GENERATED';
	const hasError = status === 'ERROR' || status === 'failed';

	const creatomateTemplate = useMemo(() => {
		if (adEngine === 'creatomate' && isCreatomateAttributes(attributes)) {
			return attributes;
		}
		return null;
	}, [adEngine, attributes]);

	const normalizedTemplate = useMemo(() => {
		if (creatomateTemplate) {
			const clone = JSON.parse(JSON.stringify(creatomateTemplate));
			if (Array.isArray(clone.elements)) {
				clone.elements = clone.elements.map((el: any) => {
					if (typeof el.x === 'string') el.x = el.x.trim();
					if (typeof el.y === 'string') el.y = el.y.trim();
					if (typeof el.width === 'string') el.width = el.width.trim();
					if (typeof el.height === 'string') el.height = el.height.trim();
					if (el.elements && Array.isArray(el.elements)) {
						el.elements = el.elements.map((subEl: any) => {
							if (typeof subEl.x === 'string') subEl.x = subEl.x.trim();
							if (typeof subEl.y === 'string') subEl.y = subEl.y.trim();
							if (typeof subEl.width === 'string')
								subEl.width = subEl.width.trim();
							if (typeof subEl.height === 'string')
								subEl.height = subEl.height.trim();
							return subEl;
						});
					}
					return el;
				});
			}
			return clone;
		}
		return null;
	}, [JSON.stringify(creatomateTemplate)]);

	const handleImageLoaded = () => setIsImageLoaded(true);

	const renderContent = () => {
		if (hasError) {
			return (
				<Flex
					p={5}
					alignItems="center"
					direction="column"
					textAlign="center"
					gap={2}
					color="gray.500"
					bg="gray.100"
					w="323px"
					h="323px"
					justifyContent="center"
				>
					<Text>
						There was an issue generating the image. Please give it a retry.
					</Text>
					<IoMdRefreshCircle
						cursor="pointer"
						size={50}
						onClick={() => onRetry(designDirection)}
					/>
				</Flex>
			);
		}
		if (isPendingGeneration) {
			return (
				<Flex position="absolute" borderRadius="2xl" w="323px" h="323px">
					<Box
						borderRadius="2xl"
						display="block"
						position="relative"
						zIndex="-1"
						backgroundImage="url('/images/background_preview_DD.webp')"
						backgroundSize="cover"
						backgroundPosition="center"
					/>
					<LoadingList
						isPendingGeneration={isPendingGeneration}
						hasError={hasError}
						text1="Building layout"
						text2="Generating ad"
						text3="Splitting in multiple layers"
						size="lg"
						width="323px"
					/>
				</Flex>
			);
		}
		if (normalizedTemplate) {
			return (
				<Box w="323px" h="323px" borderTopRadius={16} pointerEvents="none">
					<TemplatePreview
						template={normalizedTemplate}
						onReady={handleImageLoaded}
					/>
				</Box>
			);
		}
		if (adEngine === 'photopea' && isMediaAttributes(attributes)) {
			return (
				<Image
					display="block"
					src={attributes.image.flatFile}
					fallbackSrc="/fusion-loader.gif"
					w="auto"
					h="full"
					borderTopRadius={16}
					data-testid="dd-image"
					onLoad={handleImageLoaded}
				/>
			);
		}
		if (isMediaAttributes(attributes)) {
			return (
				<Image
					display="block"
					src={attributes.image.flatFile}
					fallbackSrc="/fusion-loader.gif"
					w="auto"
					h="full"
					borderTopRadius={16}
					data-testid="dd-image"
				/>
			);
		}
		return null;
	};

	return (
		<Flex direction="column" alignItems="center" gap={6} flexShrink={0}>
			<Flex
				justify="center"
				alignItems="center"
				minW="323px"
				minH="323px"
				onMouseEnter={() => {
					if (!hasError && !isPendingGeneration && isImageLoaded) {
						setIsHovering(true);
					}
				}}
				onMouseLeave={() => setIsHovering(false)}
				onClick={() => !isPendingGeneration && onSelect(designDirection)}
				cursor={isPendingGeneration ? 'default' : 'pointer'}
			>
				<Flex
					zIndex="3"
					position={isHovering ? 'relative' : 'static'}
					w="full"
					h="full"
				>
					<Flex
						w="full"
						justify="center"
						alignItems="center"
						position="relative"
					>
						{renderContent()}
						{!isSelected && isHovering && !isPendingGeneration && !hasError && (
							<Flex
								cursor="pointer"
								position="absolute"
								zIndex={1}
								justify="center"
								alignItems="center"
								borderTopRadius={16}
								w="full"
								h="full"
								transition="ease-in"
								background="blackAlpha.700"
							>
								<VStack spacing={2}>
									<LuEye color="white" size={35} />
									<Text color="white" size="md">
										Preview marketing content
									</Text>
								</VStack>
							</Flex>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default DDImage;
