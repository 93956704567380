import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';

import('ace-builds/src-noconflict/ace').then(() => {
	import('ace-builds/src-noconflict/mode-html');
	import('ace-builds/src-noconflict/theme-github');
	import('ace-builds/src-noconflict/ext-searchbox');
});

import { uploadFile } from '../../services/fileUpload';
import { Editor } from '@tinymce/tinymce-react';

import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import config from 'src/config';

interface HTMLPreviewEditorProps {
  htmlContent: string;
  onHtmlChange: (newHtml: string) => void;
  view: 'preview' | 'html';
}

export default function HTMLPreviewEditor({
  htmlContent,
  onHtmlChange,
  view
}: HTMLPreviewEditorProps) {
  const [editorHtml, setEditorHtml] = useState(htmlContent);

  useEffect(() => {
    setEditorHtml(htmlContent);
  }, [htmlContent]);

  const handleTinyChange = (content: string) => {
    setEditorHtml(content);
    onHtmlChange(content);
  };

  const handleAceChange = (newValue: string) => {
    setEditorHtml(newValue);
    onHtmlChange(newValue);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(editorHtml);
    } catch (err) {
      console.error('Error copying:', err);
    }
  };
  return (
    <Box>
      {view === 'preview' && (
        <Editor
          apiKey={config.tinymce.apiKey}
          value={editorHtml}
          onEditorChange={handleTinyChange}
          init={{
            height: 610,
            menubar: false,
            plugins: ['link', 'image', 'lists'],
            toolbar:
            'undo redo | print | formatselect | ' +
            'bold italic underline | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | ' +
            'link image',
            content_style:"@page {    size: 21cm 45cm;  margin: 0;} ",
            toolbar_mode: 'wrap',
            toolbar_drawer: 'none',
            images_file_types: 'png,jpg,webp,svg',
            images_upload_handler: (blobInfo: any, progress: any) => {
              return new Promise((resolve, reject) => {
                (async () => {
                  try {
                    const file = blobInfo.blob();
                    const response = await uploadFile(file);
                    resolve(response.imageUrl);
                  } catch (err) {
                    reject('Error uploading image');
                  }
                })();
              });
            },
            file_picker_types: 'image',
            file_picker_callback: async (callback: any, _value: any, meta: any) => {
              if (meta.filetype === 'image') {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = async () => {
                  const file = input.files?.[0];
                  if (file) {
                    try {
                      const response = await uploadFile(file);
                      callback(response.imageUrl, { title: file.name });
                    } catch (error) {
                      console.error(error);
                    }
                  }
                };
                input.click();
              }
            }
          }}
        />
      )}
      {view === 'html' && (
        <Box>
          <Flex justify="space-between" alignItems="center" mb={2}>
            <Box fontWeight="semibold">HTML Code</Box>
            <Tooltip label="Copy" placement="top">
              <Box
                as="button"
                cursor="pointer"
                onClick={handleCopy}
                p={1}
                _hover={{ color: 'gray.600' }}
                display="flex"
                alignItems="center"
              >
                <FiCopy />
              </Box>
            </Tooltip>
          </Flex>

          <AceEditor
            mode="html"
            theme="github"
            onChange={handleAceChange}
            name="html-editor"
            value={editorHtml}
            fontSize={14}
            height="580px"
            width="100%"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              useWorker: false,
              wrap: true
            }}
          />
        </Box>
      )}
    </Box>
  );
}
