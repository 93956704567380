import React from 'react';
import { List, ListItem, ListIcon, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

interface FeaturesListProps {
	features: string[];
}

const FeaturesList: React.FC<FeaturesListProps> = ({ features }) => (
	<List spacing={3} mb={4}>
		{features.map((feature, index) => (
			<ListItem key={index} display="flex" alignItems="center">
				<ListIcon as={CheckCircleIcon} color="orange.base" mr={2} />
				<Text>{feature}</Text>
			</ListItem>
		))}
	</List>
);

export default FeaturesList;
