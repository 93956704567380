import http from './http';
import { ITemplate } from 'src/contexts/templates/TemplatesContext';
import { ICampaign } from 'src/lib/schemas';
import { toastError } from './toast';
import { generatePromotedObjectVersionHash } from 'src/utils/hashGenerator';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export const updateTemplate = async (
	template: ITemplate,
	templateid: string,
	scope: 'platform' | 'account',
) => {
	const { data } = await http.patch<ITemplate>(
		`${accountUrl}/templates/${templateid}?scope=${scope}`,
		template,
	);
	return data;
};

export const createTemplate = async (template: ITemplate) => {
	if (!template.id) {
		template.id = 'No title';
	}
	const { data } = await http.post<ITemplate>(
		`${accountUrl}/templates`,
		template,
	);
	return data;
};

export const makeTemplatePublic = async (templateid: string) => {
	const { data } = await http.post<ITemplate>(
		`${accountUrl}/templates/${templateid}`,
	);
	return data;
};

export const getTemplatesByIndustry = async (
	campaign?: ICampaign | null,
	queryParams: {
		isInternal?: boolean;
		extraParams?: string;
	} = {},
): Promise<{ results: ITemplate[]; facets: any[] }> => {
	try {
		const { isInternal, extraParams } = queryParams;

		let url = `${accountUrl}/templates/search`;
		const qs: string[] = [];

		if (extraParams) {
			qs.push(extraParams);
		}
		if (isInternal) {
			qs.push('is_internal=true');
		}

		if (qs.length > 0) {
			url += `?${qs.join('&')}`;
		}

		let payload: any = {};
		if (campaign) {
			const { creatives, designDirections, ...filteredCampaign } = campaign;
			payload = { ...filteredCampaign };
		}

		const { data } = await http.post<{ results: ITemplate[]; facets: any[] }>(
			url,
			payload,
		);

		return data;
	} catch (error: any) {
		toastError(error.message || 'Failed to fetch templates');
		throw new Error(
			`Failed to fetch templates${
				campaign ? ` for campaign ${campaign.id}` : ''
			}`,
		);
	}
};
export const getTemplates = async (account: string) => {
	try {
		const { data } = await http.get<ITemplate[]>(`${accountUrl}/templates`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getFonts = async () => {
	try {
		const { data } = await http.get<string[]>(`${accountUrl}/getFonts`);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates');
	}
};

export const getTemplateById = async (
	account: string,
	templateId: string,
	scope: 'platform' | 'account',
): Promise<ITemplate> => {
	try {
		const { data } = await http.get<ITemplate>(
			`${accountUrl}/templates/${templateId}?scope=${scope}&no_cache=true`,
		);
		return data;
	} catch (error: any) {
		throw new Error(`Failed to fetch template ${templateId}`);
	}
};

export const getTemplateLayers = async (
	templateId: string,
	campaignId: string,
	campaign?: ICampaign | null,
	template?: any,
) => {
	try {
		let design_promoted_object_version = '';

		if (campaign) {
			design_promoted_object_version = await generatePromotedObjectVersionHash({
				campaignDescription: campaign.description || '',
				audiences: campaign.audience || [],
				tones: campaign.tone || [],
				title: campaign.title || '',
				description: campaign.description || '',
				template: template,
			});
		}

		const inputs = {
			templateId,
			campaignId,
			design_promoted_object_version,
		};

		const url =
			'/v2/apps/fusion_ai.design_direction/execute/generate_creatomate_ad';
		const { data } = await http.post(url, { inputs });
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch template layers: ' + error);
	}
};

export const getUpdatedLayers = async () => {
	try {
		const inputs = {
			outputs: {
				layers: [
					{
						name: '$Product',
						source:
							'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg',
					},
					{
						name: '$account.logo',
						source:
							'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1024px-Instagram_icon.png',
					},
					{
						name: '$campaign.promotion[0].description',
						text: 'Updated description',
					},
					{
						name: '$PromocodeLabel',
						text: 'CODE: UPDATED',
					},
					{
						name: '$direction.headline',
						text: 'Updated headline',
					},
					{
						name: '$direction.punchline',
						text: 'Updated punchline',
					},
					{
						name: '$direction.callToAction',
						text: 'Updated CTA',
					},
				],
			},
		};
		const url = '/v2/apps/fusion_ai.mocks/execute/creatomate_layers?sync=true';
		const { data } = await http.post(url, { inputs });
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch templates: ' + error);
	}
};
