import React from 'react';
import { BillingStatus } from 'src/models/account/interfaces';
import CustomAlert from './CustomAlert';
import {
	formatNextPaymentDate,
	getRemainingTime,
	goToPaymentCheckout,
} from 'src/lib/utils/billing';

interface SubscriptionMessageProps {
	subscription: any;
}

const SubscriptionMessage: React.FC<SubscriptionMessageProps> = ({
	subscription,
}) => {
	if (!subscription) return null;
	const cardEnding = subscription.paymentMethod?.card?.last4;
	const nextBillingDate = new Date(subscription.nextBillingDate);
	const freeTrialEnd = new Date(subscription.freeTrialEnd);
	const hasPaymentMethod = Boolean(cardEnding);
	const trialTimeRemainingWarning = getRemainingTime(freeTrialEnd);
	const trialTimeRemainingInfo = getRemainingTime(nextBillingDate);
	const nextPaymentDate = formatNextPaymentDate(nextBillingDate);
	const isTrial = subscription.billingStatus === BillingStatus.Trial;

	let alertStatus:
		| 'info'
		| 'warning'
		| 'success'
		| 'error'
		| 'loading'
		| undefined = 'info';
	let alertButtonLabel = 'Update payment method';
	let alertContent = null;
	let onButtonClick;

	if (isTrial) {
		if (!hasPaymentMethod) {
			alertStatus = 'warning';
			alertButtonLabel = 'Add payment method';
			alertContent = (
				<>
					Your trial ends in <b>{trialTimeRemainingWarning}</b>. To continue
					using the service, provide your payment method.
				</>
			);
			onButtonClick = () => goToPaymentCheckout('setup');
		} else {
			alertContent = (
				<>
					Trial ends in <strong>{trialTimeRemainingInfo}</strong>. Once your
					trial finishes, we will charge your card ending with{' '}
					<strong>{cardEnding}</strong>.
				</>
			);
			onButtonClick = () => goToPaymentCheckout('setup');
		}
	} else {
		alertContent = (
			<>
				Next payment is on <strong>{nextPaymentDate}</strong>. We will charge
				your card ending with <strong>{cardEnding}</strong>.
			</>
		);
		onButtonClick = () => goToPaymentCheckout('setup');
	}

	return (
		<CustomAlert
			status={alertStatus}
			buttonLabel={alertButtonLabel}
			onButtonClick={onButtonClick}
			borderRadius="5px"
		>
			{alertContent}
		</CustomAlert>
	);
};

export default SubscriptionMessage;
