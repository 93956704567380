import React, { useContext, useMemo } from 'react';
import {
	Box,
	Image,
	Text,
	Button,
	Flex,
	HStack,
	Avatar,
} from '@chakra-ui/react';
import {
	ICreative,
	isCreatomateAttributes,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import UserContext from 'src/contexts/user/UserContext';
import { IoMdLink } from 'react-icons/io';
import MiniGlobeIcon from 'src/assets/icons/MiniGlobe';
import CreativeCard from './CreativeCard';
import { IChannelCreativeAttributes } from 'src/lib/schemas';
import { formatCallToAction } from './utils/formatedCTA';
import DDPhotoEditor, {
	DDPhotoEditorProps,
} from '../design-directions/DDPhotoEditor';
import FusionLoading from 'src/components/common/FusionLoading';
import TemplatePreview from 'src/components/templates/TemplatePreview';

interface StoryCreativeProps extends Partial<DDPhotoEditorProps> {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
	editMode?: boolean;
	creativesConfig?: IChannelCreativeAttributes[];
	isPaid?: boolean;
}

const StoryCreative = ({
	creative,
	withActions = true,
	size = 'md',
	creativesConfig,
	editMode = false,
	isPaid,
	...photoEditorProps
}: StoryCreativeProps) => {
	const { user, account } = useContext(UserContext);
	const callToAction = (creative as any).attributes.callToAction;
	const isPending =
		creative.status === 'pending' || creative.status === 'generating';
	const isCreatomate =
		creative.adEngine === 'creatomate' &&
		isCreatomateAttributes(creative.attributes);

	const normalizedTemplate = useMemo(() => {
		if (isCreatomate) {
			const clone = JSON.parse(JSON.stringify(creative.attributes));
			if (Array.isArray(clone.elements)) {
				clone.elements = clone.elements.map((el: any) => {
					if (typeof el.x === 'string') el.x = el.x.trim();
					if (typeof el.y === 'string') el.y = el.y.trim();
					if (typeof el.width === 'string') el.width = el.width.trim();
					if (typeof el.height === 'string') el.height = el.height.trim();
					if (el.elements && Array.isArray(el.elements)) {
						el.elements = el.elements.map((subEl: any) => {
							if (typeof subEl.x === 'string') subEl.x = subEl.x.trim();
							if (typeof subEl.y === 'string') subEl.y = subEl.y.trim();
							if (typeof subEl.width === 'string')
								subEl.width = subEl.width.trim();
							if (typeof subEl.height === 'string')
								subEl.height = subEl.height.trim();
							return subEl;
						});
					}
					return el;
				});
			}
			return clone;
		}
		return null;
	}, [JSON.stringify(creative.attributes)]);

	const imageSrc = !isCreatomate
		? (creative as any).attributes.image?.flatFile
		: null;

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="Story"
			creativesConfig={creativesConfig}
		>
			{isPending ? (
				<Flex width={size} minH="680px" justifyContent="center">
					<FusionLoading isLoading />
				</Flex>
			) : (
				<Box minH="680px" minW="385px">
					<Box p={4} position="absolute" zIndex={1}>
						<Flex alignItems="center" mb={4} gap={2}>
							<Avatar
								src={account?.logo}
								sx={{ img: { objectFit: 'contain' } }}
							/>
							<Box>
								<Text color="black" fontWeight="bold">
									{user?.name}
								</Text>
								<HStack spacing={1}>
									{isPaid && (
										<>
											<Text color="black" fontSize="sm">
												Sponsored ·
											</Text>
											<MiniGlobeIcon />
										</>
									)}
								</HStack>
							</Box>
						</Flex>
					</Box>
					{isCreatomate && normalizedTemplate ? (
						<Box w="full" h="680px" borderTopRadius="lg">
							<TemplatePreview
								key={JSON.stringify(creative.attributes)}
								template={normalizedTemplate}
								borderRadius="10px"
							/>
						</Box>
					) : !editMode ? (
						<Image
							src={imageSrc}
							alt="Preview"
							fallbackSrc="/fusion-loader.gif"
							borderRadius="lg"
						/>
					) : (
						photoEditorProps.designDirection &&
						photoEditorProps.isChangingLayer !== undefined &&
						photoEditorProps.onRefetchCreatives && (
							<DDPhotoEditor
								key={photoEditorProps.layeredFile}
								layeredFile={photoEditorProps.layeredFile}
								textLayersContent={photoEditorProps.textLayersContent}
								mediaLayersContent={photoEditorProps.mediaLayersContent}
								fonts={photoEditorProps.fonts}
								designDirection={photoEditorProps.designDirection}
								isChangingLayer={photoEditorProps.isChangingLayer}
								onRefetchCreatives={photoEditorProps.onRefetchCreatives}
								withActions={false}
								containerHeight="680px"
							/>
						)
					)}
					{callToAction && (
						<Flex
							position="absolute"
							bottom={5}
							width="100%"
							justifyContent="center"
						>
							<Button
								size="sm"
								cursor="default"
								bg="gray.100"
								px={3}
								_hover={{ bg: 'gray.100' }}
								fontWeight={500}
								leftIcon={<IoMdLink />}
							>
								{formatCallToAction(callToAction)}
							</Button>
						</Flex>
					)}
				</Box>
			)}
		</CreativeCard>
	);
};

export default StoryCreative;
