import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Text, Button } from '@chakra-ui/react';
import { CustomLayerPrompt } from './CustomLayerPrompt';
import { DirectionPrompt } from './DirectionPrompt';



interface CustomLayer {
	id: string;
	name: string;
	description: string;
}

export const Prompts = () => {
	const { watch, setValue } = useFormContext();
	const variations = watch('variations') || [];
	const elements = variations[0]?.template?.elements || [];
	const directionNames = [
		'$direction.headline',
		'$direction.punchline',
		'$direction.callToAction',
	];

	const findAllDollarLayers = (elements: any[]): any[] => {
		let foundElements: any[] = [];
	
		elements.forEach((element) => {
			if (element.name && element.name.startsWith('$')) {
				foundElements.push(element);
			}
	
			if (element.elements && Array.isArray(element.elements)) {
				foundElements = [...foundElements, ...findAllDollarLayers(element.elements)];
			}
		});
	
		return foundElements;
	};

	const dollarLayers = findAllDollarLayers(elements);
	const directionElements = dollarLayers.filter(el => directionNames.includes(el.name));

	const [customLayers, setCustomLayers] = useState<CustomLayer[]>(() => {
		const existingLayers = dollarLayers
			.filter(el => !directionNames.includes(el.name) && el.description)
			.map(el => ({
				id: crypto.randomUUID(),
				name: el.name,
				description: el.description || ''
			}));
		return existingLayers.length > 0 ? existingLayers : [{ id: crypto.randomUUID(), name: '', description: '' }];
	});
	const availableLayers = dollarLayers
		.filter(el => !directionNames.includes(el.name) && !customLayers.some(layer => layer.name === el.name))
		.map(el => el.name);

	useEffect(() => {
		if (elements.length > 0) {
			let hasChanges = false;

			directionElements.forEach((element) => {
				if (!element.description) {
					if (element.name === '$direction.punchline') {
						element.description =
							'A punchline with a length between 6 to 10 words. Do not mention any promotion in this field';
						hasChanges = true;
					} else if (element.name === '$direction.headline') {
						element.description =
							'A headline with a length between 3 to 7 words. Do not mention any promotion in this field';
						hasChanges = true;
					} else if (element.name === '$direction.callToAction') {
						element.description =
							'Based on the campaign information, pick one element from this list: ["Apply now","Book now","Contact us","Donate now","Get offer","Get quote","Learn more","Order now","Request time","See menu","Shop now","Sign up","Subscribe"]';
						hasChanges = true;
					}
				}
				if (!element.dynamic) {
					element.dynamic = true;
					hasChanges = true;
				}
			});

			if (hasChanges) {
				setValue('variations.0.template.elements', elements);
			}
		}
	}, [elements]);

	const handleCustomLayerChange = (id: string, layerName: string) => {
		setCustomLayers(layers => 
			layers.map(layer => {
				if (layer.id === id) {
					const element = dollarLayers.find(el => el.name === layerName);
					return {
						...layer,
						name: layerName,
						description: element?.description || ''
					};
				}
				return layer;
			})
		);
	};

	const handleCustomDescriptionChange = (id: string, value: string) => {
		setCustomLayers(layers =>
			layers.map(layer => {
				if (layer.id === id) {
					const element = dollarLayers.find(el => el.name === layer.name);
					if (element) {
						element.description = value;
						element.dynamic = true;
					}
					return { ...layer, description: value };
				}
				return layer;
			})
		);
		setValue('variations.0.template.elements', [...elements]);
	};

	const handleAddCustomLayer = () => {
		setCustomLayers(layers => [
			...layers,
			{ id: crypto.randomUUID(), name: '', description: '' }
		]);
	};

	const handleRemoveLayer = (id: string) => {
		const layer = customLayers.find(l => l.id === id);
		if (layer && layer.name) {
			const element = dollarLayers.find(el => el.name === layer.name);
			if (element) {
				delete element.description;
				delete element.dynamic;
				setValue('variations.0.template.elements', [...elements]);
			}
		}
		setCustomLayers(layers => layers.filter(l => l.id !== id));
	};

	return (
		<Box mt="30px">
			<Text fontWeight="bold" fontSize="24px" mb={4}>
				Prompts
			</Text>
			<Box>
				{directionElements.map((element) => (
					<DirectionPrompt
						key={element.id}
						element={element}
						onDescriptionChange={(value) => {
							element.description = value;
							setValue('variations.0.template.elements', [...elements]);
						}}
					/>
				))}


				{customLayers.map(layer => (
					<CustomLayerPrompt
						key={layer.id}
						selectedLayer={layer.name}
						availableLayers={availableLayers}
						customDescription={layer.description}
						onLayerChange={(name) => handleCustomLayerChange(layer.id, name)}
						onDescriptionChange={(value) => handleCustomDescriptionChange(layer.id, value)}
						onRemove={() => handleRemoveLayer(layer.id)}
					/>
				))}

				<Box mt={4}>
					<Button
						onClick={handleAddCustomLayer}
						size="xs"
					fontSize="12px"
					p={2}
					colorScheme="blue"
					>
						Add Custom Layer
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
