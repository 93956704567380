import React, { useContext } from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import CustomModal from '../../common/CustomModal';
import PlanCard from './PlanCard';
import UserContext from 'src/contexts/user/UserContext';
import { formatPrice, getRemainingTime } from 'src/lib/utils/billing';
import { BillingStatus } from 'src/models/account/interfaces';

interface TrialEndingModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const TrialEndingModal: React.FC<TrialEndingModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { subscription, currentPlan } = useContext(UserContext);
	if (!subscription || !currentPlan) return null;

	const isTrialOver =
		subscription?.billingStatus === BillingStatus.TrialExpired;
	const isInactive = subscription?.billingStatus === BillingStatus.Inactive;
	const planName = currentPlan?.name;
	const freeTrialEnd = new Date(subscription.freeTrialEnd);
	const timeRemaining = getRemainingTime(freeTrialEnd);

	const titleText = isInactive
		? 'Your account is paused!'
		: isTrialOver
		? 'Your free trial is over!'
		: `Your trial period will end in ${timeRemaining}`;

	const subtitleText =
		isTrialOver || isInactive
			? `To continue enjoying FusionAds.ai, provide now your payment method. FusionAds.ai ${planName} offers a bundle of features to streamline your marketing campaigns.`
			: `To continue enjoying FusionAds.ai before your trial ends, FusionAds.ai ${planName} offers a bundle of features to streamline your marketing campaigns.`;

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="3xl"
			header={
				<Box textAlign="center" w="full" h="full">
					<Heading size="lg">{titleText}</Heading>
				</Box>
			}
			footer={null}
			headerBgColor="orange.base"
			headerTextColor="white"
			withoutCloseButton={isTrialOver || isInactive}
			headerBgImage="/fusion-header.png"
		>
			<VStack mx="auto" p={6} maxW="lg" spacing={6}>
				<Text fontSize="md" color="gray.700" align="center">
					{subtitleText}
				</Text>
				<PlanCard
					title={currentPlan.name}
					features={currentPlan.features ?? []}
					price={formatPrice(currentPlan.amount)}
					status={subscription.billingStatus}
					isSelected
					hasPaymentMethod={Boolean(subscription.paymentMethod?.card?.last4)}
				/>
			</VStack>
		</CustomModal>
	);
};

export default TrialEndingModal;
