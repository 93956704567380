import { useFormContext, Controller, get } from 'react-hook-form';
import { Box, Text, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	NumberInputHook,
	SelectSearchCreateableInputHook,
	SelectSearchInputHook,
} from 'src/components/common/form';
import {  TemplateFormValues } from '../TemplatesForm';
import { useContext, useEffect, useState } from 'react';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';
import { InfoIcon } from '@chakra-ui/icons';
import { IOption } from 'src/lib/schemas/misc';
import TagInputHook from 'src/components/common/form/TagsCreateableInput/TagInputHook';
import { DEFAULT_VALUES } from 'src/default/defaultTemplate';
interface AttributesSectionProps {
	industryOptions: any;
}
export const AttributesSection = ({
	industryOptions,
}: AttributesSectionProps) => {
	const {
		setValue,
		clearErrors,
		getValues,
		watch,
		formState: { errors },
	} = useFormContext<TemplateFormValues>();
	const { seasonsValues, styleValues } = useContext(TemplatesContext);

	const [seasonOptions, setSeasonOptions] = useState<
		{ label: string; value: string }[]
	>([]);
	const [styleOptions, setStyleOptions] = useState<
		{ label: string; value: string }[]
	>([]);

	const [optimizedOption] = useState([
		{ label: 'Product', value: 'Product' },
		{ label: 'Service', value: 'Service' },
	]);

	useEffect(() => {
		setSeasonOptions(
			seasonsValues.map((value) => ({ label: value, value: value })),
		);
		setStyleOptions(
			styleValues.map((value) => ({ label: value, value: value })),
		);
	}, [seasonsValues, styleValues]);

	const updateOptions = (
		name: string,
		newOption: { label: string; value: string },
	) => {
		const attribute = name.split('.').pop();

		if (attribute === 'style') {
			setStyleOptions((prevState) => [...prevState, newOption]);
		} else if (attribute === 'season') {
			setSeasonOptions((prevState) => [...prevState, newOption]);
		}
	};
	const handleCreateOption = (
		name: 'attributes.style' | 'attributes.season',
		newOption: string,
	) => {
		if (newOption.length > 0) {
			clearErrors('attributes.style');
			const newOpt = { label: newOption, value: newOption };
			updateOptions(name, newOpt);
			setValue(name, newOption);
		}
	};

	const renderLabelIndicator = (name: string) => {
		const error = get(errors, name);

		if (error) return <InfoIcon color="#e53e3e" ml="3px" />;
		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	const handleIndustrySelect = (
		newValue: (string | { label: string; value: string })[],
	) => {
		clearErrors('attributes.industries');
		if (
			newValue.length > 0 &&
			typeof newValue[0] === 'object' &&
			'value' in newValue[0]
		) {
			setValue(
				'attributes.industries',
				(newValue as { label: string; value: string }[]).map(
					(option) => option.value,
				),
			);
		} else {
			setValue('attributes.industries', newValue as string[]);
		}
	};

	const handleMainIndustryChange = (
		val: (string | IOption),
	) => {
		
		if(typeof val !== 'string' ){
			val = (val as IOption).id ?? ""
		}
		
		const defaults = DEFAULT_VALUES[val as string]

		setValue('attributes.mainIndustry', val)

		const currentIndustries = getValues('attributes.industries') || []
		const newIndustries = [val]

		currentIndustries.forEach(currentIndustry => {
			if(currentIndustry != val) {
				newIndustries.push(currentIndustry)
			}
		});
		
		if(defaults){
			if(defaults.alternativeIndustries){
				defaults.alternativeIndustries.forEach(alternativeIndustry => {
					if(newIndustries?.indexOf(alternativeIndustry) == -1) {
						newIndustries.push(alternativeIndustry)
					}
				});
				setValue('attributes.industries',newIndustries);
			}
			if(defaults.keywords){
				const keywords = getValues('attributes.tags') || []
				if(keywords) {
					defaults.keywords.forEach(keywordToAdd => {
						keywords.push(keywordToAdd.trim())
					});
					setValue('attributes.tags',keywords);
				}
			}
		}
		
	}

	return (
		<Flex direction="column" flex="1" gap={4} maxWidth="600px">
			<Text fontSize="16px" fontWeight="500">
				Attributes
			</Text>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Flex width="300px" gap={1} alignItems="center">
					<Text>Main industry</Text>
				</Flex>
				<Box width="100%" maxWidth="450px">
					<SelectSearchInputHook
						name="attributes.mainIndustry"
						placeholder="Select industry"
						options={industryOptions}
						isLoading={false}
						isClearable={true}
						onChange={handleMainIndustryChange}
					/>
				</Box>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Flex width="300px" gap={1} alignItems="center">
					<Text lineHeight="40px">Alternative industries</Text>
				</Flex>
				<Box width="100%" maxWidth="450px">
					<MultiSelectCreateableInputHook
						name="attributes.industries"
						placeholder="Select industries"
						options={industryOptions}
						isLoading={false}
						disableCreateOption={true}
						isMulti
						required
						onChange={handleIndustrySelect}
					/>
					<MultiSelectValuesHook
						name="attributes.industries"
						title="Selected Industries"
						options={industryOptions}
					/>
				</Box>
			</Flex>

			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
			
			
			<FormLabel>Tags</FormLabel>
			<TagInputHook
				name="attributes.tags"
				placeholder="Add tags"
			/>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
			<MultiSelectValuesHook
				name="attributes.tags"
				title="Selected Tags"
				options={(() => {
					const tags = watch('attributes.tags');
					if (typeof tags === 'string') {
						const parsedTags = (tags as string)
							.split(',')
							.map((tag) => ({
								label: tag.trim(),
								value: tag.trim(),
							}));
						
						return parsedTags;
					}
					if (Array.isArray(tags)) {
						const parsedTags = tags.map((tag) => ({
							label: tag.trim(),
							value: tag.trim(),
						}));

						return parsedTags;
					}
					return [];
				})()}
			/>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Text width="300px">Season</Text>
				<Box width="100%" maxWidth="450px">
					<SelectSearchCreateableInputHook
						name="attributes.season"
						placeholder="Select/create season"
						options={seasonOptions}
						isClearable={true}
						onCreateOption={(newOption) =>
							handleCreateOption('attributes.season', newOption)
						}
					/>
				</Box>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Flex width="300px" gap={1} alignItems="center">
					<Text>Style</Text>
					{renderLabelIndicator('attributes.style')}
				</Flex>
				<Box width="100%" maxWidth="450px">
					<SelectSearchCreateableInputHook
						name="attributes.style"
						placeholder="Select/create Style"
						options={styleOptions}
						isLoading={false}
						onCreateOption={(newOption) => {
							handleCreateOption('attributes.style', newOption);
						}}
						required
						isClearable
					/>
				</Box>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Flex width="300px" gap={1} alignItems="center">
					<Text>Optimized for</Text>
					<Text color="#e53e3e">*</Text>
				</Flex>
				<Box width="100%" maxWidth="450px">
					<SelectSearchInputHook
						name={'attributes.kind'}
						placeholder="Select"
						options={optimizedOption}
						isLoading={false}
						required
					/>
				</Box>
			</Flex>
			<Flex alignItems="flex-start" gap={2} width="100%" mt={4}>
				<Flex width="300px" gap={1} alignItems="center">
					<Text>Priority</Text>
					<Text color="#e53e3e">*</Text>
				</Flex>{' '}
				<Box width="100%" maxWidth="450px">
					<NumberInputHook name={`attributes.priority`} />
				</Box>
			</Flex>
		</Flex>
	);
};
