import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FusionLoading from 'src/components/common/FusionLoading';

interface RedirectPageProps {
	callback?: () => void;
}

const RedirectPage: React.FC<RedirectPageProps> = ({ callback }) => {
	const navigate = useNavigate();

	useEffect(() => {
		callback?.();
		const previousUrl = sessionStorage.getItem('previousUrl');
		navigate(previousUrl ?? '/', { replace: true });
		sessionStorage.removeItem('previousUrl');
	}, [navigate, callback]);

	return <FusionLoading isLoading={true} />;
};

export default RedirectPage;
