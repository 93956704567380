import React, { FC, useState, useEffect, useMemo } from 'react';
import { Flex, Heading, Image, Text, Box } from '@chakra-ui/react';
import ContentPendingProgress from 'src/components/common/ContentPendingProgress';
import CarouselActions from './CarouselActions';

import { IoMdRefreshCircle } from 'react-icons/io';
import LoadingList from 'src/components/common/Loading/LoadingList';
import { IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';

interface CarouselImageProps {
	designDirection: IDesignDirection;
	currentDDId: string | undefined;
	onEdit: (designDirection: IDesignDirection) => void;
	onRemove: (ddId: string) => void;
	onRetry: (designDirection: IDesignDirection) => void;
	isRemovingDD: boolean;
}

const CarouselImage: FC<CarouselImageProps> = ({
	designDirection,
	currentDDId,
	onEdit,
	onRemove,
	onRetry,
	isRemovingDD,
}) => {
	const { variant, attributes, status } = designDirection;
	const [isRemoved, setIsRemoved] = useState(false);
	const [isActions, setIsActions] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const link = (attributes as any)?.image?.flatFile;
	const psdLink = (attributes as any)?.image?.layeredFile;
	const isPendingGeneration = useMemo(() => status !== 'GENERATED', [status]);
	const hasError = useMemo(
		() => status === 'error' || status === 'failed',
		[status],
	);

	const handleRemoveDD = async () => {
		setIsRemoving(true);
		await onRemove(designDirection.id);
		setIsRemoving(false);
	};

	const handleDownload = () => {
		if (!psdLink) {
			console.error('No PSD file link available');
			return;
		}

		const linkElement = document.createElement('a');
		linkElement.href = psdLink;
		linkElement.download = `design-${designDirection.id}.psd`;
		document.body.appendChild(linkElement);
		linkElement.click();
		document.body.removeChild(linkElement);
	};

	return (
		<Flex direction="column" alignItems="center" gap={6} flexShrink={0}>
			<Flex
				w="450px"
				h="450px"
				borderRadius="21px"
				onClick={
					isActions && !isPendingGeneration
						? () => onEdit(designDirection)
						: undefined
				}
				cursor={isActions && !isPendingGeneration ? 'pointer' : 'default'}
				p={1}
				justify="center"
				alignItems="center"
				onMouseEnter={() => setIsActions(true)}
				onMouseLeave={() => setIsActions(false)}
				border={
					currentDDId === designDirection.id
						? '2px solid #F7480B'
						: '2px solid transparent'
				}
			>
				<Flex
					zIndex="3"
					position={isActions ? 'relative' : 'static'}
					w="full"
					h="full"
				>
					<Flex
						w="full"
						justify="center"
						alignItems="center"
						position="relative"
					>
						{isRemoving && (
							<ContentPendingProgress
								time={5}
								isPendingGeneration={isRemoving}
								onGenerateStatusChange={(val) => setIsRemoved(val)}
								progressText="Removing"
							/>
						)}
						{!isRemoving && !hasError && (
							<>
								<Flex w="438px" h="438px" position="absolute">
									<Box
										display={isPendingGeneration ? 'block' : 'none'}
										position="relative"
										zIndex="-1"
										maxW={{ base: '200px', md: '400px' }}
										maxH={{ base: '200px', md: '400px' }}
										borderRadius="15px"
										backgroundImage="url('/images/background_preview_DD.webp')"
										backgroundSize="cover"
										backgroundPosition="center"
									/>
									{isPendingGeneration && !isRemoving && !isRemoved && (
										<LoadingList
											isPendingGeneration={isPendingGeneration}
											hasError={hasError}
										/>
									)}
								</Flex>
							</>
						)}
						{hasError && (
							<Flex
								p={5}
								alignItems="center"
								direction="column"
								textAlign="center"
								gap={2}
								color="gray.500"
								w="80%"
							>
								<Text>
									There was an issue generating the image. Please give it a
									retry.
								</Text>
								<IoMdRefreshCircle
									cursor="pointer"
									size={50}
									onClick={() => onRetry(designDirection)}
								/>
							</Flex>
						)}
						<Image
							display={isRemoving || isRemoved || hasError ? 'none' : 'block'}
							src={link}
							fallbackSrc="/fusion-loader.gif"
							w="auto"
							h="full"
							borderRadius="15px"
						/>
					</Flex>
					{isActions && (
						<CarouselActions
							isLoading={isPendingGeneration}
							onEdit={() => onEdit(designDirection)}
							onRemove={handleRemoveDD}
							onDownload={handleDownload}
							isRemovingDD={isRemovingDD}
						/>
					)}
				</Flex>
			</Flex>
			<Heading>Option {variant}</Heading>
		</Flex>
	);
};

export default CarouselImage;
