import RedirectPage from 'src/components/common/RedirectPage';
import { toastSuccess } from 'src/services/toast';

const SuccessPage = () => (
	<RedirectPage
		callback={() => toastSuccess('Payment method added successfully')}
	/>
);

export default SuccessPage;
