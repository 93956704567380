export enum ElementType {
  TEXT = "text",
  IMAGE = "image",
  VIDEO = "video",
  SHAPE = "shape",
  COMPOSITION = "composition",
}

export enum AnimationProperty {
  OPACITY = "opacity",
  SCALE = "scale",
  POSITION = "position",
}

export enum OutputFormat {
  MP4 = "mp4",
  GIF = "gif",
  JPG = "jpg",
}
