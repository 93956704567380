import { FC, Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
	Container,
	Flex,
	Heading,
	Divider,
	Box,
	Center,
	Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import BackgroundPrompt from '../CampaignBrief/parts/BackgroundPrompt';
import MediaCreatives from './parts/MediaCreatives';
import TextCreactive from './parts/TextCreactive';
import useFirstCampaign from 'src/hooks/useFirstCampaign';
import useProductTour from 'src/hooks/useProductTour';

import {
	createOrUpdateCampaign,
	genrateNewDesignDirection,
	getCampaign,
	regenerateCampaignCreative,
	removeDesignDirection,
	updateCampaignCreative,
} from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import {
	IChannelCreativeAttributes,
} from 'src/lib/schemas';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants';
import { PRODUCT_TOUR_STEPS } from '../utils/constants';
import DesignDirections from './parts/DesignDirections';
import { CampaignContext } from 'src/contexts';
import http from 'src/services/http';
import { cleanObject_id } from 'src/lib/utils';
import { SparklesIcon } from 'src/assets/icons';
import { ICreative, IDesignDirection } from 'src/lib/schemas/campaign/newFlowCampaign';

interface DesignOutputProps {
	creatives?: Array<ICreative>;
	designDirections?: Array<IDesignDirection>;
	channelCreativeAttributes: Array<IChannelCreativeAttributes> | null;
	onGenerateCreatives: (designDirectionId?: string) => void;
	onGenerateDesignDirections: () => void;
	isBriefAndPromptVisible: boolean;
	isGeneratingDesignDirections: boolean;
	setIsGeneratingDesignDirections: (value: boolean) => void;
	setIsBriefAndPromptVisible: (isBriefAndPromptVisible: boolean) => void;
}

const DesignOutput: FC<DesignOutputProps> = ({
	isBriefAndPromptVisible,
	setIsBriefAndPromptVisible,
	isGeneratingDesignDirections,
	setIsGeneratingDesignDirections,
	...props
}) => {
	const [creatives, setCreatives] = useState(
		props.creatives ?? ([] as Array<ICreative>),
	);
	const [designDirections, setDesignDirections] = useState(
		props.designDirections ?? ([] as Array<IDesignDirection>),
	);
	const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
	const [isGeneratingCreatives, setIsGeneratingCreatives] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [shouldScrollToDD, setShouldScrollToDD] = useState(false);
	const [showDD, setShowDD] = useState(true);
	const { campaign, setCampaign } = useContext(CampaignContext);

	const timeoutId = useRef<any>(null);
	const { campaignId } = useParams();
	const isFirstCampaignWithCreatives = useFirstCampaign(campaignId);
	const campaignTourState = useProductTour({ steps: PRODUCT_TOUR_STEPS });
	const creativesRef = useRef<HTMLDivElement>(null);
	const ddRef = useRef<HTMLDivElement>(null);
	const showGenerateButton =
		Boolean(campaign?.brief?.prompt) &&
		!designDirections.length &&
		!isGeneratingDesignDirections;

	useEffect(() => {
		setDesignDirections(props.designDirections || []);
	}, [props.designDirections]);

	const isDesignDirectionPending = designDirections.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	const handleRefetchCampaignDesignDirection = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;

			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			const isPending = response.designDirections?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);
			if (isPending) {
				timeoutId.current = setTimeout(
					handleRefetchCampaignDesignDirection,
					1000,
				);
			} else {
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		if (isDesignDirectionPending && !timeoutId.current) {
			handleRefetchCampaignDesignDirection();
		}
	}, [isDesignDirectionPending, timeoutId.current]);

	const handleScrollToView = (ref: React.RefObject<HTMLDivElement>) => {
		setTimeout(() => {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}, 100);
	};

	useEffect(() => {
		let scrollTimeoutId: NodeJS.Timeout | undefined;
		if (designDirections.length > 0 && shouldScrollToDD) {
			scrollTimeoutId = setTimeout(() => {
				handleScrollToView(ddRef);
				setShouldScrollToDD(false);
			}, 400);
		}
		return () => {
			clearTimeout(scrollTimeoutId);
		};
	}, [designDirections, shouldScrollToDD]);

	const handleGenerateDesignDirections = async (shouldScroll: boolean) => {
		setShowDD(true);
		if (!campaign?.brief?.prompt) {
			setIsGeneratingPrompt(true);
			generatePrompt(true);
		} else {
			setIsGeneratingDesignDirections(true);
			await props.onGenerateDesignDirections();
			shouldScroll && setShouldScrollToDD(true);
			setIsGeneratingDesignDirections(false);
		}
	};

	const handleRefreshDesignDirections = async (
		withCreatives: boolean = false,
	) => {
		if (!campaignId) return;
		const response = await getCampaign(campaignId);
		response && setCampaign(response);
		setDesignDirections(response.designDirections ?? []);
		withCreatives && setCreatives(response.creatives ?? []);
	};

	const handleGenerateNewDD = async () => {
		try {
			if (!campaignId) return;
			await genrateNewDesignDirection(campaignId);
			handleRefreshDesignDirections();
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRemoveDesignDirection = async (id: string) => {
		try {
			if (!campaignId || !id) return;
			await removeDesignDirection(campaignId, id);
			handleRefreshDesignDirections(true);
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		((Boolean(campaign?.brief?.prompt) &&
			Boolean(!campaign?.designDirections?.length)) ||
			isDesignDirectionPending) &&
			setShowDD(false);
		!!campaign?.designDirections?.length && setShowDD(true);
	}, [campaign]);

	useEffect(() => {
		if (!props.creatives) return;
		setCreatives(props.creatives);
	}, [props.creatives]);

	const isCreativesPending = creatives.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const handleRefetchCampaignCreative = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;

			const response = await getCampaign(campaignId);
			setCreatives(response.creatives ?? []);
			const isPending = response.creatives?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);
			if (isPending) {
				timeoutId.current = setTimeout(handleRefetchCampaignCreative, 1000);
			} else {
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		return () => {
			if (timeoutId.current) clearTimeout(timeoutId.current);
		};
	}, []);

	useEffect(() => {
		if (isCreativesPending && !timeoutId.current) {
			handleRefetchCampaignCreative();
		}
	}, [isCreativesPending, timeoutId.current]);

	const textCreatives = creatives.filter((c) => c.mediaType.includes('text'));
	const mediaCreatives = creatives.filter(
		(c) => c.mediaType.includes('image') && c.channel !== 'design-direction',
	);

	const uniqueVariantsSet = new Set(creatives.map((c) => c.variant));
	const uniqueVariants = Array.from(uniqueVariantsSet.values()).sort();
	const uniqueTextCreativesSet = new Set(textCreatives.map((c) => c.channel));
	const uniqueTextCreatives = Array.from(uniqueTextCreativesSet.values());
	const uniqueMediaCreativesSet = new Set(mediaCreatives.map((c) => c.channel));
	const uniqueMediaCreatives = Array.from(uniqueMediaCreativesSet.values());

	const isCreativesAvailable = Boolean(creatives.length);

	const hideButton = isGeneratingDesignDirections;

	const mediaCreativeAGenerated = mediaCreatives.some(
		(c) => c.variant === uniqueVariants[0] && c.status === 'GENERATED',
	);

	useEffect(() => {
		if (mediaCreativeAGenerated && isFirstCampaignWithCreatives)
			campaignTourState.start();
	}, [mediaCreativeAGenerated, isFirstCampaignWithCreatives]);

	const hanldeRegenrateCreative = async (id: string) => {
		if (!campaignId) return;
		try {
			const payload = {
				creativeId: id,
			};
			await regenerateCampaignCreative(campaignId, payload);
			const response = await getCampaign(campaignId);
			setCreatives(response.creatives ?? []);
		} catch (error: any) {
			toastError(error);
		}
	};

	const hanldeRegenrateTextCreatives = async (id: string) => {
		if (!campaignId) return;
		try {
			await regenerateCampaignCreative(campaignId, { creativeId: id });
			const response = await getCampaign(campaignId);
			setCreatives(response.creatives ?? []);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleLockUnlockCreative = async (
		creativeId: string,
		locked: boolean,
		isDD?: boolean,
	) => {
		if (!campaignId) return;
		try {
			await updateCampaignCreative(campaignId, creativeId, { locked });
			const response = await getCampaign(campaignId);
			isDD
				? setDesignDirections(response.designDirections ?? [])
				: setCreatives(response.creatives ?? []);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleGenerateCreatives = async (designDirectionId?: string) => {
		setIsGeneratingCreatives(true);
		await props.onGenerateCreatives(designDirectionId);
		setIsGeneratingCreatives(false);
		campaignTourState.setShouldRun(true);
	};

	const handleRefreshCreatives = async () => {
		if (!campaignId) return;
		const response = await getCampaign(campaignId);
		setCreatives(response.creatives ?? []);
	};

	const generatePrompt = async (isEmpty?: boolean) => {
		if (!campaign) return;
		const genPromptPayload = {
			inputs: {
				brief: campaign?.brief?.description,
				audiences: campaign.audience,
				tones: campaign.tone,
				products: campaign.promotedObject.ids,
			},
		};
		try {
			setIsGeneratingPrompt(true);

			const { data } = await http.post(
				'/v2/apps/fusion_ai.generate_bg_description/execute/generate_background',
				genPromptPayload,
			);
			if (data) await processCallback(data.callback, isEmpty);
		} catch (error) {
			toastError(error);
			setIsGeneratingPrompt(false);
		}
	};

	const processCallback = async (callbackUrl: string, isEmpty?: boolean) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl, isEmpty), 500);
			} else if (status === 'error' || status === 'failed') {
				setIsGeneratingPrompt(false);
			} else if (status === 'successful') {
				if (!campaignId || !campaign) return;
				const brief = cleanObject_id({
					...campaign.brief,
					prompt: body.content,
				});
				const response = await createOrUpdateCampaign({ brief }, campaignId);
				setCampaign({ ...campaign, brief: response.brief });
				setIsGeneratingPrompt(false);
				if (isEmpty) {
					await props.onGenerateDesignDirections();
					setIsGeneratingDesignDirections(false);
					// handleScrollToView(ddRef);
					// setShouldScrollToDD(true);
				}
			}
		} catch (e) {
			toastError(e);
			setIsGeneratingPrompt(false);
			setIsGeneratingDesignDirections(false);
		}
	};

	useEffect(() => {
		// !campaign?.designDirections?.length
		// 	? setIsGeneratingDesignDirections(true)
		// 	: setIsGeneratingDesignDirections(false);
		!campaign?.brief?.prompt
			? setIsGeneratingPrompt(true)
			: setIsGeneratingPrompt(false);

		if (!campaign?.brief) return;
		if (!designDirections.length && !campaign.brief.prompt) {
			setIsGeneratingDesignDirections(true);
			handleGenerateDesignDirections(false);
		}
	}, [campaign]);

	const handleScroll = (position: number) => setScrollPosition(position);
	useEffect(() => {
		if (creativesRef.current) creativesRef.current.scrollLeft = scrollPosition;
	}, [scrollPosition]);

	const noOfCreatives = uniqueVariants.length;
	return (
		<Container maxW="7xl" py={5} p={0} m={0}>
			<Flex direction="column" gap={4}>
				<Box>
					<BackgroundPrompt
						onGeneratePrompt={generatePrompt}
						isGeneratingPropmt={isGeneratingPrompt}
					/>
				</Box>
				<Box>
					{showGenerateButton && (
						<Center>
							<Button
								mt="15px"
								colorScheme="secondary"
								fontWeight="medium"
								onClick={() => handleGenerateDesignDirections(true)}
								rightIcon={<SparklesIcon color="white" />}
							>
								Generate Content
							</Button>
						</Center>
					)}
				</Box>
				<Box ref={ddRef} mt={2} />

				{(showDD || isGeneratingDesignDirections) && (
					<DesignDirections
						onScroll={handleScroll}
						scrollPosition={scrollPosition}
						designDirections={designDirections}
						onRemoveDD={handleRemoveDesignDirection}
						onRefetchDD={handleRefreshDesignDirections}
						onGenerateCreatives={handleGenerateCreatives}
						isGeneratingCreatives={isGeneratingCreatives}
						isGeneratingDesignDirections={isGeneratingDesignDirections}
						handleLockUnlockDD={handleLockUnlockCreative}
						onGenerateNewDD={handleGenerateNewDD}
						creativesRef={creativesRef}
						handleIsGeneratingCreatives={(status) => {
							setIsGeneratingCreatives(status);
						}}
					/>
				)}
				{isCreativesAvailable && (
					<Fragment>
						<Flex
							ref={creativesRef}
							onScroll={() =>
								handleScroll(creativesRef.current?.scrollLeft || 0)
							}
							overflowX="scroll"
							direction="column"
						>
							{uniqueVariants.length > 0 && (
								<Flex gap={8} mt={4}>
									{uniqueVariants.map((variant) => (
										<Flex
											key={variant}
											minW={
												noOfCreatives === 1
													? 'full'
													: noOfCreatives === 2
													? '48%'
													: '464px'
											}
											maxW={
												noOfCreatives === 1
													? 'full'
													: noOfCreatives === 2
													? '48%'
													: '464px'
											}
										>
											<Heading fontWeight="bold">
												Option {variant} Preview
											</Heading>
										</Flex>
									))}
								</Flex>
							)}

							{uniqueVariants.length > 0 && (
								<Fragment>
									<Flex gap={8} mt={2}>
										{uniqueVariants.map((variant) => (
											<Flex
												direction="column"
												gap={8}
												key={variant}
												minW={
													noOfCreatives === 1
														? 'full'
														: noOfCreatives === 2
														? '48%'
														: '464px'
												}
												maxW={
													noOfCreatives === 1
														? 'full'
														: noOfCreatives === 2
														? '48%'
														: '464px'
												}
											>
												{uniqueTextCreatives.map((channel) => {
													const title =
														CHANNELS_NAME_MAPPING[channel] ?? channel;
													return (
														<TextCreactive
															key={channel}
															creative={textCreatives.find(
																(c) =>
																	c.channel === channel &&
																	c.variant === variant,
															)}
															channelCreativeAttributes={props.channelCreativeAttributes?.find(
																(a) =>
																	a.id === channel &&
																	a.creatives.some((c) =>
																		c.mediaType.includes('text'),
																	),
															)}
															title={title}
															onRegenerate={hanldeRegenrateTextCreatives}
															onLockUnlock={handleLockUnlockCreative}
															onRefetchCreatives={handleRefreshCreatives}
														/>
													);
												})}
											</Flex>
										))}
									</Flex>

									<Flex gap={8} mt={4}>
										{uniqueVariants.map((variant) => (
											<Flex
												key={variant}
												direction="column"
												borderRadius="md"
												bg="#F8F8F8"
												boxShadow="0px 0.913px 5.478px 0px rgba(0, 0, 0, 0.15)"
												minW={
													noOfCreatives === 1
														? 'full'
														: noOfCreatives === 2
														? '48%'
														: '464px'
												}
												maxW={
													noOfCreatives === 1
														? 'full'
														: noOfCreatives === 2
														? '48%'
														: '464px'
												}
											>
												{uniqueMediaCreatives.map((channel, index) => {
													return (
														<Fragment key={channel}>
															{index > 0 && <Box h={8} bg="white" />}
															<MediaCreatives
																creativesIndex={`media-creative-${index + 1}`}
																creatives={mediaCreatives.filter(
																	(c) =>
																		c.channel === channel &&
																		c.variant === variant,
																)}
																channelCreativeAttributes={props.channelCreativeAttributes?.find(
																	(a) => a.id === channel,
																)}
																onRegenerate={hanldeRegenrateCreative}
																onLockUnlock={handleLockUnlockCreative}
																onRefetchCreatives={handleRefreshCreatives}
															/>
														</Fragment>
													);
												})}
											</Flex>
										))}
									</Flex>
								</Fragment>
							)}
						</Flex>
					</Fragment>
				)}
			</Flex>
			{isCreativesAvailable && <Divider mt={12} />}
		</Container>
	);
};

export default DesignOutput;
