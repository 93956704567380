import React, {
	useState,
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useContext,
} from 'react';
import TemplateContext, {
	ILayerSpec,
	IPayloadsByCase,
	ITemplate,
	IUpdatePayload,
} from './TemplatesContext';
import { getTemplatesByIndustry } from 'src/services/templates';
import { toastError } from 'src/services/toast';
import { ILayer } from 'src/lib/schemas/account/template/TemplateSchema';
import httpAppsai from 'src/services/http-appsai';
import { IIndustry, getIndustries } from 'src/services/industries';
import UserContext from '../user/UserContext';
import { ICampaign } from 'src/lib/schemas';
import { ICreatomateTemplate } from 'src/models/creatomate-template/interfaces';

interface TemplateProviderProps {
	children: ReactNode;
}

export const TemplateProvider: FC<TemplateProviderProps> = ({ children }) => {
	const [layers, setLayers] = useState<ILayer[]>([]);
	const [loadingLayers, setLoadingLayers] = useState(false);
	const [accountTemplates, setAccountTemplates] = useState<ITemplate[]>([]);
	const [platformTemplates, setPlatformTemplates] = useState<ITemplate[]>([]);
	const [creatomateAccountTemplates, setCreatomateAccountTemplates] = useState<
		any[]
	>([]);
	const [creatomatePlatformTemplates, setCreatomatePlatformTemplates] =
		useState<ICreatomateTemplate[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
		null,
	);
	const { user, account } = useContext(UserContext);
	const [isOptionsReady, setIsOptionsReady] = useState<boolean>(false);
	const [styles, setStyles] = useState<string[]>([]);
	const [seasons, setSeasons] = useState<string[]>([]);
	const [styleValues, setStyleValues] = useState<string[]>([]);
	const [seasonsValues, setSeasonValues] = useState<string[]>([]);
	const [industries, setIndustries] = useState<IIndustry[]>([]);
	const [industriesLoaded, setIndustriesLoaded] = useState<boolean>(false);
	const [templatesLoaded, setTemplatesLoaded] = useState<boolean>(false);
	const [selectedIndustry, setSelectedIndustry] = useState<IIndustry | null>(
		null,
	);
	const [lastSearchWithCampaign, setLastSearchWithCampaign] = useState<
		boolean | null
	>(null);
	const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
	const [payloads, setPayloads] = useState<IUpdatePayload[]>([]);
	const [payloadsByCase, setPayloadsByCaseState] = useState<IPayloadsByCase[]>(
		[],
	);
	const [isSelectTemplateModalOpen, setSelectTemplateModalOpen] =
		useState(false);
	const [hasFetchedLayers, setHasFetchedLayers] = useState(false);
	const [configuredLayers, setConfiguredLayers] = useState<
		{ variationId: string; layerSpec: ILayerSpec[] }[]
	>([]);
	const [customTemplates, setCustomTemplates] = useState<ICreatomateTemplate[]>(
		[],
	);
	const [filteredCustomTemplates, setFilteredCustomTemplates] = useState<
		ICreatomateTemplate[]
	>([]);
	const [customAccountTemplates, setCustomAccountTemplates] = useState<
		ICreatomateTemplate[]
	>([]);
	const [processedCustomTemplates, setProcessedCustomTemplates] = useState<
		ICreatomateTemplate[]
	>([]);
	const [layeredTemplates, setLayeredTemplates] = useState<any[]>([]);
	const [selectedTemplatesData, setSelectedTemplatesData] = useState<
		(ITemplate | ICreatomateTemplate)[]
	>([]);

	const handleSetPayloadsByCase = (
		caseId: number,
		newPayload: IUpdatePayload,
		isRegenerate: boolean,
	) => {
		setPayloadsByCaseState((prev) => {
			const existingCase = prev.find((x) => x?.idCase === caseId);
			if (existingCase) {
				return prev.map((x) => {
					if (x?.idCase === caseId) {
						if (isRegenerate) {
							return {
								...x,
								payloadList: x.payloadList.map((p) =>
									p.variationId === newPayload.variationId ? newPayload : p,
								),
							};
						}
						return {
							...x,
							payloadList: [...x.payloadList, newPayload],
						};
					}
					return x;
				});
			} else {
				return [...prev, { idCase: caseId, payloadList: [newPayload] }];
			}
		});
	};

	const fetchIndustries = useCallback(async () => {
		try {
			const industriesData = await getIndustries();
			setIndustries(industriesData);
			setIndustriesLoaded(true);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const fetchTemplatesByScope = useCallback(async (campaign?: ICampaign) => {
		try {
			const wasCampaignUsed = !!campaign;
			if (templatesLoaded && lastSearchWithCampaign === wasCampaignUsed) {
				return;
			}

			setTemplatesLoaded(false);

			const usedCampaign = campaign || null;
			const queryParams = {
				isInternal: false,
				extraParams: undefined,
			};

			setLastSearchWithCampaign(wasCampaignUsed);

			const { results: allTemplates, facets } = await getTemplatesByIndustry(
				usedCampaign,
				queryParams,
			);

			const seasonsFacet = facets.find((f) => f?.id === 'seasons');
			if (seasonsFacet) {
				setSeasonValues(seasonsFacet.values.map((item: any) => item.value));
			}
			const stylesFacet = facets.find((f) => f?.id === 'styles');
			if (stylesFacet) {
				setStyleValues(stylesFacet.values.map((item: any) => item.value));
			}

			const filteredAccountTemplates = allTemplates.filter(
				(template) => template.scope === 'account',
			);
			const filteredPlatformTemplates = allTemplates.filter(
				(template) => template.scope === 'platform',
			);

			const creatomateAccountTempaltes = filteredAccountTemplates
				.map((t) => {
					const template = t.variations?.find(
						(variation) => variation?.id === 'design',
					)?.template;
					return {
						templateId: t.id,
						...template,
					};
				})
				.filter(
					(template) =>
						template !== undefined &&
						!Array.isArray(template) &&
						template.elements !== undefined,
				);

			const creatomatePlatformTempaltes = filteredPlatformTemplates
				.map((t) => {
					const template = t.variations?.find(
						(variation) => variation?.id === 'design',
					)?.template;
					return {
						templateId: t.id,
						score: t.score,
						...template,
					};
				})
				.filter((template) => template.elements !== undefined)
				.sort((a, b) => (b.score || 0) - (a.score || 0));

			setAccountTemplates(filteredAccountTemplates);
			setPlatformTemplates(filteredPlatformTemplates);
			setCreatomatePlatformTemplates(creatomatePlatformTempaltes);
			setCreatomateAccountTemplates(creatomateAccountTempaltes);

			const allStyles = new Set<string>();
			const allSeasons = new Set<string>();

			allTemplates.forEach((template) => {
				if (template.attributes?.style) {
					allStyles.add(template.attributes.style);
				}
				if (template.attributes?.season) {
					allSeasons.add(template.attributes.season);
				}
			});

			setStyles(Array.from(allStyles));
			setSeasons(Array.from(allSeasons));
			setTemplatesLoaded(true);
		} catch (error: any) {
			toastError(error.message || 'Failed to fetch templates');
		}
	}, []);

	const resetSelectedTemplate = () => {
		setSelectedTemplate(null);
		setSelectedTemplateIds([]);
	};

	const fetchLayers = async () => {
		if (
			!selectedTemplate?.variations ||
			selectedTemplate.variations.length === 0
		) {
			return;
		}
		setLoadingLayers(true);
		setHasFetchedLayers(true);
		try {
			const { data: responseData } = await httpAppsai.post(
				'/images/get-layers',
				{
					inputs: {
						layeredFile: selectedTemplate.variations[0].layeredFile,
						copyImages: false,
					},
				},
			);
			const mappedLayers: ILayer[] = responseData.layers
				.filter((layer: ILayer) => layer.name.startsWith('$'))
				.map((layer: ILayer) => ({
					name: layer.name,
					type: layer.type || 'image',
					visible: layer.visible,
					imageUrl: layer.imageUrl,
					content: layer.content,
				}));
			setLayers(mappedLayers);
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingLayers(false);
		}
	};

	useEffect(() => {
		if (selectedTemplate && !hasFetchedLayers) {
			fetchLayers();
		}
	}, [selectedTemplate?.variations]);

	useEffect(() => {
		if (industriesLoaded && templatesLoaded) {
			setIsOptionsReady(true);
		}
	}, [industriesLoaded, templatesLoaded]);

	useEffect(() => {
		if (user && user.isEmailVerified) {
			setIndustriesLoaded(false);
			setTemplatesLoaded(false);
			fetchIndustries();
		} else {
			setIndustries([]);
			setIndustriesLoaded(false);
			setTemplatesLoaded(false);
			setAccountTemplates([]);
			setPlatformTemplates([]);
			setStyles([]);
			setSeasons([]);
			setSelectedTemplate(null);
			setSelectedTemplateIds([]);
			setPayloads([]);
			setPayloadsByCaseState([]);
			setLayers([]);
			setConfiguredLayers([]);
		}
	}, [user?.id, user?.isEmailVerified, fetchIndustries]);

	return (
		<TemplateContext.Provider
			value={{
				selectedTemplatesData,
				setSelectedTemplatesData,
				templates: accountTemplates,
				templatesByScope: platformTemplates,
				creatomateAccountTemplates,
				creatomatePlatformTemplates,
				styles,
				seasons,
				industries,
				fetchIndustries,
				selectedIndustry,
				setSelectedIndustry,
				industriesLoaded,
				setIndustries,
				setIndustriesLoaded,
				setSelectTemplateModalOpen,
				selectedTemplate,
				setSelectedTemplate,
				selectedTemplateIds,
				setSelectedTemplateIds,
				fetchTemplatesByScope,
				payloads,
				templatesLoaded,
				isOptionsReady,
				setTemplatesLoaded,
				setPayloads,
				isSelectTemplateModalOpen,
				payloadsByCase,
				handleSetPayloadsByCase,
				setPayloadsByCaseState,
				resetSelectedTemplate,
				layers,
				setLayers,
				loadingLayers,
				configuredLayers,
				setConfiguredLayers,
				styleValues,
				seasonsValues,
				setStyleValues,
				setSeasonValues,
				customTemplates,
				setCustomTemplates,
				customAccountTemplates,
				setCustomAccountTemplates,
				processedCustomTemplates,
				setProcessedCustomTemplates,
				layeredTemplates,
				setLayeredTemplates,
				filteredCustomTemplates,
				setFilteredCustomTemplates,
				lastSearchWithCampaign,
			}}
		>
			{children}
		</TemplateContext.Provider>
	);
};

export default TemplateProvider;
