import { Flex, Box, Button, Heading, Text } from '@chakra-ui/react';
import CustomContainer from 'src/components/common/CustomContainer';
import FeaturesList from './FeaturesList';

interface PlanCardProps {
	title: string;
	features: string[];
	price: number | string;
	actionButtonText: string;
	onActionButton: () => void;
	isSelected?: boolean;
	isLoading?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
	title,
	features,
	price,
	actionButtonText,
	onActionButton,
	isSelected = false,
	isLoading,
}) => (
	<CustomContainer
		boxShadow="0 0 15px rgba(0, 0, 0, 0.2)"
		p={6}
		mb={6}
		border={isSelected ? '2px solid' : 'none'}
		borderColor={isSelected ? 'orange.base' : 'transparent'}
	>
		<Flex justify="space-between">
			<Box>
				{isSelected && (
					<Box mb={4}>
						<Button variant="grayWithoutEvent" fontWeight={700} px={2}>
							Current Plan
						</Button>
					</Box>
				)}
				<Button
					variant="orangeSolidWithoutEvent"
					px={2}
					py={1}
					mb={5}
					size="sm"
				>
					{title}
				</Button>
				<FeaturesList features={features} />
			</Box>

			<Box textAlign="right">
				<Flex alignItems="baseline" justifyContent="flex-end" mb={4}>
					<Heading
						as="h3"
						size="xl"
						color="gray.900"
						fontWeight="bold"
						lineHeight="1"
					>
						${price}
					</Heading>
					<Text fontSize="md" color="gray.500" ml={1}>
						/month
					</Text>
				</Flex>

				{!isSelected && (
					<Button
						isLoading={isLoading}
						onClick={onActionButton}
						variant="orangeSolid"
						px={10}
					>
						{actionButtonText}
					</Button>
				)}
			</Box>
		</Flex>
	</CustomContainer>
);

export default PlanCard;
