import { FC, memo, useEffect } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { IAttribute } from 'src/lib/schemas';
import { renderInputWidget } from './utils/renderInputWidget';
import { getValidationRules } from './utils/inputValidation';

interface MediaCreativeFormProps {
	inputs: IAttribute[];
	documentCount: number;
	initialValues?: any;
	isSubmitting?: boolean;
	onSubmit: (data: any) => void;
}

const MediaCreativeForm: FC<MediaCreativeFormProps> = ({
	inputs,
	documentCount,
	initialValues,
	isSubmitting,
	onSubmit,
}) => {
	const formMethods = useForm({ defaultValues: initialValues });

	useEffect(() => {
		formMethods.reset(initialValues);
	}, [initialValues]);

	const handleInputSubmit = formMethods.handleSubmit(async (formData) => {
		await onSubmit(formData);
	});

	const isInvalidNumberOfDocuments = documentCount !== 1;

	return (
		<Box>
			<FormProvider {...formMethods}>
				<form onSubmit={handleInputSubmit}>
					<Flex direction="column" gap={6}>
						{inputs.map((input) => {
							const validationRules = getValidationRules(input);
							const InputWidget = renderInputWidget(input.type);
							if (InputWidget) {
								return (
									<InputWidget
										key={input.name}
										input={input}
										validationRules={validationRules}
									/>
								);
							}
							return null;
						})}
						<Flex direction="column" gap={1}>
							<Box textAlign="right">
								<Button
									type="submit"
									colorScheme="secondary"
									onSubmit={onSubmit}
									isLoading={isSubmitting || formMethods.formState.isSubmitting}
									loadingText="Saving..."
									isDisabled={isInvalidNumberOfDocuments}
								>
									Save
								</Button>
							</Box>
							{isInvalidNumberOfDocuments && (
								<Text fontSize="xs" color="#f74809" align="right">
									To save the changes, please keep only open the image that you
									want to save.
								</Text>
							)}
						</Flex>
					</Flex>
				</form>
			</FormProvider>
		</Box>
	);
};

export default memo(MediaCreativeForm);
