import { useContext } from 'react';
import { Box, Button, Flex, HStack, Image, Show, Text } from '@chakra-ui/react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import Logo from 'src/assets/svgs/logo.svg';
import UserAvatar from 'src/components/common/UserAvatar';
import UserContext from 'src/contexts/user/UserContext';

const pages = ['login', 'signup'];

const pagesMap: Record<string, Record<string, string>> = {
	login: {
		redirect: '/signup',
		text: 'Don’t have an account yet?',
		buttonText: 'Sign Up',
	},
	signup: {
		redirect: '/login',
		text: 'Already have an account?',
		buttonText: 'Sign In',
	},
};

const Header = () => {
	const location = useLocation();
	const [search] = useSearchParams();
	const { user } = useContext(UserContext);

	const redirectUrl = search.get('redirect_url');

	const pagePath = location.pathname.split('/')[1];
	const { redirect, text, buttonText } =
		pagesMap[pages.includes(pagePath) ? pagePath : 'signup'];

	const link = redirectUrl
		? `${redirect}?redirect_url=${redirectUrl}`
		: redirect;

	const shouldShowAvatar = user && !user.permissions?.includes('demoByDomain');

	return (
		<Box h="71px" p="15px 20px" shadow="0px 2px 2px -1px rgba(0, 0, 0, 0.15)">
			<Flex justifyContent="space-between" alignItems="center">
				<Link to="https://www.fusionos.ai">
					<Image src={Logo} height="30px" />
				</Link>
				{shouldShowAvatar ? (
					<UserAvatar name={user.name} />
				) : (
					<HStack>
						<Show above="sm">
							<Text>{text}</Text>
						</Show>
						<Link to={link}>
							<Button variant="outline" fontWeight={400}>
								{buttonText}
							</Button>
						</Link>
					</HStack>
				)}
			</Flex>
		</Box>
	);
};

export default Header;
