/// <reference types="vite/types/importMeta.d.ts" />

const config = {
	app: {
        apiURL: import.meta.env.VITE_API_URL || '',
        genAiApiUrl: import.meta.env.VITE_GEN_AI_API_URL || '',
        appsApiUrl: import.meta.env.VITE_APPS_API_URL || '',
    },
    apmlitude: {
        apiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
    },
    facebook: {
        clientId: import.meta.env.VITE_FACEBOOK_CLIENT_ID || '',
        configId: import.meta.env.VITE_FACEBOOK_CONFIG_ID || '',
        userConfigId: import.meta.env.VITE_FACEBOOK_USER_CONFIG_ID || '',
        scope: import.meta.env.VITE_FACEBOOK_SCOPE || '',
    },
    google: {
        clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID || '',
        // adWordsclientId: import.meta.env.VITE_GOOGLE_ADWORDS_CLIENT_ID || '',
    },
    twitter: {
        clientId: import.meta.env.VITE_TWITTER_CLIENT_ID || '',
    },
	tinymce: {
		apiKey: import.meta.env.VITE_TINYMCE_API_KEY || ''
	},
    enviroment: import.meta.env.MODE || ''
};

export default config;
