import RedirectPage from 'src/components/common/RedirectPage';
import { customToast } from 'src/services/toast';

const CancelPage = () => (
	<RedirectPage
		callback={() =>
			customToast(
				'We could not add your payment method. Please try again.',
				'error',
			)
		}
	/>
);

export default CancelPage;
