import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Flex,
	HStack,
	Skeleton,
	Text,
	useDisclosure,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { IChannelCreativeAttributes, IChannelGroup } from 'src/lib/schemas';
import DDCard from './DDCard';
import GenerateOrSelectCard from './GenerateOrSelectCard';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { removeDuplicateLayers } from '../utils/removeDuplicateLayers';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import {
	getCampaign,
	removeDesignDirection,
	genrateCampaignCreatives,
	regenerateCampaignDesignDirection,
	genrateNewDesignDirection,
	editCampaignDesignDirections,
} from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import Creatives from '../creatives/Creatives';
import EmptyState from './EmptyState';
import { getTemplateLayers } from 'src/services/templates';
import { processCallback } from 'src/lib/utils/processCallback';
import {
	getHighestScoreTemplate,
	getTemplateIdsFromDesignDirections,
} from 'src/lib/utils/templates/templates';
import {
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import Design from 'src/pages/design/Design';
import ConfirmDialog from 'src/components/common/ConfirmDialog';
import { getFonts } from 'src/services/config';

interface DesignDirectionsProps {
	creativesConfig: IChannelCreativeAttributes[];
	onSubmit: () => void;
	isLoading?: boolean;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<
		React.SetStateAction<IDesignDirection[] | IDesignDirection[]>
	>;
	creatives: ICreative[];
	setCreatives: React.Dispatch<React.SetStateAction<ICreative[] | ICreative[]>>;
	availableChannelGroups: IChannelGroup[];
	selectedChannels: string[];
}

const DesignDirections = ({
	creativesConfig,
	isLoading,
	designDirections,
	setDesignDirections,
	creatives,
	setCreatives,
	availableChannelGroups,
	selectedChannels,
	...props
}: DesignDirectionsProps) => {
	const {
		id: campaignId,
		setCampaign,
		campaign,
		completeStep,
		wasCampaignChanged,
	} = useContext(CampaignContext);
	const { closeChat } = useContext(AssistantChatContext);
	const [selectedDesignDirectionId, setSelectedDesignDirectionId] = useState<
		string | null
	>(null);

	const { selectedTemplateIds, creatomatePlatformTemplates, layeredTemplates } =
		useContext(TemplateContext);

	const [fonts, setFonts] = useState<any>([]);
	const [selectedDesignDirection, setSelectedDesignDirection] =
		useState<IDesignDirection>();
	const [channels, setChannels] = useState<string[]>(campaign?.channels ?? []);
	const [loadingCreatives, setLoadingCreatives] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const [isAutoAssign, setIsAutoAssign] = useState(false);
	const {
		isOpen: isConfirmDialogOpen,
		onOpen: onOpenConfirmDialog,
		onClose: onCloseConfirmDialog,
	} = useDisclosure();

	const [editingDesignDirection, setEditingDesingDirection] =
		useState<IDesignDirection>();
	const prevDesignDirectionsLength = useRef(designDirections.length);
	const tagModalToggle = useToggleWithPayload<IDesignDirection>();
	const skeletonsRef = useRef<HTMLDivElement | null>(null);
	const ddRefs = useRef<Array<HTMLElement | null>>([]);

	const timeoutId = useRef<any>(null);
	const creativesRef = useRef<any>(null);
	const hasAnyGenerated = designDirections?.some(
		(c) => c.status === 'GENERATED',
	);
	const [selectedTemplatesCount, setSelectedTemplatesCount] = useState<number>(
		selectedTemplateIds.length,
	);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	const isShowingSkeletons = isLoading && designDirections.length === 0;

	const getFontsAccount = async () => {
		const fontArray = await getFonts();
		setFonts(fontArray);
	};
	useEffect(() => {
		getFontsAccount();
	}, []);
	useEffect(() => {
		if (isGeneratingDD) {
			if (isAutoAssign) {
				setSelectedTemplatesCount(1);
			} else {
				setSelectedTemplatesCount(selectedTemplateIds.length);
			}
		}
	}, [isGeneratingDD, selectedTemplateIds.length, isAutoAssign]);

	useEffect(() => {
		if (!isGeneratingDD) {
			setIsAutoAssign(false);
		}
	}, [isGeneratingDD]);

	useEffect(() => {
		if (isGeneratingDD) {
			setSelectedTemplatesCount(isAutoAssign ? 1 : selectedTemplateIds.length);
		}
	}, [isGeneratingDD, selectedTemplateIds.length, isAutoAssign]);
	useEffect(() => {
		setSelectedTemplatesCount(selectedTemplateIds.length);
	}, [selectedTemplateIds]);

	useEffect(() => {
		if (
			!selectedDesignDirectionId &&
			designDirections?.length &&
			designDirections[0].status === 'GENERATED'
		) {
			handleSelectDesignDirection(designDirections[0], false);
		}
	}, [designDirections]);

	useEffect(() => {
		if (isLoading || (isDesignDirectionPending && !timeoutId.current)) {
			handleRefetchCampaignDesignDirection();
		}
	}, [isDesignDirectionPending, timeoutId.current, isLoading]);

	useEffect(() => {
		campaign?.designDirections &&
			setDesignDirections(removeDuplicateLayers(campaign.designDirections));
		campaign?.creatives && setCreatives(campaign?.creatives);
		campaign?.channels && setChannels(campaign?.channels);
	}, [campaign]);

	useEffect(() => {
		if (isLoading || !designDirections.length) {
			setSelectedDesignDirectionId(null);
		} else if (
			!selectedDesignDirectionId ||
			(selectedDesignDirection?.id === designDirections[0].id &&
				designDirections[0].status === 'GENERATED' &&
				selectedDesignDirection?.status !== 'GENERATED') ||
			designDirections.every((d) => d.status === 'pending')
		) {
			handleSelectDesignDirection(designDirections[0]);
		}
	}, [isLoading, designDirections]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			setIsGeneratingDD(false);
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections]);

	useEffect(() => {
		if (isGeneratingDD && skeletonsRef.current) {
			skeletonsRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isGeneratingDD]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			const lastDDIndex = designDirections.length - 1;
			const lastDDRef = ddRefs.current[lastDDIndex];
			if (lastDDRef) {
				lastDDRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections.length]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			const lastDDIndex = designDirections.length - 1;
			const lastDDRef = ddRefs.current[lastDDIndex];
			if (lastDDRef) {
				lastDDRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections.length]);

	useEffect(() => {
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections]);

	useEffect(() => {
		if (editingDesignDirection) {
			window.history.pushState({ editing: true }, '');

			const onPopState = () => {
				onOpenConfirmDialog();
			};

			window.addEventListener('popstate', onPopState);

			return () => {
				window.removeEventListener('popstate', onPopState);
			};
		}
	}, [editingDesignDirection]);

	const handleRefetchCampaignDesignDirection = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;
			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			const isPending = response.designDirections?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);
			if (isPending) {
				timeoutId.current = setTimeout(
					handleRefetchCampaignDesignDirection,
					1000,
				);
			} else {
				setIsGeneratingDD(false);
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRefreshDesignDirectionsWithCount = async (count: number) => {
		setSelectedTemplatesCount(count);
		await handleRefreshDesignDirections();
	};

	const handleRetryGenerateDD = async (designDirection: IDesignDirection) => {
		if (!campaignId) return;
		try {
			setIsGeneratingDD(true);
			await regenerateCampaignDesignDirection(
				campaignId,
				designDirection.id,
				{},
			);
			toastSuccess('Design direction regenerated');
			await handleRefreshDesignDirections();
		} catch (error) {
			toastError(error);
		} finally {
			setIsGeneratingDD(false);
		}
	};

	const handleRefreshDesignDirections = async () => {
		if (!campaignId) return;
		const response = await getCampaign(campaignId);
		setDesignDirections(response.designDirections ?? []);
		setCampaign(response);
	};

	const getNextTemplate = async () => {
		if (!campaignId) return;

		if (layeredTemplates?.length > 0 && !wasCampaignChanged) {
			return getHighestScoreTemplate(layeredTemplates);
		}

		const designDirectionTemplateIds =
			getTemplateIdsFromDesignDirections(designDirections);

		const filteredTemplates = creatomatePlatformTemplates.filter(
			(template) => !designDirectionTemplateIds.includes(template.templateId),
		);

		const highestScoreTemplate = getHighestScoreTemplate(filteredTemplates);

		return await processTemplate(highestScoreTemplate.id, campaignId);
	};

	const processTemplate = async (templateId: string, campaignId: string) => {
		const { callback } = await getTemplateLayers(templateId, campaignId);
		return await processCallback(callback);
	};

	const handleGenerateNewDD = async () => {
		try {
			setIsAutoAssign(true);
			if (!campaignId) return;
			setIsGeneratingDD(true);
			await genrateNewDesignDirection(campaignId, { adEngine: 'creatomate' });
			await handleRefreshDesignDirectionsWithCount(1);
		} catch (error: any) {
			toastError(error);
			setIsGeneratingDD(false);
		}
	};

	const handleRemoveDesignDirection = async (id: string) => {
		try {
			if (!campaignId || !id) return;
			await removeDesignDirection(campaignId, id);
			toastSuccess('Design direction deleted');
			if (selectedDesignDirectionId === id) {
				setSelectedDesignDirectionId(null);
			}
			handleRefreshDesignDirections();
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleGenerateCreatives = async (
		designDirectionId?: string,
		onlyUnprocessed = true,
	) => {
		if (!campaignId) return;
		try {
			await genrateCampaignCreatives(
				campaignId,
				designDirectionId,
				onlyUnprocessed,
			);
			const data = await getCampaign(campaignId);
			setChannels(data.channels);
			data.creatives && setCreatives(data.creatives);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleSelectDesignDirection = (
		designDirection: IDesignDirection,
		scroll = true,
	) => {
		if (selectedDesignDirectionId !== designDirection.id) {
			setSelectedDesignDirectionId(designDirection.id);
			setSelectedDesignDirection(designDirection);
			scroll && creativesRef.current.scrollIntoView();
		}
		if (designDirection.status !== 'GENERATED') return;

		const creativesFromSelectedDD = creatives?.filter(
			(creative) => creative.designDirectionId === designDirection.id,
		);
		const allChannelsCovered = selectedChannels.every(
			(channel) =>
				creativesFromSelectedDD?.some(
					(creative) => creative.channel === channel,
				),
		);
		const hasPendingCreatives =
			!creativesFromSelectedDD.length || !allChannelsCovered;

		if (hasPendingCreatives && designDirection.status === 'GENERATED') {
			setLoadingCreatives(true);
			handleGenerateCreatives(designDirection.id, hasPendingCreatives);
		}
	};

	const handleCloseEditor = () => {
		setEditingDesingDirection(undefined);
	};

	const handleSave = async (updatedTemplate: any) => {
		if (!campaignId || !editingDesignDirection) return;
		const updatedPayload = {
			attributes: updatedTemplate,
		};

		await editCampaignDesignDirections(
			campaignId,
			editingDesignDirection.id,
			updatedPayload,
		);

		await handleRefreshDesignDirections();
		handleCloseEditor();
		toastSuccess('Your changes have been saved');

		try {
			setLoadingCreatives(true);
			await genrateCampaignCreatives(campaignId, editingDesignDirection.id);
			const data = await getCampaign(campaignId);
			setCampaign(data);
			toastSuccess('Creatives have been regenerated successfully');
		} catch (error) {
			toastError('Failed to regenerate creatives');
		} finally {
			setLoadingCreatives(false);
		}
	};

	const handleChange = (updatedTemplate: any) => {
		setEditingDesingDirection((prev) => {
			if (!prev) return prev;
			return {
				...prev,
				attributes: {
					...prev.attributes,
					elements: updatedTemplate.elements,
				},
			};
		});
	};

	const isAnySelected = selectedDesignDirectionId !== null;
	const sortedDesignDirections = [...designDirections].sort((a, b) => {
		if (a.locked && !b.locked) return -1;
		if (!a.locked && b.locked) return 1;
		return a.variant.localeCompare(b.variant);
	});
	const designDirectionsToShow = isLoading
		? sortedDesignDirections.filter((dd) => dd.locked)
		: sortedDesignDirections;

	const isEditing = Boolean(editingDesignDirection);

	if (isEditing)
		return (
			<>
				<ConfirmDialog
					isOpen={isConfirmDialogOpen}
					onClose={onCloseConfirmDialog}
					onConfirm={() => {
						handleCloseEditor();
						onCloseConfirmDialog();
					}}
					message="You have unsaved changed that will be lost if you decide to continue. Are you sure you want to leave this page?"
				/>
				<div
					style={{
						left: 0,
						top: 72,
						width: '100vw',
						height: '100vh',
						zIndex: 1000,
						position: 'fixed',
					}}
				>
					<Design
						fonts={fonts}
						template={editingDesignDirection!.attributes}
						onClose={handleCloseEditor}
						onSave={handleSave}
						onChange={handleChange}
					/>
				</div>
			</>
		);

	return (
		<Flex p={4} direction="column">
			<HStack
				justifyContent="space-between"
				w="full"
				alignItems="center"
				mb={10}
				mt="-20px"
			>
				<Text
					size="md"
					visibility={
						designDirections.length > 0 || isShowingSkeletons
							? 'visible'
							: 'hidden'
					}
				>
					Designs to promote your business
				</Text>

				<GenerateOrSelectCard
					onGenerate={handleGenerateNewDD}
					campaignId={campaignId!}
					setIsGeneratingDD={setIsGeneratingDD}
					isDisabled={isShowingSkeletons}
					source="designDirections"
					handleRefreshDesignDirections={handleRefreshDesignDirections}
				/>
			</HStack>
			<Wrap spacing={4}>
				{designDirections.length > 0 &&
					designDirections.map((dd: IDesignDirection, index) => (
						<WrapItem
							key={dd.id}
							ref={(el: HTMLLIElement | null) => (ddRefs.current[index] = el)}
						>
							<Box>
								<DDCard
									designDirection={dd}
									openEditModal={() => setEditingDesingDirection(dd)}
									onRetry={handleRetryGenerateDD}
									onRemove={handleRemoveDesignDirection}
									onSelect={() => handleSelectDesignDirection(dd)}
									isSelected={selectedDesignDirectionId === dd.id}
									isAnySelected={isAnySelected}
								/>
							</Box>
						</WrapItem>
					))}

				{(isLoading || isGeneratingDD) &&
					Array.from({ length: selectedTemplatesCount || 1 }).map(
						(_, index) => (
							<WrapItem key={`skeleton-${index}`}>
								<Box ref={index === 0 ? skeletonsRef : undefined}>
									<Skeleton w="323px" h="323px" borderRadius="16px" />
								</Box>
							</WrapItem>
						),
					)}
				{designDirections.length === 0 && !isLoading && !isGeneratingDD && (
					<EmptyState />
				)}
			</Wrap>

			<Box ref={creativesRef} />
			{designDirections.length > 0 &&
				selectedDesignDirection &&
				selectedChannels.length > 0 && (
					<Creatives
						creatives={creatives}
						setCreatives={setCreatives}
						loadingCreatives={loadingCreatives}
						setLoadingCreatives={setLoadingCreatives}
						channels={selectedChannels}
						designDirection={selectedDesignDirection}
						creativesConfig={creativesConfig}
						availableChannelGroups={availableChannelGroups}
					/>
				)}
			{designDirections.length > 0 && (
				<Box alignSelf="flex-end" mt={5}>
					<Button
						isDisabled={!hasAnyGenerated || isDesignDirectionPending}
						variant="orangeSolid"
						_hover={{
							opacity: 0.9,
						}}
						_disabled={{
							opacity: 0.7,
							cursor: 'not-allowed',
						}}
						onClick={() => {
							props.onSubmit();
							completeStep(1);
						}}
					>
						Schedule & Publish
					</Button>
				</Box>
			)}
			{/* {tagModalToggle.payload && (
				<EditDDModal
					isOpen={tagModalToggle.isOpen}
					onClose={() => {
						tagModalToggle.onClose();
						closeChat();
					}}
					initialValues={tagModalToggle.payload}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					designDirections={designDirections}
					setLoadingCreatives={setLoadingCreatives}
				/>
			)} */}
		</Flex>
	);
};
export default DesignDirections;
