import React, {
	FC,
	useContext,
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import { Flex, Heading, Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import {
	ChangeAccountLink,
	FacebookAuthButton,
} from 'src/components/integration';
import UserContext from 'src/contexts/user/UserContext';
import Chip from 'src/components/common/Chip';
import AlertMessage from 'src/components/common/AlertMessage';
import { getFacebookOauthUrl } from 'src/lib/integrations';
import SocialPages, { SocialPagesRef } from './SocialPages';
import { IPage } from 'src/lib/schemas';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { FacebookIcon, MetaIcon } from 'src/assets/icons';

interface IntegrationCardProps {
	isConnetedToFb: boolean;
	pages?: IPage[];
	adAccounts?: any[];
	adAccountsLoading: boolean;
	redirectedFromFB: boolean;
	isCampaignContext?: boolean;
	error: string | null;
	instagramPages?: IPage[];
	noPagesAvailable: boolean;
}

export interface IntegrationCardRef {
	getIntegrationData: () => any;
}

const IntegrationCard = forwardRef<IntegrationCardRef, IntegrationCardProps>(
	(
		{
			pages,
			adAccounts,
			isConnetedToFb,
			adAccountsLoading,
			redirectedFromFB,
			instagramPages,
			isCampaignContext = false,
			error,
			noPagesAvailable,
		},
		ref,
	) => {
		const { user } = useContext(UserContext);
		const { disconnectFacebookIntegration, handleFetchIntegrations } =
			useMyIntegrations();
		const { pathname } = useLocation();
		const [connected, setConnected] = useState(isConnetedToFb);
		const socialPagesRef = useRef<SocialPagesRef>(null);
		const [localError, setLocalError] = useState<string | null>(null);

		useEffect(() => {
			if (error) {
				setConnected(false);
			}
		}, [error]);

		if (!user) return null;

		useEffect(() => {
			setConnected(isConnetedToFb);
		}, [isConnetedToFb]);

		const handleDisconnect = async () => {
			try {
				await disconnectFacebookIntegration();

				await handleFetchIntegrations();
			} catch (error) {
				console.error('Error disconnecting Facebook integration:', error);
			} finally {
				setConnected(false);
			}
		};

		useImperativeHandle(ref, () => ({
			getIntegrationData: () => {
				if (socialPagesRef.current) {
					const {
						selectedPages,
						selectedAdAccount,
						selectedIGPages,
						accessTokens,
					} = socialPagesRef.current.getSelectedData();

					// if (selectedPages.length === 0) {
					// 	setLocalError('Please select at least one page.');
					// 	return null;
					// }

					const fbIntegrationData = {
						enabled: true,
						attributes: {
							pagesToPublishContent: selectedPages,
							adAccounts: selectedAdAccount ? [selectedAdAccount] : [],
							instagramPagesToPublish: selectedIGPages,
							tokens: accessTokens,
						},
					};

					return fbIntegrationData;
				}
				return null;
			},
		}));
		return (
			<Flex
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
				p={5}
				borderRadius="8px"
				w="full"
				maxW="600px"
			>
				<Flex gap={8} direction="column" w="full">
					{(error || localError) && (
						<AlertMessage status="error">{error || localError}</AlertMessage>
					)}
					{connected ? (
						<Flex direction="column" gap={10}>
							<Flex
								gap={2}
								alignItems="center"
								justifyContent="space-between"
								w="full"
							>
								<Flex gap={2} alignItems="center">
									<img
										src="/images/meta-logo.png"
										alt="Meta Logo"
										style={{ height: '12px' }}
									/>
									<Chip
										label="Connected"
										tagProps={{
											colorScheme: 'green',
										}}
									/>
								</Flex>
								<Flex gap={2} alignItems="center">
									<ChangeAccountLink
										link={getFacebookOauthUrl({
											account: user.account,
											redirectTo: `${pathname}?fromFB`,
										})}
									/>
									<Button
										onClick={handleDisconnect}
										bg="#E5E7EB"
										color="black"
										size="sm"
										_hover={{ bg: '#D1D5DB' }}
									>
										Disconnect
									</Button>
								</Flex>
							</Flex>
							{pages && (
								<SocialPages
									ref={socialPagesRef}
									pages={pages}
									instagramPages={instagramPages}
									adAccounts={adAccounts}
									adAccountsLoading={adAccountsLoading}
									redirectedFromFB={redirectedFromFB}
									noPagesAvailable={noPagesAvailable}
								/>
							)}
						</Flex>
					) : (
						<Flex
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							w="full"
						>
							<Flex direction="row" alignItems="center" gap={4}>
								<img
									src="/images/meta-logo.png"
									alt="Meta Logo"
									style={{ height: '12px' }}
								/>
								<Chip
									label="Disconnected"
									tagProps={{
										colorScheme: 'red',
									}}
								/>
							</Flex>
							<FacebookAuthButton account={user.account} />
						</Flex>
					)}
				</Flex>
			</Flex>
		);
	},
);

IntegrationCard.displayName = 'IntegrationCard';

export default IntegrationCard;
