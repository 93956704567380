import {
	Flex,
	Box,
	Button,
	Heading,
	Text,
	HStack,
	Image,
} from '@chakra-ui/react';
import CustomContainer from 'src/components/common/CustomContainer';
import Logo from 'src/assets/svgs/logo.svg';
import { openIntercom } from 'src/lib/utils/intercom';
import { goToPaymentCheckout } from 'src/lib/utils/billing';
import { BillingStatus } from 'src/models/account/interfaces';
import FeaturesList from 'src/pages/account/subscription/FeaturesList';

interface PlanCardProps {
	title: string;
	features: string[];
	price: number | string;
	isSelected?: boolean;
	status?: BillingStatus;
	hasPaymentMethod?: boolean;
}

const PlanCard: React.FC<PlanCardProps> = ({
	title,
	features,
	price,
	isSelected = false,
	status,
	hasPaymentMethod,
}) => {
	const isTrialExpired = status === BillingStatus.TrialExpired;
	const isInactive = status === BillingStatus.Inactive;
	const isTrialOver = isTrialExpired || isInactive;

	const checkoutMode = isInactive ? 'subscription' : 'setup';

	const userMessage = isTrialExpired
		? 'Hello, my trial is over and I need help'
		: 'Hello, my account is inactive and I need help';

	return (
		<CustomContainer
			boxShadow="0 0 15px rgba(0, 0, 0, 0.2)"
			p={6}
			mb={6}
			border={isSelected ? '2px solid' : 'none'}
			borderColor={isSelected ? 'orange.base' : 'transparent'}
			w="full"
		>
			<Box mb={6}>
				<HStack mb={6} alignItems="center">
					<Image src={Logo} height="30px" />
					<Button variant="orangeSolidWithoutEvent" px={2} py={1} size="sm">
						{title}
					</Button>
				</HStack>
				<FeaturesList features={features} />
			</Box>

			<Flex alignItems="center" mb={4}>
				<Heading
					as="h3"
					size="xl"
					color="gray.900"
					fontWeight="bold"
					lineHeight="1"
				>
					${price}
				</Heading>
				<Text fontSize="md" color="gray.500" ml={1}>
					/month
				</Text>
			</Flex>

			<HStack>
				<Button
					variant="orangeSolid"
					onClick={() => goToPaymentCheckout(checkoutMode)}
				>
					{hasPaymentMethod ? 'Update' : 'Add'} {''} payment method
				</Button>
				{isTrialOver && (
					<>
						<Text>or</Text>
						<Button variant="gray" onClick={() => openIntercom(userMessage)}>
							Contact us
						</Button>
					</>
				)}
			</HStack>
		</CustomContainer>
	);
};

export default PlanCard;
