import { Box } from '@chakra-ui/react';

interface DirectionPromptProps {
	element: {
		id: string;
		name: string;
		description?: string;
	};
	onDescriptionChange: (value: string) => void;
}

export const DirectionPrompt = ({
	element,
	onDescriptionChange,
}: DirectionPromptProps) => {
	return (
		<Box display="flex" alignItems="center" mb={3} gap={2}>
			<Box
				width="200px"
				border="1px solid"
				borderRadius="md"
				px={3}
				h="40px"
				display="flex"
				alignItems="center"
				bg="white"
				textColor="gray.600"
			>
				{element.name}
			</Box>
			<Box flex={1}>
				<input
					type="text"
					value={element.description || ''}
					onChange={(e) => onDescriptionChange(e.target.value)}
					style={{
						width: '100%',
						padding: '8px',
						border: '1px solid',
						borderRadius: '6px',
					}}
				/>
			</Box>
		</Box>
	);
};
