import React, { useState, useEffect, useContext } from 'react';
import {
	Box,
	Flex,
	Text,
	Button,
	Spinner,
	useToast,
	Textarea,
	FormErrorMessage,
	FormControl,
} from '@chakra-ui/react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import GoogleDriveIcon from 'src/assets/icons/GoogleDriveIcon';
import {
	main as generateDriveVariations,
	initGapiClient,
} from 'src/services/drive';
import UserContext from 'src/contexts/user/UserContext';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';
import { VariationItem } from '../testTemplates/commponents/VariationListItem';
import { useFormContext } from 'react-hook-form';
import TextareaInputJsonHook from 'src/components/common/form/TextAreaInputJson/TextAreaInputJsonHook';

const CLIENT_ID =
	'1003422174381-evhamlkokjku3kk0rg8cv5quqr8n36h1.apps.googleusercontent.com';
const SCOPES =
	'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

export interface Variation {
	id: string;
	title: string;
	layeredFile: string;
	isLoading: boolean;
	layerSpecs?: {
		name: string;
		actions: {
			id: string;
			arguments: any;
		}[];
	}[];
}

const VariationsList: React.FC<{
	onVariationsGenerated: (variations: Variation[]) => void;
	folderName: string;
}> = ({ onVariationsGenerated, folderName }) => {
	const { user, isLoading: isUserLoading, account } = useContext(UserContext);
	const { selectedTemplate } = useContext(TemplatesContext);
	const {
		formState: { errors },
	} = useFormContext();

	const [variations, setVariations] = useState<Variation[]>([]);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isGenerated, setIsGenerated] = useState(false);

	const toast = useToast();

	useEffect(() => {
		const defaultTitles: Record<string, string> = {
			design: 'Design Direction',
			facebook_story: 'FB/IG Story',
			facebook_feed: 'Facebook Feed',
			instagram_feed: 'Instagram Feed',
			instagram_story: 'FB/IG Story',
		};

		if (
			selectedTemplate?.variations &&
			selectedTemplate.variations.length > 0
		) {
			const formattedVariations = selectedTemplate.variations.map(
				(variation) => ({
					...variation,
					title: variation.title || defaultTitles[variation.id!] || '',
					isLoading: false,
				}),
			) as Variation[];

			setVariations(formattedVariations);
		} else {
			const initialVariations = [
				{
					id: 'design',
					title: 'Design Direction',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'facebook_story',
					title: 'FB/IG Story',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'facebook_feed',
					title: 'Facebook Feed',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'instagram_feed',
					title: 'Instagram Feed',
					layeredFile: '',
					isLoading: false,
				},
				{
					id: 'instagram_story',
					title: 'FB/IG Story',
					layeredFile: '',
					isLoading: false,
				},
			];
			setVariations(initialVariations);
		}
	}, [selectedTemplate]);

	useEffect(() => {
		const allGenerated = variations.every((v) => v.layeredFile !== '');
		setIsGenerated(allGenerated);
	}, [variations]);

	const initializeGoogleApi = async (
		accessToken: string,
		maxRetries = 3,
	): Promise<boolean> => {
		for (let attempt = 0; attempt < maxRetries; attempt++) {
			try {
				await initGapiClient(accessToken, CLIENT_ID);

				if (window.gapi && window.gapi.client && window.gapi.client.drive) {
					return true;
				}

				await new Promise((resolve) => setTimeout(resolve, 1000));
			} catch (error) {
				console.error(`Error in initialization attempt ${attempt + 1}:`, error);
				if (attempt === maxRetries - 1) {
					throw error;
				}
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		}
		return false;
	};

	const login = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			if (isGenerated) return;
			setIsGenerating(true);
			setVariations(variations.map((v) => ({ ...v, isLoading: true })));
			try {
				const isInitialized = await initializeGoogleApi(
					tokenResponse.access_token,
				);
				if (!isInitialized) {
					throw new Error(
						'Failed to initialize Google API after multiple attempts',
					);
				}

				if (account && user) {
					const generatedVariations = await generateDriveVariations(
						tokenResponse.access_token,
						CLIENT_ID,
						folderName,
						account.id,
						account.name,
					);
					const uniqueVariations = generatedVariations.reduce(
						(acc: Variation[], current) => {
							if (current) {
								return acc.concat([{ ...current, isLoading: false }]);
							}
							return acc;
						},
						[],
					);

					setVariations(uniqueVariations);
					onVariationsGenerated(uniqueVariations);
					setIsGenerated(true);
				} else {
					throw new Error('User or account information is missing');
				}
			} catch (error) {
				console.error('Error generating variations:', error);
				setVariations(variations.map((v) => ({ ...v, isLoading: false })));
				toast({
					title: 'Error',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			} finally {
				setIsGenerating(false);
			}
		},
		onError: (error) => {
			console.error('Login Failed:', error);
			toast({
				title: 'Error',
				description: 'Google login failed. Please try again.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		},
		scope: SCOPES,
		flow: 'implicit',
	});

	return (
		<Box>
			<Flex alignItems="center" mb={4}>
				<Text fontWeight="bold" fontSize="24px" mr={2}>
					Variations
				</Text>
				<Button
					variant="link"
					colorScheme="blue"
					leftIcon={isGenerating ? <Spinner size="sm" /> : <GoogleDriveIcon />}
					onClick={() => login()}
					isDisabled={isGenerating || isGenerated}
				>
					{isGenerating
						? 'Generating...'
						: isGenerated
						? 'Generated'
						: 'Generate variations ↓'}
				</Button>
			</Flex>
			<Box mb={6}>
				<Text fontWeight="bold" fontSize="16px" mb={2}>
					Design Template
				</Text>
				<TextareaInputJsonHook
					name="variations.0.template"
					disabled={!isGenerated}
				/>
			</Box>

			{variations
				.filter((v) => v.id === 'design')
				.map((variation, index) => {
					return <VariationItem key={index} variation={variation} />;
				})}
		</Box>
	);
};

const WrappedVariationsList: React.FC<{
	onVariationsGenerated: (variations: Variation[]) => void;
	folderName: string;
}> = (props) => (
	<GoogleOAuthProvider clientId={CLIENT_ID}>
		<VariationsList {...props} />
	</GoogleOAuthProvider>
);

export default WrappedVariationsList;
