import React from 'react';
import {
	Alert,
	AlertProps,
	AlertIcon,
	Box,
	Button,
	Flex,
} from '@chakra-ui/react';
import { WarningIcon } from 'src/assets/icons';

interface ICustomAlert extends AlertProps {
	buttonLabel?: string;
	onButtonClick?: any;
	children: React.ReactNode;
	customIcon?: React.ReactNode;
}

const CustomAlert: React.FC<ICustomAlert> = ({
	status = 'info',
	borderRadius = 'sm',
	mb = 6,
	children,
	buttonLabel,
	onButtonClick,
	customIcon,
	...props
}) => {
	const icon =
		customIcon ||
		(status === 'warning' ? (
			<WarningIcon boxSize="17px" ml={3} mr={2} />
		) : (
			<AlertIcon boxSize="17px" ml={3} mr={2} />
		));

	return (
		<Alert status={status} borderRadius={borderRadius} mb={mb} {...props}>
			<Flex justify="space-between" w="100%">
				<Box display="flex" alignItems="center">
					{icon}
					<Box fontSize="14px">{children}</Box>
				</Box>
				{buttonLabel && (
					<Box>
						<Button variant="orangeSolid" onClick={onButtonClick}>
							{buttonLabel}
						</Button>
					</Box>
				)}
			</Flex>
		</Alert>
	);
};

export default CustomAlert;
