import { FormControl, FormErrorMessage, Textarea } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

interface TextareaInputJsonHookProps {
	name: string;
	disabled?: boolean;
}

const TextareaInputJsonHook = ({
	name,
	disabled,
}: TextareaInputJsonHookProps) => {
	const {
		setValue,
		getValues,
		setError,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	const handleInputChange = (value: string) => {
		if (!value.trim()) {
			setValue(name, '');
			clearErrors(name);
			return;
		}

		try {
			const sanitizedValue = value.replaceAll("________",".")
			const parsedValue = JSON.parse(sanitizedValue);

			if (typeof parsedValue !== 'object' || parsedValue === null) {
				throw new Error('Root must be an object');
			}

			setValue(name, parsedValue);
			clearErrors(name);
		} catch {
			setValue(name, value);
			setError(name, { message: 'Should be a valid JSON' });
		}
	};

	const error = get(errors, name);
	const errorMessage =
		typeof error?.message === 'string'
			? error.message
			: 'Should be a valid JSON';

	const formatValueForDisplay = (value: string | object): string => {
		const expression = /\$([a-zA-Z0-9]+)(\.)/g;
		const doubleDotexpression = /\$([a-zA-Z0-9]+)(\.)([a-zA-Z0-9]+)(\.)/g;
		let content = value as string
		if (typeof value === 'object') {
			content = JSON.stringify(value, null, 2)
		}

		content = content.replaceAll("[0].","[0]________")
		content = content.replaceAll(doubleDotexpression,"$$$1________$3________")
		return content.replaceAll(expression, '$$$1________');
	};

	const rawValue = getValues(name);
	const displayedValue =
		Array.isArray(rawValue) && rawValue.length === 0
			? ''
			: formatValueForDisplay(rawValue || '');

	return (
		<FormControl isInvalid={!!error}>
			<Textarea
				name={name}
				placeholder="Enter design template JSON"
				disabled={disabled}
				onChange={(e) => handleInputChange(e.target.value)}
				value={displayedValue}
				isDisabled={disabled}
				minH="200px"
			/>
			{error && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</FormControl>
	);
};

export default TextareaInputJsonHook;
