import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import {
	Box,
	Image,
	Text,
	Button,
	Flex,
	CloseButton,
	useColorModeValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import CampaignContext from 'src/contexts/campaign/CampaignContext';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SelectTemplateModal from './design-directions/SelectTemplateModal';

const MotionBox = motion(Box);

const SelectedTemplatePreview: FC = () => {
	const {
		selectedTemplateIds,
		setSelectedTemplateIds,
		templates,
		templatesByScope,
	} = useContext(TemplateContext);

	const { campaign, setCampaign } = useContext(CampaignContext);
	const [selectTemplateModalOpen, setSelectTemplateModalOpen] = useState(false);
	const selectedTemplates = useMemo(() => {
		const selectedTemplatesList = selectedTemplateIds
			.map((id) => {
				return (
					templates.find((t) => t.id === id) ||
					templatesByScope.find((t) => t.id === id)
				);
			})
			.filter(
				(template): template is NonNullable<typeof template> =>
					template !== undefined,
			);

		return selectedTemplatesList;
	}, [selectedTemplateIds, templates, templatesByScope]);

	if (selectedTemplates.length === 0) return null;

	const handleChangeTemplate = () => {
		setSelectTemplateModalOpen(true);
	};

	const handleRemoveTemplate = (templateId: string) => {
		const updatedSelectedTemplateIds = selectedTemplateIds.filter(
			(id) => id !== templateId,
		);
		setSelectedTemplateIds(updatedSelectedTemplateIds);

		if (campaign?.templateId === templateId) {
			setCampaign({ ...campaign, templateId: undefined });
		}
	};

	const swiperRef = useRef<any>(null);
	const [isBeginning, setIsBeginning] = useState<boolean>(true);
	const [isEnd, setIsEnd] = useState<boolean>(false);

	const slideSize = '200px';

	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	const handleNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	return (
		<Box mb={4} width="100%" borderRadius={5} paddingBottom={5}>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				mb={2}
				width="100%"
			>
				<Text fontSize="16px" fontWeight="medium" textAlign="left">
					Selected Templates
				</Text>
				<Button onClick={handleChangeTemplate} variant="orangeOutline">
					Change Templates
				</Button>
			</Flex>

			<Box position="relative" overflow="visible" width="100%">
				{selectedTemplates.length > 0 && (
					<>
						{selectedTemplates.length > 4 && (
							<Button
								position="absolute"
								top="50%"
								left="0"
								transform="translate(-50%, -50%)"
								zIndex="2"
								onClick={handlePrevSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								disabled={isBeginning}
								style={{ display: isBeginning ? 'none' : 'block' }}
							>
								<ChevronLeftIcon
									w={6}
									h={6}
									color={isBeginning ? 'gray.400' : 'black'}
								/>
							</Button>
						)}

						<Swiper
							modules={[Pagination]}
							pagination={false}
							spaceBetween={20}
							slidesPerView="auto"
							loop={false}
							style={{
								paddingBottom: '20px',
								paddingTop: '10px',
								paddingLeft: '0',
								paddingRight: '0',
							}}
							// @ts-ignore:
							onSwiper={(swiper) => {
								swiperRef.current = swiper;
								swiper.on('slideChange', () => {
									setIsBeginning(swiper.isBeginning);
									setIsEnd(swiper.isEnd);
								});
								swiper.on('reachEnd', () => {
									setIsEnd(true);
								});
								swiper.on('reachBeginning', () => {
									setIsBeginning(true);
								});
								swiper.on('fromEdge', () => {
									if (!swiper.isBeginning && !swiper.isEnd) {
										setIsEnd(false);
									}
								});
							}}
						>
							{selectedTemplates.map((template) => (
								<SwiperSlide
									key={template.id}
									style={{
										width: slideSize,
										height: slideSize,
										overflow: 'visible',
										cursor: 'pointer',
										flexShrink: 0,
									}}
								>
									<MotionBox
										position="relative"
										width="100%"
										height="100%"
										boxShadow="md"
										borderRadius="5px"
										overflow="hidden"
										cursor="pointer"
										display="flex"
										alignItems="center"
										justifyContent="center"
										bg={useColorModeValue('gray.100', 'gray.700')}
									>
										<Image
											src={
												template.attributes?.thumbnail ||
												'https://via.placeholder.com/200'
											}
											alt={template.name}
											width="100%"
											height="100%"
											objectFit="cover"
											style={{
												aspectRatio: '1',
											}}
										/>
										<CloseButton
											size="sm"
											position="absolute"
											top="4px"
											right="4px"
											onClick={() => handleRemoveTemplate(template.id)}
											backgroundColor="rgba(255, 255, 255, 0.7)"
											_hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
											borderRadius="full"
										/>
									</MotionBox>
								</SwiperSlide>
							))}
						</Swiper>

						{selectedTemplates.length > 4 && (
							<Button
								position="absolute"
								top="50%"
								right="0"
								transform="translate(50%, -50%)"
								zIndex="2"
								onClick={handleNextSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								disabled={isEnd}
								style={{ display: isEnd ? 'none' : 'block' }}
							>
								<ChevronRightIcon
									w={6}
									h={6}
									color={isEnd ? 'gray.400' : 'black'}
								/>
							</Button>
						)}
					</>
				)}
			</Box>
			{selectTemplateModalOpen && (
				<SelectTemplateModal
					isOpen={selectTemplateModalOpen}
					onClose={() => setSelectTemplateModalOpen(false)}
					source="changeTemplates"
				/>
			)}
		</Box>
	);
};

export default SelectedTemplatePreview;
