import { defineStyleConfig } from '@chakra-ui/react';

const Heading = defineStyleConfig({
	sizes: {
		md: {
			fontSize: '18px',
			fontWeight: 500,
			lineHeight: '28px',
			fontFamily: 'inherit',
		},
		lg: {
			fontSize: '24px',
			fontWeight: 700,
			lineHeight: '120%',
			fontFamily: 'inherit',
		},
	},
	defaultProps: {
		size: 'md',
	},
});

const Box = defineStyleConfig({
	baseStyle: {
		borderColor: '#D1D5DB',
	},
});

const Text = defineStyleConfig({
	baseStyle: {
		color: '#111827',
	},
	sizes: {
		sm: {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '20px',
		},
		md: {
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: '24px',
			color: 'gray.800',
		},
	},
	defaultProps: {
		size: 'sm',
	},
});

const IconButton = defineStyleConfig({
	baseStyle: {
		minH: 'auto',
	},
});

const Button = defineStyleConfig({
	baseStyle: {
		fontWeight: 600,
		borderRadius: '6px',
		minH: '32px',
	},
	sizes: {
		sm: {
			fontSize: 'sm',
			lineHeight: '16px',
			fontWeight: 500,
		},
		md: {
			fontSize: 'sm',
			lineHeight: '20px',
		},
		lg: {
			height: '50px',
			fontWeight: 500,
			fontSize: '16px',
		},
	},

	variants: {
		orangeOutline: {
			bg: 'white',
			borderColor: 'orange.base',
			border: '1px solid',
			color: 'orange.base',
			_hover: { bg: 'orange.lighter' },
		},
		orangeSolid: {
			bg: 'orange.base',
			color: 'white',
			borderColor: 'orange.base',
			_hover: { bg: '#fa602a' },
			_loading: {
				opacity: 0.7,
				_hover: {
					bg: 'orange.base',
				},
			},
			_disabled: {
				opacity: 0.6,
				cursor: 'not-allowed',
				_hover: {
					opacity: 0.7,
					backgroundColor: 'inherit',
				},
			},
		},
		orangeSolidWithoutEvent: {
			bg: 'orange.base',
			color: 'white',
			borderColor: 'orange.base',
			height: 'auto',
			minH: '0',
			cursor: 'default',
			p: '1.5',
			fontWeight: 500,
		},
		blankOutline: {
			bg: 'white',
			color: 'black',
			border: '1px solid',
			borderColor: 'gray.200',
		},
		blueOutline: {
			bg: 'white',
			color: 'blue.500',
			border: '1px solid',
			borderColor: 'blue.500',
		},
		ghost: {
			_active: {
				bg: 'orange.light',
				color: 'orange.base',
			},
		},
		gray: {
			bg: 'gray.200',
			color: 'black',
		},
		grayWithoutEvent: {
			bg: 'gray.200',
			color: 'black',
			height: 'auto',
			minH: '0',
			cursor: 'default',
			p: '1.5',
		},
	},

	defaultProps: {
		size: 'md',
	},
});

const Divider = defineStyleConfig({
	baseStyle: {
		borderColor: '#CDCDCD',
		borderRadius: 'base',
	},
});

const Drawer = defineStyleConfig({
	variants: {
		clickThrough: {
			dialog: {
				pointerEvents: 'auto',
			},
			dialogContainer: {
				pointerEvents: 'none',
			},
		},
	},
});

const Input = defineStyleConfig({
	baseStyle: {
		minH: '40px',
		borderColor: '#E2E8F0',
		fontSize: '14px',
		borderRadius: '5px',
		_placeholder: {
			color: '#A0AEC0',
			fontWeight: 400,
		},
	},
});

const Select = defineStyleConfig({
	baseStyle: {
		minH: '40px',
		borderColor: '#E2E8F0',
		fontSize: '14px',
		borderRadius: '5px',
		_placeholder: {
			color: '#A0AEC0',
			fontWeight: 400,
		},
	},
});

const Textarea = defineStyleConfig({
	baseStyle: {
		fontSize: '14px',
		borderRadius: '5px',
		_placeholder: {
			color: '#A0AEC0',
			fontWeight: 400,
		},
	},
	defaultProps: {
		size: 'sm',
	},
});

const FormLabel = defineStyleConfig({
	baseStyle: {
		fontSize: '14px',
		fontWeight: 500,
		marginBottom: '15px',
		display: 'inline-block',
	},
});

const Progress = defineStyleConfig({
	baseStyle: {
		filledTrack: {
			bg: '#F7480B',
		},
	},
	variants: {
		weak: {
			filledTrack: {
				bg: '#FBB6B6',
			},
		},
	},
});

export {
	Button,
	Box,
	IconButton,
	Divider,
	Drawer,
	FormLabel,
	Heading,
	Input,
	Text,
	Progress,
	Select,
	Textarea,
};
