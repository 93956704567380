import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { BiUpload } from 'react-icons/bi';
import { uploadFile } from 'src/services/fileUpload';
import { customToast, toastError, toastSuccess } from 'src/services/toast';
import { FileRejection, useDropzone, Accept } from 'react-dropzone';
import { uploadFontFile } from 'src/services/account';

interface FileInputProps<T extends FieldValues> {
	name: FieldPath<T>;
	uploadButtonText?: string;
	acceptImages?: boolean;
	acceptFonts?: boolean;
	uploadPath?: string;
	onUploadingStatusChange?: (isUploading: boolean) => void;
	onUrlChange?: (url: string) => void;
	onDrop?: (
		acceptedFiles: File[] | string,
		fileRejections: FileRejection[],
	) => void;
	onLoading?: (loading: boolean) => void;
}

const FileInput: FC<FileInputProps<FieldValues>> = ({
	name,
	uploadButtonText,
	acceptImages = false,
	acceptFonts = false,
	uploadPath = '',
	onUploadingStatusChange,
	onUrlChange,
	onDrop,
	onLoading,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		onLoading && onLoading(isLoading);
	}, [isLoading, onLoading]);

	const acceptTypes = (): Accept => {
		if (acceptImages) {
			return {
				'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.svg', '.avif'],
			};
		}
		if (acceptFonts) {
			return {
				'.ttf': ['.ttf'], 
				'.otf': ['.otf'],
			};
		}
		return {};
	};
	

	const handleFileChange = async (file: File) => {
		if (!file) return;
		if (!isValidFile(file)) {
			customToast(
				`Only ${acceptImages ? 'images' : 'fonts'} are allowed.`,
				'error',
			);
			return;
		}

		onUploadingStatusChange && onUploadingStatusChange(true);
		setIsLoading(true);

		try {
			let publicUrl: string | undefined;

			if (isImageFile(file)) {
				const { imageUrl } = await uploadFile(file, true, 100, 30);
				publicUrl = imageUrl;
			} else if (isFontFile(file)) {
				const { message, newFontFamilies } = await uploadFontFile(file);
				toastSuccess(message)
			}

			if (publicUrl) {
				onUrlChange && onUrlChange(publicUrl);
			}
		} catch (error: any) {
			toastError(error);
		}

		setIsLoading(false);
		onUploadingStatusChange && onUploadingStatusChange(false);
	};

	const isImageFile = (file: File) => {
		const imageExtensions = ['png', 'jpg', 'jpeg', 'webp', 'gif', 'svg', '.avif'];
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		return fileExtension ? imageExtensions.includes(fileExtension) : false;
	};

	const isFontFile = (file: File) => {
		const fontExtensions = ['ttf', 'otf'];
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		return fileExtension ? fontExtensions.includes(fileExtension) : false;
	};

	const isValidFile = (file: File): boolean => {
		const acceptObj = acceptTypes();
		const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;

		return Object.values(acceptObj).some((extArray) =>
			extArray.includes(fileExtension),
		);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (fileRejections.length > 0) {
				fileRejections.forEach((fileRejection) => {
					customToast(
						`File type not accepted: ${fileRejection.file.name}`,
						'error',
					);
				});
			} else {
				handleFileChange(acceptedFiles[0]);
			}
		},
		accept: acceptTypes(),
		maxFiles: 1,
	});

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			handleFileChange(file);
		}
	};

	return (
		<Box {...getRootProps()} w="150px" onClick={(e) => e.stopPropagation()}>
			<input
				{...getInputProps()}
				type="file"
				accept={Object.keys(acceptTypes()).join(',')}
				onChange={handleInputChange}
				style={{ display: 'none' }}
				id={name}
			/>
			<Button
				htmlFor={name}
				as="label"
				w="150px"
				leftIcon={<BiUpload size={18} />}
				variant="orangeOutline"
				isLoading={isLoading}
				loadingText="Uploading..."
				cursor="pointer"
				_hover={{ bg: 'inherit' }}
				textAlign="center"
				bg={isDragActive ? 'blue.100' : 'white'}
			>
				{uploadButtonText ?? `Upload ${name}`}
			</Button>
		</Box>
	);
};

export default FileInput;
