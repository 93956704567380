import { FC, useContext, useState } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';

import CreativeImage from './CreativeImage';
import MediaCreativeModal from './MediaCreativeModal';
import DownloadPopover from '../../parts/DownloadPopover';
import { CampaignContext } from 'src/contexts';
import {
	DownloadIcon,
	EditIcon,
	LockIcon,
	SyncIcon,
	UnlockIcon,
} from 'src/assets/icons';
import { customToast, toastError } from 'src/services/toast';
import { downloadFile, parseCampaignName } from 'src/lib/utils';
import {
	IChannelCreative,
	IChannelMediaAttributes,
} from 'src/lib/schemas';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';

interface MediaCreativeProps {
	creativeIndex?: string;
	creative?: ICreative;
	hideLockAction?: boolean;
	onRegenerate: (id: string) => void;
	onLockUnlock: (id: string, locked: boolean) => void;
	channelCreativeAttributes?: IChannelCreative;
	onRefetchCreatives: () => void;
}

const MediaCreative: FC<MediaCreativeProps> = ({
	creativeIndex,
	creative,
	onRegenerate,
	onLockUnlock,
	hideLockAction,
	channelCreativeAttributes,
	onRefetchCreatives,
}) => {
	const [isUpdating, setIsUpdating] = useState(false);
	const [isRegenerating, setIsRegenerating] = useState(false);
	const { campaign } = useContext(CampaignContext);

	const creativeFormToggle = useToggleWithPayload<ICreative | null>();

	if (!creative) return null;
	const { id, name, channel, variant, prompt, status, locked, attributes } =
		creative;
	const mediaContent = attributes as unknown as IChannelMediaAttributes;
	const {
		flatFile,
		layeredFile,
		dimensions: { width, height },
	} = mediaContent.image;
	const filteredAttributes = channelCreativeAttributes?.attributes?.filter(
		(a) => ['String', 'enum'].includes(a.type),
	);

	const handleDownload = async (fileUrl: string) => {
		if (!fileUrl) {
			customToast('No file to download', 'error');
			return;
		}

		const fileName = `${parseCampaignName(campaign?.title ?? '')}-${
			creative.variant
		}-${creative.channel}-${width}x${height}`;

		await downloadFile(fileUrl, fileName);
	};

	const handleFileDownload = async (fileType: string) => {
		await handleDownload(fileType === 'PSD' ? layeredFile : flatFile);
	};

	const handleLockUnlockCreative = async () => {
		setIsUpdating(true);
		try {
			await onLockUnlock(id, !locked);
		} catch (error) {
			toastError(error);
		} finally {
			setIsUpdating(false);
		}
	};

	const hanldeRegenrateCreative = async () => {
		setIsRegenerating(true);
		await onRegenerate(creative.id);
		setIsRegenerating(false);
	};

	const isCreativePending = status !== 'GENERATED';

	const handleEditCreative = () => {
		creativeFormToggle.onOpen(creative);
	};

	const resetEditProps = creativeIndex ? { id: `edit-${creativeIndex}` } : {};
	const resetRegenrateProps = creativeIndex
		? { id: `regenerate-${creativeIndex}` }
		: { id: `regenerate-${channel}-${variant}` };
	const imageId = creativeIndex ? `image-${creativeIndex}` : `image-${variant}`;

	return (
		<Flex direction="column" gap={4} w="full">
			<Flex
				//  alignItems="center"
				justify={name ? 'space-between' : 'flex-end'}
			>
				{name && <Text fontWeight="semibold">{name}</Text>}
				<Flex gap={4}>
					{!hideLockAction && (
						<IconButton
							aria-label="Lock/Unlock"
							colorScheme="secondary"
							icon={
								locked ? <UnlockIcon color="#fff" /> : <LockIcon color="#fff" />
							}
							onClick={handleLockUnlockCreative}
							isDisabled={isCreativePending || isUpdating}
							size="sm"
						/>
					)}
					{!locked && (
						<IconButton
							aria-label="Regenerate"
							colorScheme="secondary"
							icon={<SyncIcon color="#fff" />}
							onClick={hanldeRegenrateCreative}
							isDisabled={isCreativePending || isRegenerating}
							size="sm"
							{...resetRegenrateProps}
						/>
					)}
					{!locked && channelCreativeAttributes && (
						<IconButton
							aria-label="Edit"
							colorScheme="secondary"
							icon={<EditIcon color="#fff" />}
							onClick={handleEditCreative}
							isDisabled={isCreativePending}
							size="sm"
							{...resetEditProps}
						/>
					)}
					<DownloadPopover onDownload={handleFileDownload}>
						<IconButton
							aria-label="Download"
							colorScheme="secondary"
							icon={<DownloadIcon color="#fff" />}
							isDisabled={isCreativePending}
							size="sm"
						/>
					</DownloadPopover>
				</Flex>
			</Flex>

			<CreativeImage
				creativeId={id}
				name={name}
				source={flatFile}
				width={Number(width)}
				height={Number(height)}
				isPendingGeneration={isCreativePending}
				isLocked={locked}
				imageId={imageId}
				attributes={filteredAttributes}
				mediaContent={mediaContent}
				onEditCreative={handleEditCreative}
				creativeData={creative}
			/>
			{creativeFormToggle.payload && (
				<MediaCreativeModal
					isOpen={creativeFormToggle.isOpen}
					data={creativeFormToggle.payload}
					onClose={creativeFormToggle.onClose}
					onRefetchCreatives={onRefetchCreatives}
				/>
			)}
		</Flex>
	);
};

export default MediaCreative;
