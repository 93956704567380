import {
	IChannelMediaAttributes,
	IChannelTextContent,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { CommonAttributes } from 'src/lib/schemas/campaign/newFlowCampaign';

function isMediaAttributes(
	attributes: CommonAttributes,
): attributes is IChannelMediaAttributes {
	return (attributes as IChannelMediaAttributes).image !== undefined;
}
export const removeDuplicateLayers = (
	data?: IDesignDirection[],
): IDesignDirection[] => {
	if (!data) return [];

	return data.map((item) => {
		const attributes = item.attributes;

		if (isMediaAttributes(attributes) && attributes.image?.layers) {
			const layers = attributes.image.layers;
			const uniqueLayers: typeof layers = [];
			const layerNames = new Set<string>();

			layers.forEach((layer) => {
				if (!layerNames.has(layer.name)) {
					uniqueLayers.push(layer);
					layerNames.add(layer.name);
				}
			});

			return {
				...item,
				attributes: {
					...attributes,
					image: {
						...attributes.image,
						layers: uniqueLayers,
					},
				},
			};
		}

		return item;
	});
};
