import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { IAppActionOutput } from 'src/lib/schemas';
import FusionLoading from 'src/components/common/FusionLoading';
import CopyToClipboard from 'src/components/common/CopyToClipboard';

interface StringArrayOutputWidgetProps {
	isParent?: boolean;
	output: IAppActionOutput;
	value: any;
	isProcessing: boolean;
	view?: string;
}

// @ts-ignore:
const StringArrayOutputWidget: FC<StringArrayOutputWidgetProps> = ({
	isParent,
	output,
	value,
	isProcessing,
	view,
}) => {
	const isLoading = isProcessing && !value;
	if (!isLoading && !value) return;

	return (
		<Flex direction="column" gap={4}>
			<Flex direction="column" gap={1}>
				<Text fontWeight={isParent ? 'semibold' : 500}>{output.name}</Text>
				<Flex direction="column" gap={4} pl={2} borderLeft="1px solid #E1E1E1">
					{Array.isArray(value) &&
						value.map((_value: string, index: number) => {
							return (
								<Flex
									key={index}
									alignItems="center"
									border={view === 'text' ? 'none' : '1px solid #E2E8F0'}
									justifyContent={view === 'text' ? '' : 'space-between'}
									borderRadius="sm"
								>
									<FusionLoading isLoading={isLoading} />
									{!isLoading && (
										<Text pl={2} py={2}>
											{_value}
										</Text>
									)}
									{!isLoading && <CopyToClipboard value={_value} />}
								</Flex>
							);
						})}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default StringArrayOutputWidget;
